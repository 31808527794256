import { useContext, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

import whiteLogo from '../../assets/brand-logo.png';
import blackLogo from '../../assets/brand-logo.png';
import FormSuccess from '../../components/loading/FormSuccess';
import Divider from './components/Divider';
import RegisterForm from './components/RegisterForm';
import ThemeSwitchButton from '../../components/buttons/ThemeSwitchButton';
import { ThemeModeContext } from '../../services/themeService';

const Register = () => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(true);
  const { themeMode, handleThemeToggle } = useContext(ThemeModeContext);

  
  const blackLogo = 'https://tekfall-home.s3.amazonaws.com/digiyo_word__1_-removebg-preview.png';
  const whiteLogo = 'https://tekfall-home.s3.amazonaws.com/image.png';

  return (
    <div className="flex flex-col justify-center min-h-screen items-start h-auto">
      <div className="bg-boxes px-3 py-10 shadow-lg rounded-md text-white min-h-[500px] w-full sm:w-[400px] h-full sm:h-auto">
        {
          isFormOpen
            ?
            <>
              <LazyLoadImage
                className='w-[50%] mx-auto hidden sm:block'
                src={themeMode === 'dark' ? whiteLogo : blackLogo }
                alt='logo'
                effect='blur'
              />
              <h1 className='text-3xl font-semibold text-center sm:hidden mb-10'>Create Account</h1>
              <RegisterForm setIsFormOpen={setIsFormOpen} />
              <div className='text-center mb-3'>
                <Divider />
                <Link className='hover:text-text text-text duration-200' to='/login'>Have an account? <span className='text-notification'>Login</span></Link>
              </div>
            </>
            :
            <FormSuccess message='Account created' redirectTo='login' />
        }
      </div>
    </div>
  );
};

export default Register;
