import React, { useEffect, useRef, useState } from "react";
import { GoVerified } from "react-icons/go";
import { MdOutlineCancel } from "react-icons/md";
import Comments from "./Comments";
import LikeButton from "./buttons/LikeButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { setPostDetails } from "../redux/features/feedSlice";
import { FaComment, FaShare, FaTimes } from "react-icons/fa";
import NumberFormatter from "./NumberFormater";
import { Player } from "./MediaLibrary/Player";
import ResponsiveImageRenderer from "./MediaLibrary/ResponsiveImage";
import FollowButton from "./buttons/FollowButton";
import useFollowerStatus from "../hooks/useFollowerStatus";
import useBlockStatus from "../hooks/useBlockStatus";
import { toast } from "react-hot-toast";
import useAllUsers from "../hooks/useAllUsers";
import useComments from '../hooks/useComments';
import useLikes from '../hooks/useLikes';
import SharePostModal from "./Modals/ShareModal";
import CurrentlyOnlineSkeleton from './loading/skeletons/UsersSkeleton';
import ButtonSkeleton from './loading/skeletons/ButtonSkeleton';
import useAllPosts from "../hooks/useAllPosts";

type IProp = {
  post: Post | null;
  setPost: any;
};

const Detail: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isVideoMuted, setIsVideoMuted] = useState<boolean>(false);
  const [isPostingComment, setIsPostingComment] = useState<boolean>(false);
  const [expandedPosts, setExpandedPosts] = useState<string[]>([]);
  const [comment, setComment] = useState<string>("");
  const [modalOpen, setModalOpen] = useState(false);
  const [post, setPost] = useState<any>([]);
  const [url, setUrl] = useState('');
  const [src, setSrc] = useState<any>();
  const [type, setType] = useState('');
  const [urlLow, setUrlLow] = useState('');
  const [urlMedium, setUrlMedium] = useState('');
  const [urlHigh, setUrlHigh] = useState('');
  const [foundUser, setFoundUser] = useState<any>();
  const [loadComments, setLoadComments] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const pathname = location.pathname; 
  const pathSegments = pathname.split('/'); 
  const postId = pathSegments[pathSegments.length - 1];

  const postDetails = useSelector((state: any) => state?.feed?.postDetails);

  const user = useSelector((state: any) => state?.auth.user);
  const { fetchUserById, isLoading } = useAllUsers(postDetails?.author_id)
  const [incomingUser, setIncomingUser] = useState<any>();
  const [showComments, setShowComments] = useState(false);

  const toggleComments = () => {
    setShowComments((prevShowComments) => !prevShowComments);
  };

  const closeComments = () => {
    setShowComments(false);
  };

  const { 
    isFollower, isFollowing, isFollowLoading, 
    removeFollower, addFollower
  } = useFollowerStatus(postDetails?.author_id);

  const { 
    isBlocked, isBlockLoading,  addBlock, 
    removeBlock 
  } = useBlockStatus(postDetails?.author_id);

  const { 
    addComments, removeComments, isCommentLoading 
  } = useComments(comment, postId);

  const { 
    addLike, removeLike, isLiked, 
    setIsLiked, isLikeLoading 
  } = useLikes(postId);

  const { fetchPostById } = useAllPosts(postId);

  const fetchPost = async () => {
    const response = await fetchPostById();
    const thePost = response?.data?.data?.post;
    dispatch(setPostDetails(thePost));

    console.log('POST MENTAL', thePost);
    const usersWhoLiked = response?.data?.data?.post?.usersWhoLiked;
    const ifCurrentUserLiked = usersWhoLiked?.find( 
      async (userThatLiked: any) => {
        return await userThatLiked?.user_id === user?.id;
      }
    );

    if (ifCurrentUserLiked) {
      setIsLiked(true);
    }
  }

  useEffect(() => {
    fetchPost();
  }, [postDetails?.post_id]);

  console.log('POST DETAILS', postDetails);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const result = await fetchUserById();
        if ('data' in result) {
          const user = result?.data;
          setFoundUser(user);
        } else {
          console.error('API error:', result.message);
        }
      } catch (error) {
          console.error(error);
      }
    }

    fetchAllUsers();
    fetchPost();
  }, [postDetails?.post_id]);

  useEffect(() => {
    if (postDetails && videoRef?.current) {
      videoRef.current.muted = isVideoMuted;
    }
    setSrc(postDetails?.media_items);
  }, [postDetails, isVideoMuted]);

  useEffect(() => {
    if (Array.isArray(src) && src.length > 0) {
      const urlsByType = src[0]; // Assuming src[0] contains type and URLs
      const { type, url } = urlsByType;
      const { low, medium, high } = url;
      setUrl(url);
      setType(type);
      setUrlLow(low);
      setUrlMedium(medium);
      setUrlHigh(high);
    }
  }, [src]);

  //Toggle Posts expansion
  const togglePostExpansion = (postId: string) => {
    setExpandedPosts((prevExpandedPosts) =>
      prevExpandedPosts.includes(postId)
        ? prevExpandedPosts.filter((id) => id !== postId)
        : [...prevExpandedPosts, postId]
    );
  };

  const handleLike = async () => {
    // e.preventDefault();
    if (user) {
      await addLike();
    }
    if (!isLikeLoading) {
      fetchPost();
    }

  };

  const handleUnLike = async () => {
    // e.preventDefault();
    if (user) {
      await removeLike();
    }
    if (!isLikeLoading) {
      fetchPost();
    }
  };

  const addComment = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsPostingComment(true);
    try {
      if (user) {
        if (comment) {
          const res = await addComments();
          const newComment = res?.data?.content;
          if (newComment) {
            setLoadComments(true);
            await fetchPost();
          }
          // fetchPostDetails();
          console.log("COMMENT RES", res);
          setComment("");
        }
      }
    }catch (error) {
      console.log(error);
    } finally {
      setIsPostingComment(false);
      setLoadComments(false);
    }
  };

  const handleFollow = () => {
    try {
        addFollower()
        setTimeout(() => {
            toast.success(`${incomingUser?.username} followed.`, {
                duration: 3000,
                position: 'top-center',
                style: {
                    backgroundColor: '@apply bg-boxes',
                    color: '@apply text-text'
                }
            });
        }, 1000);
    }catch(error: any) {
        // Handle API request errors here
        console.error('API request error:', error);
        // You can also display an error toast or take other actions as needed.
    };
  };
  

const handleUnFollow = () => {
    try {
        removeFollower();
        setTimeout(() => {
            toast.success(`${incomingUser?.username} unfollowed.`, {
                duration: 3000,
                position: 'top-center',
                style: {
                    backgroundColor: '@apply bg-boxes',
                    color: '@apply text-text'
                }
            });
        }, 1000);
    }catch(error: any) {
        // Handle API request errors here
        console.error('API request error:', error);
        // You can also display an error toast or take other actions as needed.
    };
  };

  return (
    <>
      {postDetails && (
        <div className="flex w-screen justify-center h-screen bottom-0 flex-wrap lg:flex-nowrap bg-main">
          <div className="relative flex-2 w-full flex justify-center items-center bg-main bg-no-repeat bg-cover bg-center">
            <div className="opacity-90 absolute top-6 left-2 lg:left-6 flex gap-6 z-50">
              <p className="cursor-pointer " onClick={() => navigate(-1)}>
                <MdOutlineCancel className="text-text text-[35px] hover:opacity-90" />
              </p>
            </div>
            <div className="relative w-full h-full flex flex-col items-center justify-center">
              <div
                className={`
                  video-player border rounded-[7px] border-1 
                  border-transparent flex flex-col justify-center items-center 
                  h-full w-full max-w-[420px] max-h-[590px] relative`}
              >
                {type === 'video' ?
                <>
                    <Player
                      src={[
                        {
                          quality: "Full HD",
                          url: urlHigh
                            // "https://cdn.glitch.me/cbf2cfb4-aa52-4a1f-a73c-461eef3d38e8/1080.mp4",
                        },
                        {
                          quality: 720,
                          url: urlMedium
                            // "https://cdn.glitch.me/cbf2cfb4-aa52-4a1f-a73c-461eef3d38e8/720.mp4",
                        },
                        {
                          quality: 480,
                          url: urlLow
                            // "https://cdn.glitch.me/cbf2cfb4-aa52-4a1f-a73c-461eef3d38e8/480.mp4?v=1647351419495",
                        },
                      ]}
                      subtitles={[
                        {
                          lang: "en",
                          language: "English",
                          url:
                            "https://cdn.jsdelivr.net/gh/naptestdev/video-examples@master/en.vtt",
                        },
                        {
                          lang: "fr",
                          language: "French",
                          url:
                            "https://cdn.jsdelivr.net/gh/naptestdev/video-examples@master/fr.vtt",
                        },
                      ]}
                      minHeight={'590px'}
                      // poster="https://cdn.jsdelivr.net/gh/naptestdev/video-examples@master/poster.png"
                    />
                </>
                : 
                (
                  <ResponsiveImageRenderer 
                    // sizes="(max-width: 800px) 100vw, 50vw"
                    // loading="lazy"
                    minHeight={'590px'} 
                    src={url} 
                    alt="Upload media" 
                    className="h-full w-full border rounded-[7px] border-1 border-boxes"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="relative flex flex-col justify-center items-center w-full max-w-[500px]">
            <div className="w-[100%] rounded-lg border border-transparent  bg-boxes p-4">
              <div
                className="w-full flex flex-row justify-between items-center"
              >
                {isLoading ? 
                  <div className="w-full"><CurrentlyOnlineSkeleton /></div> 
                :   
                    <div className="flex gap-4 mb-4 text-dryBlack w-full cursor-pointer"
                      onClick={() =>
                        navigate(`/profile/${postDetails?.author_id}`)
                      }
                    >
                      {foundUser?.avatar ? (
                        <LazyLoadImage
                          alt="user-profile"
                          className="w-10 h-10 rounded-full"
                          src={foundUser?.avatar}
                        />
                      ) : (
                        <div
                          onClick={() =>
                            navigate(`/profile/${postDetails?.author_id}`)
                          }
                          className="w-10 h-10 flex flex-col text-center cursor-pointer items-center justify-center bg-gray-300 rounded-full text-4xl text-gray-600 font-bold"
                        >
                          {foundUser?.username?.charAt(0)?.toUpperCase()}
                        </div>
                      )}
                      <div>
                        <div className="text-xl text-text font-bold lowercase tracking-wider flex gap-2 items-center justify-center">
                          {foundUser?.username?.replace(/\s+/g, "")}{" "}
                          {foundUser?.is_premium &&<GoVerified className="text-[#FF4500] text-xl" />}
                        </div>
                        <p className="text-md text-white">
                          {" "}
                          @{foundUser?.username?.toLowerCase()}
                        </p>
                      </div>
                    </div>
                  }
                {
                  user?.id != postDetails?.author_id &&
                  (
                    <>
                    {isLoading ? 
                      <ButtonSkeleton />
                    :
                    <>
                      {isFollowing ?
                        (<FollowButton
                          isTextCanClosed
                          text={'Following'}
                          onClick={handleUnFollow}
                          type='button'
                        
                        />
                        ):(
                        <FollowButton
                          isTextCanClosed
                          text={'Follow'}
                          onClick={handleFollow}
                          type='button'
                        
                        />)
                      }
                    </>
                    }</>
                  )
                }
              </div>
              <div className="px-10">
                <p className=" text-md text-text">{postDetails?.caption}</p>
              </div>
              {postDetails?.content?.length > 50 &&
              !expandedPosts.includes(postDetails?.post_id) ? (
                <p className="flex px-10 flex-row font-normal max-w-[380px]">
                  <p className="text-text">{`${postDetails?.content?.slice(0, 30)}`}</p>
                  <button
                    className="text-white"
                    onClick={() => togglePostExpansion(postDetails?.post_id)}
                  >
                    ...See more
                  </button>
                </p>
              ) : (
                <p className="flex px-10 flex-row font-normal text-text max-w-[480px]">
                  <p>{`${postDetails?.content}`}</p>
                </p>
              )}
              </div>
              <div className="mt-10 px-10">
                <SharePostModal
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                  caption={postDetails?.caption}
                  username={postDetails?.username}
                />
                {user && (

                  <div className={`
                    flex flex-row h-[40px] lg:justify-between max-w-[70%] xl:justify-between`
                    }
                  > 
                  {!isLiked ? 
                    (<LikeButton
                      likes={postDetails?.totalLikes}
                      flex="flex"
                      handleLike={handleLike}
                    />
                    ):(
                    <LikeButton
                      likes={postDetails?.totalLikes}
                      color={'[#FF4500]'}
                      flex="flex"
                      handleLike={handleUnLike}
                    />)
                  }
                    <div className="flex flex-row relative w-[110px] bg-boxes border border-1 border-transparent rounded-full p-3 justify-between cursor-pointer items-center mr-5 space-x-1">
                      <button onClick={toggleComments} className="">
                      <FaComment className="text-white text-2xl" />
                      </button>
                      <div className="font-medium rounded-full text-md text-notification">
                          <NumberFormatter number={postDetails?.totalComments}/>
                      </div>
                    </div>
                    <div className="flex flex-row w-[110px] relative cursor-pointer items-center mr-5 space-x-1 bg-boxes border justify-between border-1 border-transparent rounded-full p-3">
                        <button className="">
                          <FaShare onClick={() => setModalOpen(true)} className="text-white text-2xl" />
                        </button>
                        <div className="font-medium rounded-full text-md text-notification">
                            <NumberFormatter number={10}/>
                        </div>
                    </div>
                  </div> 
                )}
              </div>
             {showComments && (<div
                className={
                  `fixed lg:hidden w-full bottom-0 left-0 right-0 bg-main z-50 overflow-y-auto 
                  transition-transform duration-300 ease-in-out ${
                  showComments ? "transform-translateY-0 block" : "transform-translateY-full hiiden"
                } md:static md:h-auto`}
              >
                {showComments && (
                <button
                  className="absolute top-2 left-2 text-gray-600"
                  onClick={() => closeComments()}
                >
                  <FaTimes className="text-text text-2xl" />
                </button>
              )}
                <Comments
                  comment={comment}
                  setComment={setComment}
                  addComment={addComment}
                  load={loadComments}
                  comments={postDetails?.commentsWithUsers}
                  isPostingComment={isPostingComment}
                />
              </div>)}
                <div className="lg:block hidden w-full">
                  <Comments
                    comment={comment}
                    setComment={setComment}
                    addComment={addComment}
                    load={loadComments}
                    comments={postDetails?.commentsWithUsers}
                    isPostingComment={isPostingComment}
                  />
                </div>
            </div>
          </div>
      )}
    </>
  );
};

export default Detail;
