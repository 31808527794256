import React, { FC, useEffect, useState } from "react";
import { FaRegEdit, FaShare } from "react-icons/fa";
import { HiUserAdd, HiUserRemove } from "react-icons/hi";
import { ImBlocked } from "react-icons/im";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import useBlockStatus from "../../../hooks/useBlockStatus";
import useFollowerStatus from "../../../hooks/useFollowerStatus";
import { getAllUser } from "../../../services/userService";
import { Toaster, toast } from "react-hot-toast";
import ProfileModal from '../../../components/Modals/ProfileModal'
import { useDispatch } from "react-redux";
import FollowButton from "../../../components/buttons/FollowButton";
import useAllUsers from "../../../hooks/useAllUsers";
import SharePostModal from "../../../components/Modals/ShareModal";

const Info: React.FC<any> = ({ otherUser }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector((state: any) => state.auth.user);
    const [profileModal, setProfileModal] = useState<boolean>(false);
    const [shareModal, setShareModal] = useState<boolean>(false);

    const { 
        isFollower, isFollowing, isFollowLoading, 
        isFollowerById, nowFollowingOrNot,
        removeFollower, addFollower
    } = useFollowerStatus(otherUser?.user_id);

    const { 
        isBlocked, isBlockLoading,  addBlock, removeBlock 
    } = useBlockStatus(otherUser?.user_id);

    const handleFollow = () => {
        try {
            addFollower()
            setTimeout(() => {
                toast.success(`${otherUser?.username} followed.`, {
                    duration: 3000,
                    position: 'top-center',
                    style: {
                        backgroundColor: '@apply bg-boxes',
                        color: '@apply text-text'
                    }
                });
            }, 1000);
        }catch(error: any) {
            // Handle API request errors here
            console.error('API request error:', error);
            // You can also display an error toast or take other actions as needed.
        };
      };
      

    const handleUnFollow = () => {
        try {
            removeFollower();
            setTimeout(() => {
                toast.success(`${otherUser?.username} unfollowed.`, {
                    duration: 3000,
                    position: 'top-center',
                    style: {
                        backgroundColor: '@apply bg-boxes',
                        color: '@apply text-text'
                    }
                });
            }, 1000);
        }catch(error: any) {
            // Handle API request errors here
            console.error('API request error:', error);
            // You can also display an error toast or take other actions as needed.
        };
      };

      const handleBlock = () => {
        try {
            addBlock()
            setTimeout(() => {
                toast.success(`${otherUser?.username} blocked.`, {
                    duration: 3000,
                    position: 'top-center',
                    style: {
                        backgroundColor: '@apply bg-boxes',
                        color: '@apply text-text'
                    }
                });
            }, 1000);
        }catch(error: any) {
            // Handle API request errors here
            console.error('API request error:', error);
            // You can also display an error toast or take other actions as needed.
        };
      };
      

    const handleUnBlock = () => {
        try {
            removeBlock();
            setTimeout(() => {
                toast.success(`${otherUser?.username} unblocked.`, {
                    duration: 3000,
                    position: 'top-center',
                    style: {
                        backgroundColor: '@apply bg-boxes',
                        color: '@apply text-text'
                    }
                });
            }, 1000);
        }catch(error: any) {
            // Handle API request errors here
            console.error('API request error:', error);
            // You can also display an error toast or take other actions as needed.
        };
      };
      
    
    console.log('OTHER USER', otherUser, isFollowing);
    return (
        <div className="w-full min-h-[300px] flex flex-col flex xl:flex-row lg:flex-row xl:flex-row justify-between items-center py-1 xl:py-10">
            <div className="h-full w-full max-w-[600px] flex xl:flex-row lg:flex-row items-center flex-col my-5 p-3 relative">
                <div className="absolute top-0 border border-1 border-boxes rounded-md left-0 w-full h-full z-0">
                    {typeof otherUser === 'object' && otherUser?.avatar ? (
                        <LazyLoadImage 
                            src={otherUser?.avatar} alt="Background" 
                            className="w-full h-full rounded-md object-cover" 
                        />
                    ) : (
                        <div
                            className="w-full h-full object-cover"
                        >
                            {typeof otherUser === 'object' && otherUser?.username?.charAt(0).toUpperCase()}
                        </div>
                    )}
                </div>
                <span className="relative z-10">
                    {typeof otherUser === 'object' && otherUser?.avatar ? (
                        <LazyLoadImage
                            src={otherUser?.avatar}
                            alt="user-pp"
                            className="w-26 h-26 sm:w-32 border border-2 border-text cursor-pointer sm:h-32 md:w-26 md:h-26 lg:w-26 lg:h-26 xl:w-52 xl:h-52 object-cover rounded-full mx-auto mb-5 xl:mb-0"
                            onClick={() => setProfileModal(!profileModal)}
                        />
                    ) : (
                        <div
                            onClick={() => setProfileModal(!profileModal)}
                            className="w-26 h-26 sm:w-32 cursor-pointer sm:h-32 md:w-26 md:h-26 lg:w-26 lg:h-26 xl:w-52 xl:h-52 flex items-center justify-center bg-gray-300 rounded-full text-4xl sm:text-5xl text-gray-600 font-bold p-2"
                        >
                            {typeof otherUser === 'object' && otherUser?.username?.charAt(0).toUpperCase()}
                        </div>
                    )}
                </span>
            </div>

            <div className="h-full w-full max-w-[400px] p-3">
                {/* <p className="text-2xl font-semibold my-2 xl:text-start text-white text-center">{otherUser?.fname + ' ' + otherUser?.lname}</p> */}
                <p className="text-md font-semibold my-2 xl:text-start text-white text-center">@{otherUser?.username?.toLowerCase()}</p>
                <p className="min-h-fit text-text">{otherUser?.bio ? otherUser?.bio : 'No Information.'}</p>
                <ProfileModal
                  modalOpen={profileModal}
                  setModalOpen={setProfileModal}
                  incomingUser={otherUser}
                />
                <SharePostModal
                  modalOpen={shareModal}
                  setModalOpen={setShareModal}
                  username={otherUser?.username}
                />
                <div className="flex">
                    {
                        otherUser?.user_id === user?.id
                            ?
                            <>
                                <FollowButton
                                    Icon={FaRegEdit}
                                    text='Edit'
                                    onClick={() => navigate('/profile/settings')}
                                    type='button'
                                />
                                <FollowButton
                                    Icon={FaShare}
                                    text='Share'
                                    onClick={() => setShareModal(!shareModal)}
                                    type='button'
                                />
                            </>
                            :
                            <>
                                {
                                    isFollowing || nowFollowingOrNot
                                    ?
                                        (
                                        <FollowButton
                                            isTextCanClosed
                                            Icon={HiUserRemove}
                                            text={'Following'}
                                            onClick={handleUnFollow}
                                            type='button'
                                        
                                        />
                                        ):(
                                        <FollowButton
                                            isTextCanClosed
                                            Icon={HiUserAdd}
                                            text={'Follow'}
                                            onClick={handleFollow}
                                            type='button'
                                        
                                        />)
                                }
                                {
                                    isBlocked
                                        ?
                                        (<FollowButton
                                            isTextCanClosed
                                            Icon={ImBlocked}
                                            text={'Unblock'}
                                            onClick={handleUnBlock}
                                            type='button'
                                        
                                        />
                                        ):(
                                        <FollowButton
                                            isTextCanClosed
                                            Icon={ImBlocked}
                                            text={'Block'}
                                            onClick={handleBlock}
                                            type='button'
                                        
                                        />)
                                }
                            </>
                    }
                </div>
                <Toaster />
            </div>
        </div>
    )
}

export default Info;