import axios from "axios";
import axiosWithAuth from "../utils/axiosWithAuth";
import { API_BASE_URL, CLOUD_NAME, UPLOAD_PRESET } from "../utils/constants";
import axiosFormDataWithAuth from "../utils/axiosFormDataWithAuth";

export const getUserById = async (userId: any) => {
    try {
      return await axiosWithAuth.get(`/users/${userId}`);
    } catch (error) {
      // Handle error
      throw new Error('Failed to fetch user');
    }
};

export const getUserWithoutId = async () => {
    try {
      return await axiosWithAuth.get(`/users`);
    } catch (error) {
      // Handle error
      throw new Error('Failed to fetch user');
    }
};

export const getAllUser = async () => {
    try {
        return await axiosWithAuth.get(`/users`);
    } catch (error) {
        console.log('CREATE USER ERROR', error)
        return { statusCode: '409', message: (error as any)?.response?.data?.message };
    }
};
  
export const getUsersBySearch = async (search: string) => {
    const { data } = await axiosWithAuth.get(`/users/?search=${search}`);
    return data;
}

export const updateUser = async (user: any) => {
    return await axiosFormDataWithAuth.put(`users`, user);
};

export const updatePassword = async (data: any) => {
    return await axiosWithAuth.put(`users/update/password`, data);
};

export const deleteAccount = async () => {
    return await axiosWithAuth.delete(`users/delete/account`);
}

// IMAGES
export const uploadImages = async (images: any) => {
    const results: any[] = []

    for (let i = 0; i < images.length; i++) {
        const formData = new FormData();
        formData.append('file', images[i]);
        formData.append('upload_preset', UPLOAD_PRESET!);
        const { data } = await axios.post(`https://api.cloudinary.com/v1_1/${CLOUD_NAME}/upload`, formData);
        results.push(data.secure_url);
    }

    return results;
}

export const uploadUserImage = async (image: string) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('upload_preset', UPLOAD_PRESET!);
    const { data } = await axios.post(`https://api.cloudinary.com/v1_1/${CLOUD_NAME}/upload`, formData);
    return data.secure_url
}

// FRIENDS
export const getFollowers = async () => {
    const { data } = await axiosWithAuth.get(`/users/my/followers`);
    return data;
}

export const setFollower = async (followedId: string) => {
    const { data } = await axiosWithAuth.post(`/users/follow/${followedId}`);
    return data;
}

export const unFollow = async (followedId: string) => {
    const { data } = await axiosWithAuth.delete(`/users/follow/${followedId}`);
    return data;
}

export const getFollowersById = async (userId: string) => {
    const { data } = await axiosWithAuth.get(`/users/${userId}/followers`);
    return data;
}

export const getFollowingsById = async (userId: string) => {
    const { data } = await axiosWithAuth.get(`/users/${userId}/followings`);
    return data;
}

export const getAllFollowings = async () => {
    const { data } = await axiosWithAuth.get(`/users/my/followings`);
    return data;
}

// REQUESTS
export const getRequests = async (id: string) => {
    const { data } = await axiosWithAuth.get(`/users/${id}/request`);
    return data;
}

export const setRequest = async (id: string, otherId: string, status: boolean) => {
    const { data } = await axiosWithAuth.put(`/users/${id}/request`, {
        otherId,
        status
    });
    return data;
}

// BLOCK
export const getBlocked = async (id: string) => {
    const { data } = await axiosWithAuth.get(`/users/${id}/block`);
    return data;
}

export const getAllBlocked = async () => {
    const { data } = await axiosWithAuth.get(`/users/my/blocks`);
    return data;
}

export const setBlock = async (blockedUserId: string) => {
    const { data } = await axiosWithAuth.post(`/users/block/${blockedUserId}`);
    return data;
}

export const setUnBlock = async (unBlockUserId: string) => {
    const { data } = await axiosWithAuth.delete(`/users/block/${unBlockUserId}`);
    return data;
}

export const checkBlock = async (userId: string, id: string) => {
    const { data } = await axiosWithAuth.get(`/users/${userId}`);
    const blocked: string[] = data.user.blocked;
    if (!blocked) return false;

    const isBlocked = blocked.includes(id);
    return isBlocked;
}

export const joinFoundersClub = async (intent_id: any, email: any) => {
    try {
      const { data } = await axios.post(
        `http://35.171.154.42:3000/api/users/join/founders/club`,
        {
          intent_id,
          email,
        }
      );
      return data;
    } catch (error) {
      // Handle error
      throw new Error('Failed to join founders club');
    }
  };
  