// NumberFormatter.tsx
import React from "react";

const formatNumber = (number?: any) => {
  if (number >= 1000000) {
    const num = number / 1000000;
    return num % 1 === 0 ? `${num}M` : `${num?.toFixed(1)}M`;
  } else if (number >= 1000) {
    const num = number / 1000;
    return num % 1 === 0 ? `${num}K` : `${num?.toFixed(1)}K`;
  } else if (number >= 100) {
    return number?.toString();
  } else if (number >= 10) {
    return number?.toString();
  } else {
    return number?.toString();
  }
};

interface NumberFormatterProps {
  number?: number;
}

const NumberFormatter: React.FC<NumberFormatterProps> = ({ number }: any) => {
  return <>{formatNumber(number)}</>;
};

export default NumberFormatter;
