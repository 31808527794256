import React, { Dispatch, SetStateAction, useEffect } from "react";
import { GoVerified } from "react-icons/go";
import { useSelector } from 'react-redux'
import NoResults from "./NoResults";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import useAllUsers from "../hooks/useAllUsers";
import Spinner from "./loading/Spinner";
import TextInput from "./inputs/TextInput";

interface IProps {
  isPostingComment: Boolean;
  comment: string;
  loadComments?: any;
  setComment: Dispatch<SetStateAction<string>>;
  addComment: (e: React.FormEvent) => void;
  comments: IComment[];
}

interface IComment {
  id: string;
  comment: string;
  length?: number;
  _key: string;
  commentedBy: { _ref?: string; _id?: string, userName: string, image: string };
}

const Comments = ({
  comment,
  setComment,
  addComment,
  comments,
  loadComments,
  isPostingComment,
}: any) => {

  const user = useSelector((state: any) => state.auth.user);
  const allUsers = useSelector((state: any) => state.auth.allUsers);
  const navigate = useNavigate();

  // const commentsUsersId = comments?.map((com: any)=> {
  //   return com?.commentsWithUsers?.filter((comUser:any) => {
  //     return comUser?.user_id;
  //   })
  // })

  // console.log('COMMENT', commentsUsersId)
  // const { fetchUserById } = useAllUsers(commentsUsersId);

  // useEffect(()=> {
  //   const res = fetchUserById();
  //   console.log('COMMENT', res)
  // }, [])


  return (
    <div className="border-t flex w-full flex-col justify-center items-center border-lines pt-4 px-10 mt-4 bg-main border-b-2 lg:pb-0 pb-[20px]">
      <>
      {loadComments ? 
        (<div className="overflow-scroll w-full h-[400px]">
          <Spinner size={"sm"} />
        </div>)
      :
      (<div className="overflow-scroll w-full h-[400px]">
        {comments?.length > 0 ? (
          comments?.map((item: any, idx: number) => {
            const userCommented = item?.commentsWithUsers?.find(
              (isMe: any )=> isMe?.user?.user_id === user?.id
            );
            return (<div className="p-2 items-center" key={item?.comment?.comment_id}>
                  <div onClick={() => navigate(`/profile/${item?.user?.user_id}`)}>
                    <div className="flex items-start gap-3">
                      <div className="w-12 h-12">
                        {item?.user?.avatar || item?.user?.avatar != null ? (
                          <LazyLoadImage
                            className="w-10 h-10 rounded-full cursor-pointer"
                            src={item?.user?.avatar}
                            alt="user-profile"
                          />
                          ) : (
                          <div 
                            onClick={() => navigate(`/profile/${item?.user?.user_id}`)}
                            className="w-12 h-12 flex flex-col text-center cursor-pointer items-center justify-center bg-gray-300 rounded-full text-4xl text-gray-600 font-bold">
                            {item?.user?.username.charAt(0)?.toUpperCase()}
                          </div>
                        )}
                      </div>
                      <p className="flex cursor-pointer gap-1 items-center text-[18px] font-bold leading-6 text-text">
                        {userCommented ? 'You' : item?.user?.username}{" "}
                        {/* <GoVerified className="text-[#FF4500]" /> */}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="-mt-5 ml-16 text-[16px] mr-8 text-text">
                      {item?.comment?.content}
                    </p>
                  </div>
                </div>
            );
          })
        ) : ( null
          // <NoResults text="No Comments Yet! Be First to do, add the comment." />
        )}
      </div>)
      }</>
      {user && (
        <div className="relative pb-5">
          <form onSubmit={addComment} className="flex gap-4 w-[inherit]">
            <input
              value={comment}
              onChange={(e) => setComment(e.target.value.trim())}
              className="bg-boxes px-6 py-2 w-full max-w-[300px] text-md text-text font-medium border border-boxes focus:outline-none focus:border-2 focus:border-gray-300 flex-1 rounded-md"
              placeholder="Leave a comment.."
            />
            <button className="text-md bg-boxes p-3 rounded-md text-white" onClick={addComment}>
              {isPostingComment ? "Posting..." : "Post"}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Comments;