import { FC } from "react";
import { toast, Toaster } from "react-hot-toast";
import { BiBlock, BiMessageDots } from "react-icons/bi";
import { IoPersonRemoveSharp, IoPersonAddSharp } from "react-icons/io5";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import TransparentFollowButton from "../../../../components/buttons/TransparentFollowButton";
import useBlockStatus from "../../../../hooks/useBlockStatus";
import useFollowerStatus from "../../../../hooks/useFollowerStatus";
import { createChannel } from "../../../../services/channelService";
import checkIsChannelExist from "../../../../utils/checkIsChannelExist";
import { setRefresh } from "../../../../redux/features/channelSlice";
import { GoVerified } from "react-icons/go";

type Props = {
    followings?: any;
    isFollowingById?: any;
    isFollowing?: any;
    isBlocked?: any;
    isFollowLoading?: any;
    nowFollowingOrNot?: any;
    incomingId?: any;
    handleAdd?: any;
    handleBlock?: any;
    handleUnBlock?: any;
    handleRemove?: any;
    isBlockLoading?: any;
};

const FollowingBox: FC<Props> = (
    { 
        isFollowingById, isBlocked, isFollowing, isFollowLoading,
        incomingId, followings, handleAdd, nowFollowingOrNot,
        handleBlock, handleUnBlock, handleRemove, isBlockLoading
    }
) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.auth.user)
    const { refresh } = useSelector((state: any) => state.channel);

    const handleClickMessage = async () => {
        const channelId = await checkIsChannelExist(user?.id!, followings?.user_id);

        if (channelId) return navigate('/chat', { state: { channelId } });

        const { statusCode, channel } = await createChannel({
            participants: [
                user?.id,
                followings?.user_id
            ]
        });

        dispatch(setRefresh());
        if (statusCode === '201') return navigate('/chat', { state: { channelId: channel.id } });
    };

    console.log('FOLLOWINGBOX', followings)
    return (
        <>
            <div className="flex flex-row gap-3 w-full justify-start hover:bg-primary p-2 cursor-pointer font-semibold rounded-sm items-center">
                {followings?.avatar ? (
                    <LazyLoadImage
                        onClick={() => navigate(`/profile/${followings?.user_id}`)}
                        src={followings?.avatar}
                        alt="user-profile"
                        className="w-10 h-10 object-cover rounded-full cursor-pointer"
                    />
                ) : (
                    <div 
                        onClick={() => navigate(`/profile/${followings?.user_id}`)}
                        className="w-10 h-10 object-cover rounded-full cursor-pointer flex flex-col text-center items-center bg-gray-300 text-4xl text-gray-600 font-bold"
                    >
                    {followings?.username?.charAt(0).toUpperCase()}
                    </div>
                )}
                <div 
                    onClick={() => navigate(`/profile/${followings?.user_id}`)} 
                    className="hidden lg:block xl:block">
                    <p 
                       className="flex gap-1 items-center text-md font-bold text-text lowercase"
                    >
                    {followings?.username?.replace(/\s+/g, "")}{" "}
                    {followings?.is_premium &&<GoVerified className="text-[#FF4500]" />}
                    </p>
                    <p 
                        className="capitalize text-white text-xs"
                    >
                        {followings?.username}
                    </p>
                </div>
                <div className="ml-10 flex flex-row items-center justify-center">
                    {
                     
                        <>
                            {
                                isFollowing
                                    ?
                                    (<TransparentFollowButton
                                        isTextCanClosed
                                        // Icon={IoPersonRemoveSharp}
                                        text={'Following'}
                                        type="button"
                                        onClick={handleRemove}
                                        
                                    />
                                    ):(
                                    <TransparentFollowButton
                                        isTextCanClosed
                                        // Icon={IoPersonAddSharp}
                                        text={'Follow'}
                                        type="button"
                                        onClick={handleAdd}
                                        
                                    />)
                            }
                            {
                                isBlocked
                                    ?
                                    (<TransparentFollowButton
                                        isTextCanClosed
                                        // Icon={BiBlock}
                                        text={isBlockLoading ? 'Unblocking...' : 'Unblock'}
                                        type="button"
                                        onClick={handleUnBlock}
                                        
                                    />
                                    ):(
                                    <TransparentFollowButton
                                        isTextCanClosed
                                        // Icon={BiBlock}
                                        text={isBlockLoading ? 'Blocking...' : 'Block'}
                                        type="button"
                                        onClick={handleBlock}
                                        
                                    />)
                            }
                        </>
                    }
                </div>
            </div>
            <Toaster />
        </>
    )
}

export default FollowingBox;