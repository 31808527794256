import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BiSearchAlt2 } from "react-icons/bi";
import { useSelector } from "react-redux";

const Search = () => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const suggestionsRef = useRef<HTMLUListElement | null>(null);

  const posts = useSelector((state: any) => state.feed.posts);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setShowSuggestions(true);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target as Node)
    ) {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion: any) => {
    setSearchQuery(suggestion?.caption);
    setShowSuggestions(false);
    navigate(`/posts?q=${encodeURIComponent(suggestion?.caption)}`);
  };

  const suggestions = Array.isArray(posts)
  ? posts.filter((suggestion: any) => {
      const caption = suggestion?.caption;
      const query = searchQuery;
  
      if (typeof caption === 'string' && typeof query === 'string') {
        return caption.toLowerCase().includes(query.toLowerCase());
      }
  
      return false;
    })
  : [];



  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className="w-full block flex flex-col items-center"
    >
      <div className="relative w-full group">
        <input
          name="search"
          className="p-3 w-full rounded-lg text-neutral-400 bg-boxes outline-none"
          type="text"
          value={searchQuery}
          onChange={handleInputChange}
          placeholder="Search Feed..."
          spellCheck="false"
        />
        <button
          className="absolute right-2 text-3xl top-2 text-neutral-400"
          type="submit"
        >
          <BiSearchAlt2 />
        </button>
        {searchQuery && showSuggestions && (
          <ul
            ref={suggestionsRef}
            className="absolute bg-boxes border p-5 z-50 border-transparent mt-1 w-full max-h-90 overflow-y-auto rounded-lg shadow-md"
          >
            <h4 className="mb-3 text-text">Recent searches</h4>
            {suggestions.map((suggestion: any) => (
              <li
                key={suggestion?.post_id}
                className="p-2 hover:bg-main border border-transparent rounded-lg text-lg text-white cursor-pointer"
              >
                <button
                  type="button"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion?.caption}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </form>
  );
};

export default Search;