import { BsCode, BsEmojiSunglasses } from 'react-icons/bs';
import { GiCakeSlice, GiGalaxy, GiLipstick } from 'react-icons/gi';
import { FaPaw, FaMedal, FaGamepad } from 'react-icons/fa';
import React from 'react';

export const topics: { name: string; icon: JSX.Element }[] = [
  {
    name: 'Characters',
    icon: <BsEmojiSunglasses />,
  },
  {
    name: 'Environments',
    icon: <BsCode />,
  },
  {
    name: 'Wrestling Moves',
    icon: <GiGalaxy />,
  },
  {
    name: 'Wearables',
    icon: <GiCakeSlice />,
  },
  {
    name: 'Game Mechanics',
    icon: <GiLipstick />,
  },
  // {
  //   name: 'Sports',
  //   icon: <FaMedal />,
  // },
  {
    name: 'Game Modes',
    icon: <FaGamepad />,
  }
  // {
  //   name: 'animals',
  //   icon: <FaPaw />,
  // }
];

export const footerList1 = ['About', 'Newsroom', 'Store', 'Contact', 'Carrers', 'ByteDance', 'Creator Directory'];
export const footerList2 = ['Tekfall Supreme for Good', 'Advertise', 'Developers', 'Transparency', 'TikTik Rewards'];
export const footerList3 = ['Help', 'Safety', 'Terms', 'Privacy', 'Creator Portal', 'Community Guidelines'];