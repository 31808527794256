import React from "react";
import { Link } from "react-router-dom";

export default function DemoText() {
  return (
    <div className="bg-main p-4 text-text rounded-lg mt-4 text-center">
      <span>
        This is {" "}
        <Link
          to="/login"
          className="text-notification hover:underline"
        >
          DigiYo Payment
        </Link>{" "}
          , all rights reserved.{" "}
        <Link
          to="/login"
          className="text-notification hover:underline"
        >
          Terms, & conditions.
        </Link>
      </span>
    </div>
  );
};