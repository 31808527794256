import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MainModal from "./MainModal";

function ProfileModal({ modalOpen, setModalOpen, incomingUser }) {
  return (
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="flex flex-col items-center justify-center sm:w-4/5 border border-border md:w-3/5 lg:w-2/5 w-full p-10 overflow-y-auto bg-main text-white rounded-2xl">
        {incomingUser?.avatar ? (
        <LazyLoadImage
            src={incomingUser?.avatar}
            alt="user-pp"
            className="w-64 h-64 object-cover rounded-full border-1 border border-boxes cursor-pointer"
        />
        ) : (
        <div className="w-64 h-64 object-cover rounded-full cursor-pointer flex items-center justify-center bg-gray-300 text-4xl sm:text-5xl text-gray-600 font-bold">
            {incomingUser?.username?.charAt(0).toUpperCase()}
        </div>
        )}
      </div>
    </MainModal>
  );
}

export default ProfileModal;