import { FC, ReactNode } from 'react'

type Props = {
  type?: any;
  onClick?: any;
  width?: any;
  disabled?: any;
  background?: any;
  children?: ReactNode;
}

const BasicButton: FC<Props> = ({ type, disabled, onClick, children, background, width }) => {
  return (
    <button 
      type={type} onClick={onClick} disabled={disabled} 
      className={`w-full rounded-md ${disabled? 
        'disabled-button' : `${background ? background :'bg-button'}`} 
        w-${width ? width : ''} text-text p-3 hover:bg-buttonHover duration-200`}
      >
      {children}
    </button>
  )
}

export default BasicButton;