import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
    getFollowersById, getFollowingsById, getAllFollowings, 
    getAllUser, setFollower, unFollow, getFollowers 
} from '../services/userService';
import { setFollowers, setFollowing } from '../redux/features/feedSlice';

const useFollowerStatus = (incomingId: any) => {
    const user = useSelector((state: any) => state.auth.user);
    const [isFollower, setIsFollower] = useState<boolean>(true);
    const [isFollowing, setIsFollowing] = useState<boolean>(true);
    const [isFollowerById, setIsFollowerById] = useState<boolean>(true);
    const [isFollowingById, setIsFollowingById] = useState<boolean>(true);
    const [isFollowLoading, setIsFollowLoading] = useState<boolean>(false);
    const [followingById, setFollowingsById] = useState<any>([]);
    const [followerById, setFollowersById] = useState<any>([]);
    const [nowFollowingOrNot, setNowFollowingOrNot] = useState<boolean>(false);
    const dispatch = useDispatch();
    const following = useSelector((state: any) => state?.feed?.followings);
    const follower = useSelector((state: any) => state?.feed?.followers)

    const fetchAllFollowings = async () => {
        // setIsFollowLoading(true);
        try {
            const result = await getAllFollowings();
            const data = result;
            // setFollowings(data);
            dispatch(setFollowing({ followings: data }));
            const followingUser = data.find(
                (user: any) => user?.user_id === incomingId);
            if ( 
                followingUser
            ) {
                setIsFollowing(true);
            } else {
                setIsFollowing(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            // setIsFollowLoading(false);
        }
    };

    const fetchAllFollowingsById = async () => {
        // setIsFollowLoading(true);
        try {
            const result = await getFollowingsById(incomingId);
            const currentUserResult = await getFollowingsById(user?.id);
            const followingUserInResult = result;
            const followingUserInCurrentUser = currentUserResult;
    
            setFollowingsById(followingUserInResult);
    
            // Find common users between followingUserInResult and followerUserInCurrentUser
            const commonUsers = followingUserInResult.filter((userResult: any) =>
                followingUserInCurrentUser.some(
                    (currentUser: any) => currentUser?.user_id === userResult?.user_id
                )
            );
            // If there are common users, set isFollower to true; otherwise, set it to false
            if (commonUsers?.length > 0) {
                setIsFollowingById(true);
            } else {
                setIsFollowingById(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            // setIsFollowLoading(false);
        }
    }

    const fetchAllFollowersById = async () => {
        // setIsFollowLoading(true);
        try {
            const result = await getFollowersById(incomingId);
            const currentUserResult = await getFollowersById(user?.id);
            const followerUserInResult = result;
            const followerUserInCurrentUser = currentUserResult;
    
            setFollowersById(followerUserInResult);
    
            // Find common users between followerUserInResult and followerUserInCurrentUser
            const commonUsers = followerUserInResult.filter((user: any) =>
                followerUserInCurrentUser.some(
                    (currentUser: any) => currentUser.user_id === user.user_id
                )
            );
    
            // If there are common users, set isFollower to true; otherwise, set it to false
            if (commonUsers?.length > 0) {
                setIsFollowerById(true);
            } else {
                setIsFollowerById(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            // setIsFollowLoading(false);
        }
    }
    

    const fetchAllFollowers = async () => {
        // setIsFollowLoading(true);
        try {
            const result = await getFollowers();
            const data = result;
            const followerUser = data.find(
                (user: any) => user?.user_id === incomingId);
            dispatch(setFollowers({ followers: data }));
            // setFollowers(followers);
            if ( 
                followerUser
            ) {
                setIsFollower(true);
            } else {
                setIsFollower(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            // setIsFollowLoading(false);
        }
    }

    useEffect(() => {
        fetchAllFollowersById();
        fetchAllFollowingsById();
        fetchAllFollowings();
        fetchAllFollowers();
    }, [incomingId]);

    const addFollower = async () => {
        setIsFollowLoading(true);
        try {  
            setNowFollowingOrNot(true);
            await setFollower(incomingId);
            fetchAllFollowersById();
            fetchAllFollowingsById();
            fetchAllFollowings();
            fetchAllFollowers();
            setIsFollowing(true);
            setIsFollowingById(true);
        } catch (error) {
            console.log(
                error
            );
        } finally { 
            setIsFollowLoading(false);
        }
    }

    const removeFollower = async () => {
        setIsFollowLoading(true);
        try {  
            setNowFollowingOrNot(false);
            await unFollow(incomingId);
            fetchAllFollowersById();
            fetchAllFollowingsById();
            fetchAllFollowings();
            fetchAllFollowers();
            setIsFollowing(false);
            setIsFollowingById(false);
        } catch (error) {
            console.log(
                error
            );
        } finally { 
            setIsFollowLoading(false);
        }
    }

    return { 
        isFollowLoading, isFollower, following, follower, 
        isFollowing, followingById, followerById, isFollowerById, 
        isFollowingById, nowFollowingOrNot, fetchAllFollowingsById, 
        addFollower, removeFollower, fetchAllFollowings, fetchAllFollowers
    };
}

export default useFollowerStatus;