import { FC, ReactNode } from "react";

type Props = {
    children: ReactNode;
}

const ContentArea: FC<Props> = ({ children }) => {
    return (
        <div
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="10"
            data-aos-offset="200"
            id="autoflow"
            className="flex-grow bg-main w-fit max-h-[100vh] sm:min-w-[320px] overflow-y-scroll overflow-x-hidden">
            {children}
        </div>
    )
}

export default ContentArea;