import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

const UserBox: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.user);
  const [loggedUser, setLoggedUser] = useState<any>();
  const [image, setImage] = useState<any>();
  const [lastUser, setLastUser] = useState<string>(
    Cookies.get("last_user") || ""
  );

  useEffect(() => {
    const fetchUser = async () => {
      setLoggedUser( await user);
    };
    fetchUser();
  }, [user?.id]);

  useEffect(() => {
    const imgUrl =
      loggedUser?.image instanceof Blob
        ? URL.createObjectURL(loggedUser.image)
        : loggedUser?.image;

    if (imgUrl && imgUrl?.startsWith("blob:")) {
      const filename = imgUrl.split("/").pop() || "";
      const newImgUrl = `http://localhost:3000/${filename}`;
      setImage(newImgUrl);
    } else {
      setImage(imgUrl);
    }
  }, [loggedUser?.image]);

  return (
    <div className="p-3 flex items-center relative   h-22">
      {image ? (
        <LazyLoadImage
          onClick={() => navigate(`/profile/${user?.id}`)}
          className="w-10 h-10 object-cover rounded-full cursor-pointer"
          src={image}
          alt="user-pp"
        />
      ) : (
        <div
          onClick={() => navigate(`/profile/${user?.id}`)}
          className="w-10 h-10 flex flex-col text-center cursor-pointer items-center justify-center bg-gray-300 rounded-full text-4xl text-gray-600 font-bold"
        >
          {loggedUser?.username?.charAt(0).toUpperCase()}
        </div>
      )}
    </div>
  );
};

export default UserBox;
