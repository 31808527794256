import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useSelector } from 'react-redux'

import PageInfo from '../../components/layout/ContentArea/PageInfo'
import { useEffect, useRef, useState } from 'react'
import { getUserWithoutId, updateUser, uploadUserImage } from '../../services/userService'
import BasicButton from '../../components/buttons/BasicButton'
import { toast, Toaster } from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { setUser } from '../../redux/features/authSlice'


const getFileNameWithoutExtension = (fileName: any) => {
    const extensionIndex = fileName?.lastIndexOf('.');
    if (extensionIndex !== -1) {
      return fileName?.substring(0, extensionIndex);
    }
    return fileName;
  };
  
  const formatFileName = (fileName: any) => {
    return fileName?.replace(/_/g, ' ');
  };
const ChangeEmail = () => {
    const user = useSelector((state: any) => state.auth.user);
    const [details, setDetails] = useState<any>();
    const [image, setImage] = useState<any>(null);
    const [photo, setPhoto] = useState<any>(null);
    const [name, setName] = useState<string>('');
    const inputRef = useRef<any>(null);

    const dispatch = useDispatch();

    // useEffect(() => {
    //     const fetchUser = async () => {
    //         const result = await getUserWithoutId(user?.id!);
    //         setDetails(result.user);
    //     };

    //     fetchUser();
    // }, [user?.id]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const userId = user?.id
        const previouspassword = e.target.previouspassword.value;
        const newpassword = e.target.newpassword.value;
        const confirmpassword = e.target.confirmpassword.value;
        
        const formData = new FormData();
        formData.append('previouspassword', previouspassword);
        formData.append('newpassword', newpassword);
        formData.append('confirmpassword', confirmpassword);
        if (image) {
            formData.append('image', image);
        } 
        
        const response = await updateUser(formData);
        console.log('UPDATED USER', response)
        if ('statusText' in response && response.statusText === 'OK') {
            dispatch(setUser(response?.data))
            toast.success(response?.data.message, {
                duration: 3000,
                position: 'bottom-center',
                style: {
                    backgroundColor: '#353535',
                    color: '#fff'
                }
            });
        }

        toast.error(response?.data.message, {
            duration: 3000,
            position: 'bottom-center',
            style: {
                backgroundColor: '#353535',
                color: '#fff'
            }
        });
    };

    const handleClick = () => {
        inputRef.current.click();
    };

    // const handleChange = (e: any) => {
    //     const imageFile = e.target.files[0];
    //     setName(imageFile.name);

    //     if (imageFile && FileReader) {
    //         const fr = new FileReader();
    //         fr.onload = () => {
    //             setImage(fr.result)
    //         }
    //         fr.readAsDataURL(imageFile);
    //     }
    // };
    const handleChange = async (e: any) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile) {
            setImage(selectedFile);
            setPhoto(URL.createObjectURL(selectedFile));
        }
    };
    
    const fileName = getFileNameWithoutExtension(photo);
    const formattedFileName = formatFileName(fileName);
    return (
        <section>
            <form className='w-full flex flex-col items-center justify-around py-5' onSubmit={handleSubmit} action='POST'>
                
                <div className='mt-10 flex flex-col md:w-[400px]'>
                    <label className='text-xl font-semibold text-white' htmlFor="previousemail">Previous email</label>
                    <input
                        minLength={5}
                        maxLength={20}
                        placeholder='Previous email'
                        className='w-full p-3 mt-2 bg-main border bg-mnf text-text border-border rounded'
                        type="text"
                        name='previousemail'
                        defaultValue={details?.previousemail}
                    />
                    <label className='text-xl font-semibold text-white' htmlFor="newemail">New email</label>
                    <input
                        minLength={5}
                        maxLength={40}
                        placeholder='New email'
                        className='w-full p-3 mt-2 bg-main bg-mnf text-text border border-border rounded'
                        type="text"
                        name='newemail'
                        defaultValue={details?.newemail}
                    />
                    {/* <label className='text-xl font-semibold mt-5 text-white' htmlFor="confirmpassword">Confirm new password</label>
                    <input
                        minLength={5}
                        maxLength={40}
                        placeholder='Confirm new password'
                        className='w-full p-3 mt-2 bg-main bg-mnf text-text border border-border rounded'
                        type="text"
                        name='confirmpassword'
                        defaultValue={details?.confirmpassword}
                    /> */}
                    {/* <BasicButton type='submit'>Save</BasicButton> */}
                    <div className="flex gap-2 flex-wrap flex-col-reverse sm:flex-row justify-between items-center my-4">
                        <button type='submit' className="bg-main font-medium transitions hover:bg-[#FF4500] border border-[#FF4500] text-white py-3 px-6 rounded w-full sm:w-auto">
                            Change Email
                        </button>
                    </div>
                </div>
            </form>
            <Toaster />
        </section>
    )
}

export default ChangeEmail;