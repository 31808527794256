import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPostLikesbyId, setLike, unLike } from '../services/postService';
import { getAllUser } from '../services/userService';
import { setLikes } from '../redux/features/feedSlice';

const useLikes = (postId: string) => {
    const user = useSelector((state: any) => state.auth.user);
    const [isLiked, setIsLiked] = useState<boolean>(false);
    const [isLikeLoading, setIsLikeLoading] = useState<boolean>(false);
    const [id, setId] = useState<any>();
    const dispatch = useDispatch();

    const likes = useSelector((state: any) => state?.feed?.likes);

    const fetchAllLikes = async () => {
        // setIsFollowLoading(true);
        try {
            const result = await getPostLikesbyId(postId);
            const data = result;
            // setFollowings(data);
            dispatch(setLikes({ likes: data }));
            const likingUser = data.find(
                (likeUser: any) => likeUser?.user_id === user?.id);
            if ( 
                likingUser
            ) {
                setIsLiked(true);
            } else {
                setIsLiked(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            // setIsFollowLoading(false);
        }
    }

    useEffect(() => {
        fetchAllLikes();
    }, [postId]);

    const addLike = async () => {
        setIsLikeLoading(true);
        try {  
            await setLike(postId);
            fetchAllLikes();
            // setIsLiked(true);
        } catch (error) {
            console.log(
                error
            )
        } finally { 
            setIsLikeLoading(false);
        }
    }

    const removeLike = async () => {
        setIsLikeLoading(true);
        try {  
            await unLike(postId);
            fetchAllLikes();
            setIsLiked(false)
        } catch (error) {
            console.log(
                error
            )
        } finally { 
            setIsLikeLoading(false);
        }
    }

    return { 
        isLikeLoading, isLiked, likes, fetchAllLikes,
        addLike, removeLike, setIsLiked
    };
}

export default useLikes;