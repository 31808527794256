import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { setPage } from './feedSlice';
import { useDispatch } from 'react-redux';

type AuthState = {
  user: {
    id: any;
    email: string;
    username: string;
    image: string;
    firstname: string;
    lastname: string;
    role: string;
    age?: number;
    status: string;
  } | null;
  otherUser: {
    id: any;
    email: string;
    username: string;
    image: string;
    firstname: string;
    lastname: string;
    role: string;
    age?: number;
    status: string;
  } | null;
  allUsers: any[];
  isPaymentSuccessful?: boolean;
};

var initialState: AuthState = { 
  user: null, otherUser: null, isPaymentSuccessful: false, allUsers: [] 
};

const token = Cookies.get('access_token');
if (token) {
  var { username, email, id, image, firstname, lastname, role, status }: any = jwtDecode(token);

  initialState = {
    user: {
      id,
      email,
      username,
      image,
      firstname,
      lastname,
      role,
      status,
    },
    otherUser: {
      id,
      email,
      username,
      image,
      firstname,
      lastname,
      role,
      status,
    },
    allUsers: [], // Initialize allUsers as an empty array
    isPaymentSuccessful: false,
  };
}
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setOtherUser: (state, action: PayloadAction<any>) => {
      state.otherUser = action.payload;
    },
    setAllUsers: (state, action: PayloadAction<any[]>) => {
      // Ensure that allUsers is replaced with the new data, not appended
      state.allUsers = action.payload;
    },
    setIsPaymentSuccessful: (state) => {
      state.isPaymentSuccessful = !state.isPaymentSuccessful;
    },
    logOut: (state) => {
      state.user = null;
    },
  },
});

export const { 
  setUser, setOtherUser, logOut, 
  setIsPaymentSuccessful, setAllUsers 
} = authSlice.actions;

export default authSlice.reducer;
