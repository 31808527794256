import React from 'react'
import useSkeletons from '../../../hooks/useSkeletons';

const NavigationSkeleton: React.FC<any> = (props) => {
    const count: any = (props.count) ? parseInt(props.count) : 1;
    const { SkeletonAvatarDiv, SkeletonTextDiv } = useSkeletons();

    return (
        <>
            {
                [...Array(count)].map((item, index) => {
                    return (
                        <div className='mb-5' key={index}>
                            <SkeletonAvatarDiv />
                            <SkeletonTextDiv width='100%' maxWidth='170px' height='13px' />
                        </div>
                    )
                })
            }
        </>
    )
}
export default NavigationSkeleton;
