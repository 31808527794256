import { FC, ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";
import ContentArea from "./ContentArea";
import Sidebar from "./Sidebar";
import RightBar from "./RightBar";
import NavBar from "./NavBar";
import Search from "./Search";
import Upload from "./Upload";
import BottomBar from "./BottomBar";

type Props = {
  children: ReactNode;
};
const Layout: FC<Props> = ({ children }) => {
  const [channel, setChannel] = useState<Channel>();

  const location = useLocation();
  if (
    location.pathname === "/login" ||
    location.pathname === "/register" ||
    location.pathname?.startsWith("/detail")
  ) {
    return <>{children}</>;
  }

  return (
    <div className="min-w-[320px] flex flex-col bg-main left-0 right-0   items-center justify-center">
      <div>
        <NavBar
          search={<Search />}
          upload={<Upload />}
        />
        <div className="flex flex-col w-full justify-center items-center">
          <div className="flex flex-row h-[100vh] max-w-[1600px] w-screen bg-main rounded-md">
            <Sidebar />
            <ContentArea>{children}</ContentArea>
            {location.pathname === "/" && <RightBar />}
          </div>
        </div>
        <BottomBar
          search={<Search />}
          upload={<Upload />}
        />
      </div>
    </div>
  );
};

export default Layout;
