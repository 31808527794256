import axios from 'axios';
import Cookies from 'js-cookie';
import { API_BASE_URL } from './constants';

const token = Cookies.get('access_token');

function getTokenFromAuthorization(authorizationHeader: any) {
  const bearerPrefix = 'Authorization=';
  if (authorizationHeader?.startsWith(bearerPrefix)) {
    return authorizationHeader.slice(bearerPrefix.length).split(';')[0];
  }
  return authorizationHeader;
}

const actualToken = getTokenFromAuthorization(token);

console.log('Access Token:', actualToken);

const axiosFormDataWithAuth = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${actualToken ? actualToken : actualToken}`,
    'Content-Type': 'multipart/form-data',
  }
});

console.log('Authorization Header:', axiosFormDataWithAuth.defaults.headers['Authorization']);

export default axiosFormDataWithAuth;
