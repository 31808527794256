import React from "react";
import MainModal from "./MainModal";
import {
  FaFacebook,
  FaInstagram,
  FaTelegram,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Link } from "react-router-dom";

type Props = {
  modalOpen?: any;
  setModalOpen?: any; 
  caption?: any; 
  username?: any;
}
const SharePostModal: React.FC<Props> = ({modalOpen, setModalOpen, caption, username} ) => {
  const shareData = [
    {
      icon: FaFacebook,
      shareButton: FacebookShareButton,
    },
    {
      icon: FaTwitter,
      shareButton: TwitterShareButton,
    },
    {
      icon: FaInstagram,
      shareLink: `https://www.instagram.com/share?url=${encodeURIComponent(
        `${window.location.protocol}//${window.location.host}/post/${caption}`
      )}`
    },
    {
      icon: FaTelegram,
      shareButton: TelegramShareButton,
    },
    {
      icon: FaWhatsapp,
      shareButton: WhatsappShareButton,
    },
  ];

  const url = `${window.location.protocol}//${window.location.host}/post/${caption}`;
  return (
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="inline-block sm:w-4/5 border border-border md:w-3/5 lg:w-2/5 w-full align-middle p-10 overflow-y-auto h-full bg-boxes text-white rounded-2xl">
        <h2 className="text-2xl">
          Share <span className="text-xl text-text font-bold">"{caption ? caption : username ? username : caption}"</span>
        </h2>
        <form className="flex-rows flex-wrap gap-6 mt-6">
          {shareData.map((data, index) => (
            <div
              key={index}
            >
              {data.shareButton ? (
                <data.shareButton
                  url={url}
                  quote="Tekfall Supreme | Virtuals"
                >
                  <div 
                    className="w-10 transition text-notification flex flex-col justify-center items-center text-lg h-10 bg-opacity-30"
                  >
                    <data.icon className="w-full h-full text-notification" />
                  </div>
                </data.shareButton>
              ) : (
                <Link
                  to={data.shareLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-10 transition text-notification flex flex-col justify-center items-center text-lg h-10 bg-opacity-30"
                >
                  <data.icon className="w-full h-full text-notification" />
                </Link>
              )}
            </div>
          ))}
        </form>
      </div>
    </MainModal>
  );
}

export default SharePostModal;
