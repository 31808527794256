import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CurrentlyOnline from '../../CurrentlyOnline';

const RightBar = () => {

    const menuToggle = useSelector((state: any) => state.feed.menuToggle);
    

    return (
        <>
        {/* sm:block */}
        {menuToggle && <aside id="autoflow"
            className={`bg-main max-w-[335px] lg:block hidden border-lines lg:col-span-2 xl:col-span-2 min-h-fit overflow-y-auto max-h-[100vh]
            xl:w-400 lg:w-400 md:w-30 sm:w-20 w-20 flex flex-col justify-end xl:border-0 px-3`}>
            <CurrentlyOnline/>
        </aside>
        }</>
    )
}

export default RightBar;