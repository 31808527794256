import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast, Toaster } from 'react-hot-toast';
import { AiFillLock } from 'react-icons/ai';
import { MdMail } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HiUser, HiKey  } from 'react-icons/hi';

import BasicButton from '../../../components/buttons/BasicButton';
import LoginBasicButton from '../../../components/buttons/LoginBasicButton';
import PasswordInput from '../../../components/inputs/PasswordInput';
import TextInput from '../../../components/inputs/TextInput';
import { setUser } from '../../../redux/features/authSlice';
import { logIn } from '../../../services/authService';
import SocialLoginFactory from '../../../components/SocialLoginFactory';

type Props = {
  setIsFormOpen: Dispatch<SetStateAction<boolean>>;
}

const LoginForm: FC<Props> = ({ setIsFormOpen }) => {
  const [page, setPage] = useState<any>(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm();



  const onSubmit = async (data: any) => {
    try {
      const response = await logIn({ email: data.email, username: data.username, password: data.password });
      if ('status' in response && response.status === 200) {
        console.log('LOG USER', response)
        const token = response?.data?.data?.token;
        const user = response?.data?.data?.authenticated_user;
        toast.success(`${response?.data?.data?.message} success`, {
          duration: 5000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
        });
        // const decodedToken: unknown = jwtDecode(token);
        // Store the token and user data as needed
        Cookies.set('access_token', token as any, { expires: 3 });
        Cookies.set('last_user', user?.user_id);
        Cookies.set('user_email', user?.email);
  
        dispatch(setUser({
          username: user.username,
          email: user.email,
          id: user?.user_id,
          image: user?.avatar,
          firstname: user?.fname,
          lastname: user?.lname,
          role: user?.role,
          status: user?.status
        }));
        
        setIsFormOpen(false);
        setTimeout(() => {
          return navigate('/');
        }, 2000);
      } else if ('statusCode' in response && response.statusCode === '400') {
        // reset();
        toast.error(response.message, {
          duration: 3000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
        });
      } else if ('statusCode' in response && response.statusCode === '401') {
        toast.error(`${response.message}`, {
          duration: 5000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
        });
      }
    } catch (error) {
      console.log('Error:', error);
      reset();
      toast.error('An error occurred. Please try again later.', {
        duration: 3000,
        position: 'top-center',
        style: {
          backgroundColor: '@apply bg-boxes',
          color: '@apply text-text'
        }
      });
    }
  };
  
  

  return (
    <form
      method='POST'
      name='form'
      className='flex flex-col justify-center'
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* { page === 2 ?  */}
      <>
        <TextInput
          label='Email'
          placeholder='Type your email or username.'
          Icon={MdMail}
          informations={['email or username is required.', 'email must be valid.']}
          error={errors.email && errors.email.message}
          refs={{
            ...register('email', {
              required: 'email or username is required.',
              // pattern: {
              //   value:
              //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              //   message: 'please enter a valid email.'
              // }
            })
          }}
        />
        <PasswordInput
          label='Password'
          placeholder='Type your password.'
          error={errors.password && errors.password.message}
          Icon={AiFillLock}
          refs={{
            ...register('password', {
              required: 'password is required',
            })
          }}
        />
        <div className='w-[90%] md:w-[80%] mx-auto'>
          <BasicButton
            type='submit'
            disabled={!isValid}
          >
            Login
          </BasicButton>
        </div>
      </>
      {/* : 
        <div className='w-[100%] pt-3 pb-3 md:w-[100%] mx-auto flex flex-col justify-center items-center'>
        <LoginBasicButton
          type='button'
          onClick={() => setPage(2)}
        >
          <HiUser />Use email/username
        </LoginBasicButton> */}
        {/* <SocialLoginFactory setIsFormOpen={setIsFormOpen} /> */}
        {/* </div>
      } */}
      <Toaster />
    </form>
  )
}

export default LoginForm