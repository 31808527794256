import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { postComments, deleteComments, getAllComments } from '../services/postService';


const useComments = (comment: string, incomingPostId: string) => {
    const user = useSelector((state: any) => state.auth.user);
    const [allComments, setAllComments] = useState<any>();
    const [isCommentLoading, setIsCommentLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchAllComments = async () => {
            try {
                const result = await getAllComments(incomingPostId);
                const comments = result;
                if ( comments ) {
                    setAllComments(comments);
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchAllComments();
    }, [incomingPostId]);

    const addComments = async () => {
        setIsCommentLoading(true);
        try {  
            return await postComments(comment, incomingPostId);
        } catch (error) {
            console.log(
                error
            )
        } finally { 
            setIsCommentLoading(false);
        }
    }

    const removeComments = async () => {
        setIsCommentLoading(true);
        try {  
            return await deleteComments(incomingPostId);
        } catch (error) {
            console.log(
                error
            )
        } finally { 
            setIsCommentLoading(false);
        }
    }

    
    console.log("PID:", incomingPostId, allComments);
    return { isCommentLoading, allComments, addComments, removeComments };
}

export default useComments;