import { useLocation, useNavigate } from 'react-router-dom';
import { AiFillHome } from "react-icons/ai";
import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { FaHeart, FaUsers } from 'react-icons/fa';
import NumberFormatter from '../../NumberFormater'
import useAllUsers from '../../../hooks/useAllUsers';
import NavigationSkeleton from '../../loading/skeletons/NavigationSkeleton'
import { useSelector } from 'react-redux';
import useFollowerStatus from '../../../hooks/useFollowerStatus';

type Props = {
  setSearch: Dispatch<SetStateAction<string>>;
}


const Searchbar: FC<Props> = ({ setSearch }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading } = useAllUsers();

  const user = useSelector(
    (state: any) => state?.auth?.user
  );

  const { 
    follower, following, fetchAllFollowings,
    fetchAllFollowers, isFollower, isFollowing 
  } = useFollowerStatus(user?.id);

  useEffect(() => {
    fetchAllFollowings();
    fetchAllFollowers();
  }, []);

  // console.log('USERS', following);
  const hover = "hover:text-green transitions text-white";
  const Hover = ({ isActive }: any) => (isActive ? "text-green" : hover);

  const activeLink =
    `${Hover} cursor-pointer flex items-center hover:bg-primary p-3 justify-center xl:justify-start cursor-pointer font-semibold text-text rounded`;

  const normalLink =
  `${Hover} cursor-pointer flex items-center hover:bg-primary p-3 justify-center xl:justify-start cursor-pointer font-semibold text-white rounded`;

  return (
    <div className='flex gap-2 flex-col p-3 w-full border-b border-lines'> 
    {isLoading ? <NavigationSkeleton /> :
      <div onClick={ () => navigate('/')} className="flex cursor-pointer items-center space-x-1">
        <div className={location.pathname === "/" ? activeLink : normalLink}>
          
          <AiFillHome size={24}  />
        </div>
        <span className={location.pathname === "/" ? activeLink : normalLink}>Feed</span>
      </div>}
      {isLoading ? <NavigationSkeleton /> :
      <div onClick={ () => navigate(`/profile/${user?.id}`)} className="flex relative cursor-pointer items-center space-x-1">
        <div className={location.pathname === "/profile" ? activeLink : normalLink}>
          <FaUsers size={24} />
        </div>
        <div className="w-5 h-5 font-medium bottom-8 left-5 rounded-full text-xs text-notification absolute">
          <NumberFormatter number={follower?.length}/>
        </div>
        <span className={location.pathname === "/profile" ? activeLink : normalLink}>Followers</span>
      </div>}
      {isLoading ? <NavigationSkeleton /> :
      <div onClick={ () => navigate(`/profile/${user?.id}`)} className="flex relative cursor-pointer items-center space-x-1">
        <div className={location.pathname === "/profile" ? activeLink : normalLink}>
          <FaHeart size={24} />
        </div>
        <div className="w-5 h-5 bottom-8 left-5 font-medium rounded-full text-xs text-notification absolute">
          <NumberFormatter number={following?.length}/>
        </div>
        <span className={location.pathname === "/profile" ? activeLink : normalLink}>Following</span>
      </div>}
    </div>
  )
}

export default Searchbar;