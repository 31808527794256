import axios from "axios";
import { API_BASE_URL } from '../utils/constants';

export const createAccount = async ({ email, username, password }: signFormType) => {
    try {
        return await axios.post(`${API_BASE_URL}/auth/signup`, {
            email,
            username,
            password
        });
    } catch (error) {
        console.log('CREATE USER ERROR', error)
        return { statusCode: '409', message: (error as any)?.response?.data?.message };
    }
}

export const verifyAccount = async ({ email, otp }: verifyOTPFormType) => {
  try {
      return await axios.put(`${API_BASE_URL}/auth/verify/account`, {
          email,
          otp
      });
  } catch (error) {
      console.log('VERIFY OTP ERROR', error)
      return { statusCode: '409', message: (error as any)?.response?.data?.message };
  }
}

export const resendOTP = async ({ email }: resendOTPFormType) => {
  console.log('auth email', email)
  try {
      return await axios.post(`${API_BASE_URL}/auth/resend/otp/email`, {
          email,
      });
  } catch (error) {
      console.log('VERIFY OTP ERROR', error)
      return { statusCode: '409', message: (error as any)?.response?.data?.message };
  }
}

export const logIn = async ({ email, username, password }: signFormType) => {
    try {
      return await axios.post(`${API_BASE_URL}/auth/login`, {
        email,
        username,
        password
      });
  
    } catch (error) {
      console.log('LOGIN USER ERROR', error)
      return { statusCode: '401', message: (error as any)?.response?.data?.message };
    }
  };

  export const resetPassword = async ({ email, password, otp }: resetPasswordFormType) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/auth/reset/password`, {
          email,
          password,
          otp
        });
        return response.data;;
    } catch (error) {
        console.log('CREATE USER ERROR', error)
        return { statusCode: '409', message: (error as any)?.response?.data?.message };
    }
  }
  
  export const facebookAuth = async ({ access_token }: any) => {
    // console.log('FACEBOOK TOKEN', token)
    try {
        return await axios.post(`${API_BASE_URL}/auth/facebook`, {
          access_token
        });
    } catch (error) {
        console.log('CREATE USER ERROR', error)
        return { statusCode: '409', message: (error as any)?.response?.data?.message };
    }
  }

  export const tiktokAuth = async ({ token }: any) => {
    console.log('FACEBOOK TOKEN', token)
    try {
        return await axios.post(`${API_BASE_URL}/auth/tiktok`, {
          token
        });
    } catch (error) {
        console.log('CREATE USER ERROR', error)
        return { statusCode: '409', message: (error as any)?.response?.data?.message };
    }
  }

  export const googleAuth = async ({ id_token }: any) => {
    console.log('GOOGLE TOKEN', id_token)
    try {
        return await axios.post(`${API_BASE_URL}/auth/google`, {
          id_token
        });
    } catch (error) {
        console.log('CREATE USER ERROR', error)
        return { statusCode: '409', message: (error as any)?.response?.data?.message };
    }
  }