import React from 'react'
import useSkeletons from '../../../hooks/useSkeletons';

const ButtonSkeleton: React.FC<any> = (props) => {
    const count: any = (props.count) ? parseInt(props.count) : 1;
    const { SkeletonTextDiv } = useSkeletons();

    return (
        <>
            {
                [...Array(count)].map((item, index) => {
                    return (
                        <>
                            <SkeletonTextDiv width='100%' maxWidth='100px' height='33px' radius="5px" />
                        </>
                    )
                })
            }
        </>
    )
}
export default ButtonSkeleton;
