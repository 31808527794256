import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { getFollowers } from "../../../../services/userService";
import { useSelector } from "react-redux";
import NoResults from "../../../../components/NoResults";
import useFollowerStatus from "../../../../hooks/useFollowerStatus";
import useBlockStatus from "../../../../hooks/useBlockStatus";
import toast from "react-hot-toast";
import FollowerBox from "./FollowersBox";

const FollowersTab = ({ incomingId }: any) => {
  const users = useSelector(
    (state: any) => state.auth.allUsers
  );

  const location = useLocation();

  const { 
    isFollowing, isFollowerById, isFollowingById,
     followerById, isFollower, isFollowLoading, addFollower, 
     removeFollower 
  } = useFollowerStatus(incomingId);

  const { 
    isBlocked, isBlockLoading, addBlock, removeBlock 
  } = useBlockStatus(incomingId);

  const handleFollow = () => {
    try {
        addFollower()
        setTimeout(() => {
            toast.success(`User followed.`, {
                duration: 3000,
                position: 'top-center',
                style: {
                    backgroundColor: '@apply bg-boxes',
                    color: '@apply text-text'
                }
            });
        }, 1000);
    }catch(error: any) {
        // Handle API request errors here
        console.error('API request error:', error);
        // You can also display an error toast or take other actions as needed.
    };
  };
  

  const handleUnFollow = () => {
      try {
          removeFollower();
          setTimeout(() => {
              toast.success(`User unfollowed.`, {
                  duration: 3000,
                  position: 'top-center',
                  style: {
                      backgroundColor: '@apply bg-boxes',
                      color: '@apply text-text'
                  }
              });
          }, 1000);
      }catch(error: any) {
          // Handle API request errors here
          console.error('API request error:', error);
          // You can also display an error toast or take other actions as needed.
      };
    };

    const handleBlock = () => {
      try {
          addBlock()
          setTimeout(() => {
              toast.success(`User blocked.`, {
                  duration: 3000,
                  position: 'top-center',
                  style: {
                      backgroundColor: '@apply bg-boxes',
                      color: '@apply text-text'
                  }
              });
          }, 1000);
      }catch(error: any) {
          // Handle API request errors here
          console.error('API request error:', error);
          // You can also display an error toast or take other actions as needed.
      };
    };
    

  const handleUnBlock = () => {
      try {
          removeBlock();
          setTimeout(() => {
              toast.success(`User unblocked.`, {
                  duration: 3000,
                  position: 'top-center',
                  style: {
                      backgroundColor: '@apply bg-boxes',
                      color: '@apply text-text'
                  }
              });
          }, 1000);
      }catch(error: any) {
        // Handle API request errors here
        console.error('API request error:', error);
        // You can also display an error toast or take other actions as needed.
    };
  };


  return (
    <div>
      {
        (followerById && followerById.length > 0)
        ?
        followerById.map((followers: any) => {
          return (
            <FollowerBox 
              key={followers?.user_id} 
              incomingId={incomingId} 
              followers={followers} 
              isFollowing={isFollowing}
              isFollowingById={isFollowingById}
              isFollowerById={isFollowerById}
              isFollowLoading={isFollowLoading}
              isBlockLoading={isBlockLoading}
              isFollower={isFollower}
              isBlocked={isBlocked}
              handleBlock={handleBlock}
              handleAdd={handleFollow}
              handleUnBlock={handleUnBlock}
              handleRemove={handleUnFollow}
            />
          )
        })
      : <NoResults text={`No Followers`} />}
    </div>
  )
}

export default FollowersTab;