import React from 'react';

interface SkeletonProps {
  width?: string;
  maxWidth?: string;
  height?: string;
  maxHeight?: string;
  radius?: string;
}

function useSkeleton() {
  const SkeletonDiv: React.FC<SkeletonProps> = (
    { width, maxWidth, height, maxHeight, radius }) => (
    <div
      className="skeleton"
      style={{ width, maxWidth, height, maxHeight, borderRadius: radius}}
    ></div>
  );

  const SkeletonAvatarDiv: React.FC<SkeletonProps> = (
    { width, maxWidth, height, maxHeight }) => (
    <div
      className="skeleton rounded-full h-10 w-10"
      style={{ width, maxWidth, height, maxHeight }}
    ></div>
  );

  const SkeletonTextDiv: React.FC<SkeletonProps> = (
    { width, maxWidth, height, maxHeight, radius }) => (
    <div
      className="skeleton skeleton-text"
      style={{ width, maxWidth, height, maxHeight, borderRadius: radius }}
    ></div>
  );

  const SkeletonSmallBoxDiv: React.FC<SkeletonProps> = (
    { width, maxWidth, height, maxHeight }) => (
    <div
      className="skeleton skeleton-small-box"
      style={{ width, maxWidth, height, maxHeight }}
    ></div>
  );

  const SkeletonSmallTextDiv: React.FC<SkeletonProps> = (
    { width, maxWidth, height, maxHeight }) => (
    <div
      className="skeleton skeleton-small-text"
      style={{ width, maxWidth, height, maxHeight }}
    ></div>
  );

  const SkeletonBigBDiv: React.FC<SkeletonProps> = (
    { width, maxWidth, height, maxHeight }) => (
    <div
      className="skeleton skeleton-big-box"
      style={{ width, maxWidth, height, maxHeight }}
    ></div>
  );

  return {
    SkeletonDiv,
    SkeletonTextDiv,
    SkeletonAvatarDiv,
    SkeletonSmallBoxDiv,
    SkeletonSmallTextDiv,
    SkeletonBigBDiv,
  };
}

export default useSkeleton;