import Cookies from "js-cookie";
import { useContext, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import FormSuccess from "../../components/loading/FormSuccess";
import Divider from "../Register/components/Divider";
import LoginForm from "./components/LoginForm";
import Relogin from "./components/Relogin";
import { ThemeModeContext } from "../../services/themeService";
import ResetPassword from "./components/ResetPassword";
import { FaArrowLeft } from "react-icons/fa";
// import ThemeSwitchButton from "../../components/buttons/ThemeSwitchButton";

enum LoginResetPasswordPage {
  Page1,
  Page2
}

const Login = () => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(true);
  const [lastUser, setLastUser] = useState<string>(Cookies.get('last_user') || '');
  const [lastUserEmail, setLastUserEmail] = useState<string>(Cookies.get('user_email') || '');
  const [currentPage, setCurrentPage] = useState<LoginResetPasswordPage>(
    LoginResetPasswordPage.Page1
  );
  const { themeMode, handleThemeToggle } = useContext(ThemeModeContext);
  const blackLogo = 'https://tekfall-home.s3.amazonaws.com/digiyo_word__1_-removebg-preview.png';
  const whiteLogo = 'https://tekfall-home.s3.amazonaws.com/image.png';
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  }

  console.log('LASTUSER', lastUser)
  const renderPage = () => {
    switch (currentPage) {
      case LoginResetPasswordPage.Page1:
        return (
        <div className="flex flex-col justify-center min-h-screen items-start h-auto">
          <div className="bg-boxes px-3 py-10 shadow-lg rounded-md text-white min-h-[500px] w-full sm:w-[400px] h-full sm:h-auto">
            {
              isFormOpen
                ?
                <>
                  {
                    lastUser
                      ?
                      <Relogin id={lastUser} email={lastUserEmail} setIsFormOpen={setIsFormOpen} setLastId={setLastUser} />
                      :
                      <div>
                        <LazyLoadImage
                          className='w-[50%] mx-auto hidden sm:block'
                          src={themeMode === 'dark' ? whiteLogo : blackLogo }
                          alt='logo'
                          effect='blur'
                        />
                        <h1 className='text-3xl font-semibold text-center sm:hidden mb-10'>Login</h1>
                        <LoginForm setIsFormOpen={setIsFormOpen} />
                      </div>
                  }
                  <Divider />
                  <div className="text-center w-[90%] md:w-[80%] mx-auto">
                    <Link className='text-text duration-200 w-full text-center mx-auto text-sm' to='/register'>Don't have an account? <span className='text-notification hover:text-green'>Register</span></Link>
                  </div>
                  <div className="text-center w-[90%] md:w-[80%] mx-auto">
                    <p onClick={handleNextPage} className='text-notification cursor-pointer duration-200 w-full hover:text-green text-center mx-auto text-sm' >Reset Password</p>
                  </div>
                  {/* <ResetPassword /> */}
                </>
                :
                <FormSuccess message="Logged In" redirectTo="feed" />
            }
          </div>
        </div>)
      case LoginResetPasswordPage.Page2:
        return(<ResetPassword  handlePage={handlePrevPage}/>)
      default:
        return null;
    }
  };
  return (
    <>
      {renderPage()}  
    </>
  )
}

export default Login;