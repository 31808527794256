import React, { useEffect } from "react";
import { FC, useState, useTransition } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import Spinner from '../../components/loading/Spinner'
import { getAllPosts } from "../../services/postService";
import PostsTab from "./components/Posts/PostsTab";
import { getAllUser } from "../../services/userService";
import AccountTab from "./components/Accounts/AccountTab";

type Props = {
    searchId? : any,
}

const SearchPage: FC<Props> = ({ searchId }) => {
    const user = useSelector((state: any) => state.auth.user);
    const [tab, setTab] = useState('top');
    const [isPending, startTransition] = useTransition();
    const [page, setPage] = useState(1); // Current page number
    const [relatedPosts, setRelatedPosts] = useState<any>([]);
    const [users, setUsers] = useState<any[]>([]);

    const location = useLocation();

    const [loggedUser, setLoggedUser] = useState<any>();

    
    const searchQuery = new URLSearchParams(location.search).get('q');

    const fetchAlls = async () => {
        try {
          const result = await getAllPosts(page);
          console.log(result)
          const allPosts = result?.data?.data?.reverse();
          const filteredPosts = allPosts?.filter(
            (posts: any) => posts?.caption?.slice(0, 10) === searchQuery?.slice(0, 10) 
        );

            const usersResult = await getAllUser();
            if ('data' in usersResult) {
                const allUsers = usersResult?.data?.data; // Directly use the result array
                const shuffled = [...allUsers].sort(() => 0.5 - Math.random());
                setUsers(shuffled);
            } 
          setRelatedPosts(filteredPosts);
        } catch (error) {
          console.log(error);
        }
      };
    
      useEffect(() => {
        fetchAlls();
      }, []);
  
    // Get the search query from the location.search property

    const handleClickTop = () => {
        startTransition(() => {
            setTab('top');
        });
    };

    const handleClickAccounts = () => {
        startTransition(() => {
            setTab('accounts');
        });
    };

    const handleClickPosts = () => {
        startTransition(() => {
            setTab('posts');
            console.log('RELATED POSTS', relatedPosts)
        });
    };

    
    const getUserIdsFromPosts = (posts: any[]) => {
        const userIds = posts.map(post => post?.author_id);
        return userIds;
    };

    const filterUsersByRelatedPosts = (allUsers: any, userIds: any) => {
        const filteredUsers = allUsers.filter((user: any) => userIds.includes(user?.user_id));
        console.log('FILTERED USERS', filteredUsers)
        return filteredUsers;
    };

    const userIDs = getUserIdsFromPosts(relatedPosts);
    
    console.log('FILTERED USERS, and IDs', userIDs, users)
    const filteredUsers = filterUsersByRelatedPosts(users, userIDs);

    console.log('FILTERED USERS', filteredUsers)

    return (
        <div className="max-w-[800px] mx-auto pb-10">
            <div className="flex justify-around">
                <span
                    onClick={handleClickTop}
                    className={`
                        text-xl py-3 cursor-pointer w-full text-text text-center duration-200 transition-all border-b 
                        ${tab === 'top' ? 'border-b-2 text-white font-semibold' : 'hover:border-white border-neutral-700'}
                    `}
                >
                    Top
                </span>
                {/* {
                    user?.id === searchId
                    && */}
                    <>
                        <span
                            onClick={handleClickAccounts}
                            className={`
                                text-xl py-3 cursor-pointer w-full text-text text-center duration-200 transition-all border-b 
                                ${tab === 'accounts' ? 'border-b-2 text-white font-semibold' : 'hover:border-white border-neutral-700'}
                            `}
                        >
                            Accounts
                        </span>
                        <span
                            onClick={handleClickPosts}
                            className={`
                                text-xl py-3 cursor-pointer w-full text-text text-center duration-200 transition-all border-b 
                                ${tab === 'posts' ? 'border-b-2 text-white font-semibold' : 'hover:border-white border-neutral-700'}
                            `}
                        >
                            Posts
                        </span>
                    </>
                {/* } */}
            </div>
            <div>
                {
                    isPending
                        ?
                        <div className="mt-10">
                            <Spinner size="sm" />
                        </div>
                        :
                        <>
                            {
                                tab === 'top' && <PostsTab relatedPosts={relatedPosts} />
                            }
                            {
                                tab === 'accounts' && <AccountTab relatedUsers={filteredUsers} />
                            }
                            {
                                tab === 'posts' && <PostsTab relatedPosts={relatedPosts} />
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default SearchPage