// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { addPosts, setAnyLoading } from "../redux/features/feedSlice";
// import { createPost, getAllPosts, getPostDetails } from '../services/postService';
// import { AxiosResponse } from 'axios';

// type IProps = {
//     userId?: any;
// }

// type UseAllUsersReturnType = {
//     isLoading: boolean | undefined;
//     posts: any;
//     addPost: (formData: FormData) => Promise<any>;
//     setIsLoading: any;
//     fetchPostById: () => Promise<any>;
//     fetchAllPosts: () => Promise<any>;
// };

// const useAllPosts = (userId?: any): UseAllUsersReturnType  => {
//     const [isLoading, setIsLoading] = useState<boolean>()
//     const dispatch = useDispatch();
//     let posts = useSelector((state: any) => state.feed.posts);
    
//     const fetchAllPosts = async () => {
//         setIsLoading(true);
//         try {
//           const result = await getAllPosts();
//           const allPosts = result?.data?.reverse();
//           dispatch(addPosts(allPosts));
//         } catch (error) {
//           console.log(error);
//         } finally {
//           setIsLoading(false);
//         }
//     };  

//     useEffect(() => {
//         fetchAllPosts();
//     }, []);

//     const fetchPostById = async () => {
//         setIsLoading(true);
//         try {  
//             return await getPostDetails(userId);
//         } catch (error) {
//             console.log(
//                 error
//             )
//         } finally { 
//           setIsLoading(false);
//         }
//     }

//     const addPost = async (formData: FormData) => {
//         setIsLoading(true);
//         dispatch(setAnyLoading());
//         console.log('FORMDATA', formData)
//         try {  
//             return await createPost(formData);
//         } catch (error) {
//             console.log(
//                 error
//             )
//         } finally { 
//           setIsLoading(false);
//           dispatch(setAnyLoading());
//         }
//     }

//     return { 
//         isLoading, posts, setIsLoading, 
//         addPost, fetchPostById, fetchAllPosts 
//     };
// }

// export default useAllPosts;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPosts, setAnyLoading, setPage } from '../redux/features/feedSlice';
import { createPost, getAllPosts, getPostDetails } from '../services/postService';
import { AxiosResponse, all } from 'axios';

type IProps = {
  userId?: any;
};

type UseAllUsersReturnType = {
  isLoading: boolean | undefined;
  posts: any;
  addPost: (formData: FormData) => Promise<any>;
  setIsLoading: any;
  fetchPostById: () => Promise<any>;
  fetchAllPosts: (page: number) => Promise<any>;
  loadPosts?: any;
  hasMore: boolean;
  isLoadingMore?: boolean;
  page?: any;
  loadMore: () => void;
};

const useAllPosts = (userId?: any): UseAllUsersReturnType => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const dispatch = useDispatch();
  let posts = useSelector((state: any) => state.feed.posts);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(true);
  // const page = useSelector((state: any) => state.feed.page);
  const [page, setPage] = useState(1);

  const fetchAllPosts = async (pageNumber: any) => {
    if (pageNumber > 1 && !hasMore) {
      setIsLoadingMore(false); // No more posts to load
      return;
    }
  
    setIsLoadingMore(true);
    setIsLoading(true);
  
    try {
      console.log('PAGE ON', pageNumber)
      const result = await getAllPosts(pageNumber);
      const allPosts = result?.data?.data?.reverse();
      console.log('All posts', allPosts)
      if (allPosts?.length === 0) {
        setHasMore(false); // No more posts available
      } else {
        if (page === 1) {
          // If it's the first page, replace the existing posts
          dispatch(addPosts(allPosts));
        } else {
          // If it's not the first page, append the new posts to the existing ones
          dispatch(addPosts([...posts, ...allPosts]));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const loadPosts = () => {
    if (!isLoading && hasMore) {
      setPage(page+1);
      fetchAllPosts(page);
    }
  }
  const loadMore = () => {
    loadPosts();
  };
  
  useEffect(() => {
    fetchAllPosts(page);
  }, [userId]);

  const fetchPostById = async () => {
    setIsLoading(true);
    try {
      return await getPostDetails(userId);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addPost = async (formData: FormData) => {
    setIsLoading(true);
    dispatch(setAnyLoading());
    console.log('FORMDATA', formData);
    try {
      await fetchAllPosts(page)
      return await createPost(formData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      dispatch(setAnyLoading());
    }
  };

  // No initial fetch here, it's handled when loadMore is called

  return {
    isLoading,
    posts,
    setIsLoading,
    isLoadingMore,
    page,
    addPost,
    fetchPostById,
    fetchAllPosts,
    loadPosts,
    hasMore,
    loadMore,
  };
};

export default useAllPosts;
