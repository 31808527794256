import React from 'react';
import { useSelector } from "react-redux";
import { RxDotsVertical } from "react-icons/rx";
import { FaUserFriends } from "react-icons/fa";
import { IoFootball } from 'react-icons/io5';
import { IoVideocamOutline } from 'react-icons/io5';
import { FiLogOut } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { IoPersonAddSharp } from "react-icons/io5";
import { MdAddToPhotos } from "react-icons/md";
import { IoEarthOutline } from 'react-icons/io5';
import { FaCloudUploadAlt } from "react-icons/fa";
import { logOut } from "../../../redux/features/authSlice";
import Cookies from "js-cookie";
import ThemeSwitchButton from "../../buttons/ThemeSwitchButton";
import { setPage } from '../../../redux/features/feedSlice';
import { ImCancelCircle } from 'react-icons/im';
import { AiOutlineMenu } from 'react-icons/ai';

const MenuBox: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.user);
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [lastUser, setLastUser] = useState<string>(
    Cookies.get("last_user") || ""
  );

  return (
    <div className="p-3 flex items-center h-22">
      <div className="ml-0 cursor-pointer group">
        <RxDotsVertical onClick={() => setOpenMenu(!openMenu)} className="text-2xl hidden lg:block xl:block text-text" />
        <div
          className="lg:hidden xl:hidden block text-text cursor-pointer m-2 ml-4 text-xl"
        >
          {openMenu ? 
            <ImCancelCircle className="text-text" onClick={() => setOpenMenu(!openMenu)} /> 
          : 
            <AiOutlineMenu className="text-text" onClick={() => setOpenMenu(!openMenu)} />
          }
        </div>
        {openMenu&&(<div className="absolute top-14 text-text text-left w-[200px] lg:max-w-[220px] md:w-auto bg-main border border-main shadow-md rounded-md z-50 right-0 ">
          <a href='https://tekfallsupreme.com'
            className="w-full hover:bg-green hover:text-black xl:text-lg lg:text-lg md:text-md sm:text-xs duration-200 py-3 px-4 flex items-center"
            target='_blank'
          >
            <IoEarthOutline className="mr-3" />
            TekFall Supreme
          </a>
          <a href='https://batstoi.com'
            className="w-full hover:bg-green hover:text-black xl:text-lg lg:text-lg md:text-md sm:text-xs duration-200 py-3 px-4 flex items-center"
            target='_blank'
          >
            <IoEarthOutline className="mr-3" />
            BATS-TOI
          </a>
          <button
            className="w-full hover:bg-green hover:text-black xl:text-lg lg:text-lg md:text-md sm:text-xs duration-200 py-3 px-4 flex items-center"
            onClick={() => navigate("/create")}
          >
            <IoFootball className="mr-3" />
            Challenge Match
          </button>
          <button
            className="w-full hover:bg-green hover:text-black xl:text-lg lg:text-lg md:text-md sm:text-xs duration-200 py-3 px-4 flex items-center"
            onClick={() => navigate("/shop")}
          >
            <IoVideocamOutline className="mr-3" />
            Book a Private
          </button>
          <button
            className="w-full hover:bg-green hover:text-black xl:text-lg lg:text-lg md:text-md sm:text-xs duration-200 p-3 px-4 flex items-center"
            onClick={() => navigate(`/profile/${user?.id}`)}
          >
            <IoPersonAddSharp className="mr-3" />
            Profile
          </button>
          <button
            className="w-full hover:bg-green hover:text-black xl:text-lg lg:text-lg md:text-md sm:text-xs duration-200 p-3 px-4 flex items-center"
            onClick={() => {dispatch(logOut()); dispatch(setPage(1))}}
          >
            <FiLogOut className="mr-3" />
            {/* <AiOutlineLogout className='mr-3' color="red" fontSize={21} /> */}
            Logout
          </button>
          
          <div className='w-full hover:bg-green hover:text-black xl:text-lg lg:text-lg md:text-md sm:text-xs duration-200 py-3 px-4 flex items-center'>
            <ThemeSwitchButton />
          </div>
        </div>)}
      </div>
    </div>
  );
};

export default MenuBox;
