import React from 'react';
import { FaHeart } from 'react-icons/fa';
import NumberFormatter from '../NumberFormater';

interface IProps {
  color?: any;
  likes?: any;
  flex?: string;
  handleLike?: VoidFunction;
}

const LikeButton: React.FC<IProps> = ({ likes, color, handleLike }) => {

  return (
    <div className='flex flex-row relative w-[110px] cursor-pointer justify-between items-center space-x-1 mr-5 bg-boxes border border-1 border-transparent rounded-full p-3'>
      <button className='' onClick={handleLike} >
        <FaHeart className={`text-${color ? color : 'white'} text-2xl`} />
      </button>
      <div className="font-medium rounded-full text-md text-notification">
        <NumberFormatter number={likes || 0}/>
      </div>
    </div>
  );
};

export default LikeButton;