import React, { useEffect, useState } from 'react';
import MediaBox from './MediaBox';
import useAllPosts from '../../../../hooks/useAllPosts';
import useLikes from '../../../../hooks/useLikes';

const MediaTab: React.FC<any> = ({incomingId}: any) => {
  const [allUserPosts, setAllUserPosts] = useState<any>([]);
  const { posts } = useAllPosts();

  const fetchPosts = async () => {
    console.log('INCOMING ID', incomingId)
    try {
      
      const user_posts = posts?.filter((p: any) => {
        return p.author_id == incomingId;
      });
      console.log('ALL USER POSTS', user_posts)

      setAllUserPosts(user_posts);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  console.log('ALL USER POSTS', allUserPosts)
  return (
    <div className='flex flex-row justify-center items-center flex-wrap '>
      {
        (allUserPosts && allUserPosts?.length > 0)
        &&
        allUserPosts?.map((post: any) => {
          return <MediaBox fileAssets={post} incomingId={incomingId} />
        })
      }
    </div>
  )
}

export default MediaTab