import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageInfo from "../../components/layout/ContentArea/PageInfo";
import { getUserById } from "../../services/userService";
import Info from "./components/Info";
import Tabs from "./components/Tabs";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useAllUsers from "../../hooks/useAllUsers";
import { setOtherUser } from "../../redux/features/authSlice";

const Profile = () => {
  
    const location = useLocation();
    const pathname = location.pathname; 
    const pathSegments = pathname.split('/'); 
    const userId = pathSegments[pathSegments.length - 1];
    const [id, setId] = useState<any>()
    const user = useSelector((state: any) => state.auth.user);
    const [loggedUser, setLoggedUser] = useState<any>();
    const [otherUser, setOtherUser] = useState<any>();
    const { fetchUserById } = useAllUsers(userId);

    const dispatch = useDispatch();

    useEffect(() => {
      const fetchUser = async () => {
        setLoggedUser( await user);
        setId(userId);
      };
      fetchUser();
    }, [userId, user?.id]);

    useEffect(() => {
      const fetchAllUsers = async () => {
          try {
              const result = await fetchUserById();
              console.error('Result:', result);
              if ('data' in result) {
                  const user = result?.data;
                  setOtherUser((user));
              } else {
                  console.error('API error:', result.message);
              }
          } catch (error) {
              console.error(error);
          }
      }
      fetchAllUsers();
  }, [dispatch]);

      console.log('KILODE OOOO', otherUser?.user_id)
    return (
      <section>
        <Info otherUser={otherUser} />
        <Tabs otherUser={otherUser} loggedUser={loggedUser} />
      </section>
    )
}

export default Profile;