import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { getFollowers } from "../../../../services/userService";
import { useSelector } from "react-redux";
import NoResults from "../../../../components/NoResults";
import useFollowerStatus from "../../../../hooks/useFollowerStatus";
import useBlockStatus from "../../../../hooks/useBlockStatus";
import toast from "react-hot-toast";
import BlockedBox from "./BlockedBox";

const BlockedTab = ({ incomingId }: any) => {
  const users = useSelector(
    (state: any) => state.auth.allUsers
  );

  const { 
    isBlocked, isBlockLoading, blockedUsers,
    addBlock, removeBlock 
  } = useBlockStatus(incomingId);

  const handleBlock = () => {
    try {
        addBlock()
        setTimeout(() => {
            toast.success(`User blocked.`, {
                duration: 3000,
                position: 'top-center',
                style: {
                    backgroundColor: '@apply bg-boxes',
                    color: '@apply text-text'
                }
            });
        }, 1000);
      }catch(error: any) {
          // Handle API request errors here
          console.error('API request error:', error);
          // You can also display an error toast or take other actions as needed.
      };
    };
    

  const handleUnBlock = () => {
      try {
          removeBlock();
          setTimeout(() => {
              toast.success(`User unblocked.`, {
                  duration: 3000,
                  position: 'top-center',
                  style: {
                      backgroundColor: '@apply bg-boxes',
                      color: '@apply text-text'
                  }
              });
          }, 1000);
      }catch(error: any) {
        // Handle API request errors here
        console.error('API request error:', error);
        // You can also display an error toast or take other actions as needed.
    };
  };



  return (
    <div>
      {
        (blockedUsers && blockedUsers?.length > 0)
        ?
        blockedUsers?.map((blocked: any) => {
          return (
            <BlockedBox 
              key={blocked?.user_id} 
              incomingId={incomingId} 
              blocked={blocked} 
              isBlockLoading={isBlockLoading}
              isBlocked={isBlocked}
              handleBlock={handleBlock}
              handleUnBlock={handleUnBlock}
            />
          )
        })
      : <NoResults text={`No User Blocked`} />}
    </div>
  )
}

export default BlockedTab;