import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useSelector } from 'react-redux'

import PageInfo from '../../components/layout/ContentArea/PageInfo'
import { useEffect, useRef, useState } from 'react'
import { getUserWithoutId, updatePassword, updateUser, uploadUserImage } from '../../services/userService'
import BasicButton from '../../components/buttons/BasicButton'
import { toast, Toaster } from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { setUser } from '../../redux/features/authSlice'
import { resendOTP, verifyAccount } from '../../services/authService'
import OTPVerifyModal from '../../components/Modals/OTPModal'
import { useNavigate } from 'react-router-dom'

const ChangePassword = () => {
    const user = useSelector((state: any) => state.auth.user);
    const [OTPModal, setOTPModal] = useState<boolean>(false)
    const [loggedUser, setLoggedUser] = useState<any>()
    const [otpCountdown, setOtpCountdown] = useState(60);
    const [loading, setLoading] = useState<boolean>(false);
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputValues, setInputValues] = useState({
        old_password: '',
        new_password:  '',
        otp: ''
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setInputValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
    };

    useEffect(() => {
        const fetchUser = async () => {
            setLoggedUser( await user);
        };
        if ( otpCountdown === 0 ) {
            setLoading(false);
        }
        fetchUser();
    }, [user?.id]);

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setOtpCountdown((prevCountdown: any) =>
                prevCountdown > 0 ? prevCountdown - 1 : 0
            );
            }, 1000);
            console.log('OTPModal boolean', OTPModal)
            return () => clearInterval(countdownInterval);
    }, []);

    const handleProfileSubmit = async (e: any) => {
        e.preventDefault();
        const { 
            old_password, new_password, otp
        } = inputValues;

        const data = {
            old_password,
            new_password
        };

        if (new_password !== confirmNewPassword) {
            toast.error("New password and confirm new password don't match.", {
                position: 'top-center',
                style: {
                    backgroundColor: '@apply bg-boxes',
                    color: '@apply text-text'
                }
            });
            return;
        };

        const userEmail = user?.email;
        const verifyResponse = await verifyAccount({email: userEmail, otp}); 
        console.log('VERIFY OTP', verifyResponse);

        if ('status' in verifyResponse && verifyResponse.status === 200) {
            toast.success('OTP Verified', {
                duration: 5000,
                position: 'top-center',
                style: {
                backgroundColor: '@apply bg-boxes',
                color: '@apply text-text'
                }
            });
            setLoading(true);
            const response = await updatePassword(data);
            
            console.log('UPDATED PASSWORD', response)
            if ('statusText' in response && response.statusText === 'OK') {
                dispatch(setUser(response?.data))
                toast.success(response?.data.message, {
                    duration: 3000,
                    position: 'bottom-center',
                    style: {
                    backgroundColor: '@apply bg-boxes',
                    color: '@apply text-text'
                    }
                });
                setTimeout(() => {
                    setOTPModal(false);
                    setLoading(false);
                    navigate('/profile');
                }, 2000)
            }

            if ('statusCode' in response && response.statusCode === '401') {
                // reset();
                setLoading(false);
                toast.error('Network error, OTP not resent!', {
                    duration: 5000,
                    position: 'top-center',
                    style: {
                        backgroundColor: '@apply bg-boxes',
                        color: '@apply text-text'
                    }
                });
            }
            if ('statusCode' in response && response.statusCode === '409') {
                // reset();
                
                setLoading(false);
                toast.error('Network error, OTP not resent!', {
                duration: 5000,
                position: 'top-center',
                style: {
                    backgroundColor: '@apply bg-boxes',
                    color: '@apply text-text'
                }
                });
            }
        }
        if ('statusCode' in verifyResponse && verifyResponse.statusCode === '401') {
            // reset();
            setLoading(false);
            toast.error('Network error, OTP not resent!', {
                duration: 5000,
                position: 'top-center',
                style: {
                    backgroundColor: '@apply bg-boxes',
                    color: '@apply text-text'
                }
            });
        }
        if ('statusCode' in verifyResponse && verifyResponse.statusCode === '409') {
            // reset();
            
            setLoading(false);
            toast.error('Network error, OTP not resent!', {
            duration: 5000,
            position: 'top-center',
            style: {
                backgroundColor: '@apply bg-boxes',
                color: '@apply text-text'
            }
            });
        }
    };

    const handleResendOTP = async () => {
        const email = user?.email;
        const response = await resendOTP({email});
        console.log('status', response);
        if ('status' in response && response.status === 201) {
            setOtpCountdown(60);
            toast.success(response?.data?.message, {
                duration: 5000,
                position: 'top-center',
                style: {
                backgroundColor: '@apply bg-boxes',
                color: '@apply text-text'
                }
            });
        }
        if ('statusCode' in response && response.statusCode === '401') {
            // reset();
            toast.error('Network error, OTP not resent!', {
            duration: 5000,
            position: 'top-center',
            style: {
                backgroundColor: '@apply bg-boxes',
                color: '@apply text-text'
            }
            });
        }
        if ('statusCode' in response && response.statusCode === '409') {
            // reset();
            toast.error('Network error, OTP not resent!', {
            duration: 5000,
            position: 'top-center',
            style: {
                backgroundColor: '@apply bg-boxes',
                color: '@apply text-text'
            }
            });
        }
    }
    const onSubmit = async (e: any) => {
        console.log('handleForm is called 1');
        e.preventDefault();
        if (OTPModal) {
            handleProfileSubmit(e);
        } else {
            
        console.log('handleForm is called 2');
            setOTPModal(true);
            handleResendOTP();
        }
    };

    return (
        <section>
            <form className='w-full flex flex-col items-center justify-around py-5' name='form'
                onSubmit={onSubmit} action='POST'>
                <div className='mt-10 flex flex-col md:w-[400px]'>
                    <input
                        minLength={5}
                        maxLength={20}
                        placeholder='Enter old password'
                        className='w-full p-3 mt-2 border bg-boxes text-text border-border rounded'
                        type="password"
                        name='old_password'
                        // defaultValue={user?.old_password}
                        value={inputValues.old_password}
                        onChange={handleChange}
                    />
                    <input
                        minLength={6}
                        maxLength={32}
                        placeholder='Enter new password'
                        className='w-full p-3 mt-2 border bg-boxes text-text border-border rounded'
                        type="password"
                        name='new_password'
                        // defaultValue={user?.new_password}
                        value={inputValues.new_password}
                        onChange={handleChange}
                    />
                    <input
                        minLength={6}
                        maxLength={32}
                        placeholder='Confirm new password'
                        className='w-full p-3 mt-2 border bg-boxes text-text border-border rounded'
                        type="password"
                        name='confirm_new_password'
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />

                    {OTPModal && (
                        <OTPVerifyModal modalOpen={OTPModal} setModalOpen={setOTPModal}>
                            <div className='w-[90%] md:w-[90%] mx-auto'>
                                <input
                                    minLength={6}
                                    maxLength={6}
                                    placeholder='Type your OTP.'
                                    type='number'
                                    name='otp'
                                    value={inputValues.otp}
                                    onChange={handleChange}
                                    className='pl-3 text-white text-md bg-main p-2 w-full outline-none border border-1 rounded-md border-boxes placeholder-neutral-500'
                                />
                                <div className='flex flex-row mb-5 flex-wrap items-center justify-between text-sm mx-auto'>
                                    <p>Resend OTP in {otpCountdown} seconds</p>
                                    <button type="button" className='text-notification' onClick={handleResendOTP}>Resend OTP</button>
                                </div>
                                <BasicButton
                                    type='submit'
                                    onClick={onSubmit}
                                >
                                    {loading ? 'Verifying...' : 'Verify'}
                                </BasicButton>
                            </div>
                        </OTPVerifyModal>
                    )}
                    {/* <BasicButton type='submit'>Save</BasicButton> */}
                    <div className="flex gap-2 flex-wrap flex-col sm:flex-row justify-between items-center my-4">
                        <button type='submit' className="bg-main font-medium transitions hover:bg-[#FF4500] border border-[#FF4500] text-white py-3 px-6 rounded w-full sm:w-auto">
                            Change Password
                        </button>
                    </div>
                </div>
            </form>
            <Toaster />
        </section>
    )
}

export default ChangePassword;