
import Search from "./components/Search";
import { useEffect, useState } from "react";
import PageInfo from "../../components/layout/ContentArea/PageInfo";
import { getAllPosts, getUploadsBySearch } from "../../services/postService";
import Upload from "../Home/components/Upload";
import { useDispatch } from "react-redux";
import UploadsPage from "../Uploads/components/AllUploads";
import NoResults from "../../components/NoResults";

const UploadsFolder = () => {
  const [search, setSearch] = useState<string>('');
  const [Folder, setUploads] = useState<Upload[]>([]);
  const [allUploads, setAllUploads] = useState<any>([]);
  const [isPending, setIsPending] = useState<boolean>(false);

  
  const dispatch = useDispatch();

  useEffect(() => {
    // if(!search) return;

    setIsPending(true);
    const fetchUploads = async () => {
        const result = await getUploadsBySearch(search);
        setUploads(result.uploads);
    }
    fetchUploads();
    setIsPending(false);
}, [search]);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
        {/* <PageInfo search={<Search search={search} setSearch={setSearch} />}  upload={<Upload />} /> */}
        {/* <UploadsPage /> */}
        
          <NoResults text="SHOP, COMMING SOON..." />
        
    </div>
  )
}

export default UploadsFolder