import { FC, useState, useTransition } from "react";
import { useSelector } from "react-redux"

import Spinner from "../../../components/loading/Spinner";
import BlockedTab from "./Block/BlockedTab";
import FollowersTab from "./Followers/FollowersTab";
import MediaTab from "./Medias/MediaTab";
import FollowingsTab from "./Followings/FollowingsTab";
import useFollowerStatus from "../../../hooks/useFollowerStatus";

type Props = {
    otherUser: any;
    loggedUser?: any;
}

const Tabs: FC<Props> = ({ otherUser, loggedUser }) => {
    const user = useSelector((state: any) => state.auth.user);
    const [tab, setTab] = useState('followers');
    const [isPending, startTransition] = useTransition();
    const { fetchAllFollowingsById } = useFollowerStatus(otherUser?.user_id );

    const handleClickFollowers = () => {
        fetchAllFollowingsById();
        startTransition(() => {
            setTab('followers');
        });
    };

    const handleClickFollowing = () => {
        startTransition(() => {
            setTab('followings');
        });
    };

    const handleClickBlocked = () => {
        startTransition(() => {
            setTab('blocked');
        });
    };

    const handleClickMedias = () => {
        startTransition(() => {
            setTab('medias');
        });
    };

    return (
        <div className="w-full mx-auto pb-10">
            <div className="flex justify-around">
                <span
                    onClick={handleClickFollowers}
                    className={`
                        text-xl py-3 cursor-pointer w-full text-center text-text duration-200 transition-all border-b 
                        ${tab === 'followers' ? 'border-b-2 font-semibold' : 'hover:border-white border-neutral-700'}
                    `}
                >
                    Followers
                </span>
                {
                    user?.id === loggedUser?.id
                    &&
                    <>
                        <span
                            onClick={handleClickFollowing}
                            className={`
                                text-xl py-3 cursor-pointer w-full text-text text-center duration-200 transition-all border-b 
                                ${tab === 'followings' ? 'border-b-2 font-semibold' : 'hover:border-white border-neutral-700'}
                            `}
                        >
                            Following
                        </span>
                        {user?.id === otherUser?.user_id &&
                            <span
                                onClick={handleClickBlocked}
                                className={`
                                    text-xl py-3 cursor-pointer w-full text-text text-center duration-200 transition-all border-b 
                                    ${tab === 'blocked' ? 'border-b-2 font-semibold' : 'hover:border-white border-neutral-700'}
                                `}
                            >
                                Blocked
                            </span>
                        }
                        <span
                            onClick={handleClickMedias}
                            className={`
                                text-xl py-3 cursor-pointer w-full text-text text-center duration-200 transition-all border-b 
                                ${tab === 'medias' ? 'border-b-2 font-semibold' : 'hover:border-white border-neutral-700'}
                            `}
                        >
                            Media
                        </span>
                    </>
                }
            </div>
            <div>
                {
                    isPending
                        ?
                        <div className="mt-10">
                            <Spinner size="sm" />
                        </div>
                        :
                        <>
                            {
                                tab === 'followers' && <FollowersTab incomingId={otherUser?.user_id} />
                            }
                            {
                                tab === 'followings' && <FollowingsTab incomingId={otherUser?.user_id} />
                            }
                            {
                                tab === 'medias' && <MediaTab incomingId={otherUser?.user_id} profileId={loggedUser?.id} />
                            }
                            {
                                tab === 'blocked' && <BlockedTab incomingId={otherUser?.user_id} />
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default Tabs