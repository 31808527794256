import React from 'react'
import useSkeletons from '../../../hooks/useSkeletons';

const AvatarSkeleton: React.FC<any> = (props) => {
    const count: any = (props.count) ? parseInt(props.count) : 1;
    const { SkeletonAvatarDiv } = useSkeletons();

    return (
        <>
            {
                [...Array(count)].map((item, index) => {
                    return (
                        <>
                            <SkeletonAvatarDiv  
                            />
                        </>
                    )
                })
            }
        </>
    )
}
export default AvatarSkeleton;
