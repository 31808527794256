import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

type CartState = {
    cartItems: any[];
    session: {
        id: string;
        url: string;
    };
};

var initialState: CartState = { cartItems: [], session: {
    id: '',
    url: ''
} };

export const shopSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    addCartItem: (state, action: PayloadAction<any>) => {
      if (state?.cartItems === undefined) {
        state.cartItems = [];
      }
      state.cartItems = action?.payload;
      console.log('FROM SHOP SLICE', action?.payload)
    },
    setSessionData: (state, action: PayloadAction<any>) => {
        if (state?.session === undefined) {
          state.session = {id: '',
          url: ''};
        }
        state.session = action?.payload;
        console.log('FROM SHOP SLICE', action?.payload)
      }
  }
});

export const { addCartItem, setSessionData } = shopSlice.actions;
export default shopSlice.reducer;
