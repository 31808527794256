import { FC, ReactNode } from 'react'

type Props = {
  type?: any;
  onClick?: any;
  children?: ReactNode;
}

const LoginBasicButton: FC<Props> = ({ type = 'button', onClick, children }) => {
  return (
    <button type={type} onClick={onClick} className='w-[97%] flex flex-row items-center justify-start rounded-md bg-button text-xl text-text ml-1 p-3 hover:bg-buttonHover duration-200'>
      {children}
    </button>
  )
}

export default LoginBasicButton;