import Cookies from "js-cookie";
import { useContext, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import Divider from "../../Register/components/Divider";
import { ThemeModeContext } from "../../../services/themeService";
import ResetPasswordForm from "../components/ResetPasswordForm";
import { FaArrowLeft } from "react-icons/fa";

interface ResetPasswordProps {
  handlePage: () => void;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ handlePage }) => {
  
  const { themeMode, handleThemeToggle } = useContext(ThemeModeContext);
  const blackLogo = 'https://tekfall-home.s3.amazonaws.com/tekfall+supreme_logo_blackresized1.png';
  const whiteLogo = 'https://tekfall-home.s3.amazonaws.com/brand-logo.png';

  return (
    <div className="flex flex-col justify-center min-h-screen items-start h-auto">
      {/* <ThemeSwitchButton /> */}
      <div className="bg-boxes px-3 py-10 shadow-lg rounded-md text-white min-h-[500px] w-full sm:w-[400px] h-full sm:h-auto">
        <FaArrowLeft className='ml-5 text-white cursor-pointer' onClick={handlePage} />
        <LazyLoadImage
          className='w-[50%] mx-auto hidden sm:block'
          src={themeMode === 'dark' ? whiteLogo : blackLogo }
          alt='logo'
          effect='blur'
        />
        <h1 className='text-3xl font-semibold text-center sm:hidden mb-10'>Reset Password</h1>
        <ResetPasswordForm handlePage={handlePage} />
        <div className='text-center mb-3'>
          <Divider />
          <div className="text-center w-[90%] md:w-[80%] mx-auto" onClick={handlePage}>
            <Link className='text-text duration-200 w-full text-center mx-auto text-lg' to='/login'>Already have an account? <span className='text-notification'>Login</span></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword;