import { FC } from "react";
import { Toaster } from "react-hot-toast";
import { IoPersonRemoveSharp, IoPersonAddSharp } from "react-icons/io5";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GoVerified } from "react-icons/go";
import TransparentFollowButton from "../../../../components/buttons/TransparentFollowButton";

type Props = {
    blocked?: any;
    isBlocked?: any;
    incomingId?: any;
    handleBlock?: any;
    handleUnBlock?: any;
    isBlockLoading?: any;
};

const BlockedBox: FC<Props> = (
    { 
        isBlocked, incomingId, blocked,
        isBlockLoading, handleBlock, handleUnBlock
    }
) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.auth.user)

    console.log('BLOCKED', blocked)
    return (
        <>
            <div className="flex flex-row gap-3 w-full justify-start hover:bg-primary p-2 cursor-pointer font-semibold rounded-sm items-center">
                {blocked?.avatar ? (
                    <LazyLoadImage
                        onClick={() => navigate(`/profile/${blocked?.user_id}`)}
                        src={blocked?.avatar}
                        alt="user-profile"
                        className="w-10 h-10 object-cover rounded-full cursor-pointer"
                    />
                ) : (
                    <div 
                        onClick={() => navigate(`/profile/${blocked?.user_id}`)}
                        className="w-10 h-10 object-cover rounded-full cursor-pointer flex flex-col text-center items-center bg-gray-300 text-4xl text-gray-600 font-bold"
                    >
                    {blocked?.username?.charAt(0).toUpperCase()}
                    </div>
                )}
                <div 
                    onClick={() => navigate(`/profile/${blocked?.user_id}`)} 
                    className="hidden lg:block xl:block">
                    <p 
                       className="flex gap-1 items-center text-md font-bold text-text lowercase"
                    >
                    {user?.id === blocked?.user_id ? 'You' : blocked?.username?.replace(/\s+/g, "")}{" "}
                    {blocked?.is_premium &&<GoVerified className="text-[#FF4500]" />}
                    </p>
                    <p 
                        className="capitalize text-white text-xs"
                    >
                        {user?.id === blocked?.user_id ? 'You' : blocked?.username}
                    </p>
                </div>
                <div className="ml-10 flex flex-row items-center justify-center">
                    {user?.id != blocked?.user_id &&
                        <>
                            <TransparentFollowButton
                                isTextCanClosed
                                // Icon={IoPersonRemoveSharp}
                                text={isBlockLoading ? 'Unblocking...' : 'Unblock'}
                                type="button"
                                onClick={handleUnBlock}
                            />
                        </>
                    }
                </div>
            </div>
            <Toaster />
        </>
    )
}

export default BlockedBox;