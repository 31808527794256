import React from 'react'
import useSkeletons from '../../../hooks/useSkeletons';

const MediaSkeleton: React.FC<any> = (props) => {
    const count: any = (props.count) ? parseInt(props.count) : 1;
    const { SkeletonDiv } = useSkeletons();

    return (
        <>
            {
                [...Array(count)].map((item, index) => {
                    return (
                        <>
                            <SkeletonDiv 
                                width='100%' maxWidth='450px'
                                height='590px' radius="5px" 
                            />
                        </>
                    )
                })
            }
        </>
    )
}
export default MediaSkeleton;
