import React, { useContext } from 'react';
import { ThemeModeContext } from '../../services/themeService';
import { FaSun, FaMoon } from 'react-icons/fa';

const ThemeSwitchButton = () => {
  const { themeMode, handleThemeToggle } = useContext(ThemeModeContext);

  console.log('mode: ', themeMode)


  return (
    <button onClick={handleThemeToggle} className="flex text-text items-center">
      {themeMode === 'light' ? <FaSun className="mr-1" /> : <FaMoon className="mr-1" />}
      {themeMode === 'light' ? 'Dark' : 'Light'}
    </button>
  );
};

export default ThemeSwitchButton;
