import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';

type Props = {
  placeholder?: string;
  minLength?: any;
  maxLength?: any;
  value?: any;
  error?: any;
  onChange?: any;
  informations?: string[];
  refs?: any;
}

const MinorTextInput: React.FC<Props> = ({ placeholder, value, onChange, error, maxLength, minLength, informations, refs}) => {
  const handleSpace = (e: any) => {
    if (e.keyCode === 32) {
      // Prevent the video from playing
      e.preventDefault();
    }
  };
  return (
    <div className={`w-[100%] md:w-[100%] mx-auto m-5`}>
      <div className={`flex items-center border-b border-white rounded-md transition-all duration-100`}>
        <input
          className='pl-3 text-white text-sm bg-main p-2 w-full border border-transparent rounded-md outline-none placeholder-neutral-500'
          placeholder={placeholder}
          maxLength={maxLength}
          minLength={minLength}
          value={value}
          onKeyUp={handleSpace}
          onChange={onChange}
          type='text'
          {...refs}
        />
        {
          (error && informations) 
          &&
          <div className='group'>
            <AiOutlineInfoCircle className='text-2xl' />
            <div className='absolute hidden group-hover:block bg-neutral-800 p-2 shadow-xl'>
              {
                informations.map((info: string, index: number) => {
                  return <p key={index}>* {info}</p>
                })
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default MinorTextInput