import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTheme } from '../redux/features/themeSlice';

export const ThemeModeContext = React.createContext({
    themeMode: 'dark', // Set the initial theme mode here
    handleThemeToggle: () => {},
});

export const ThemeModeProvider = ({ children }: any) => {
  const dispatch = useDispatch();
  const themeMode = useSelector((state: any) => state.theme.mode);

  const handleThemeToggle = () => {
    dispatch(toggleTheme());
  };

  const contextValue = {
    themeMode,
    handleThemeToggle,
  };

  return (
    <ThemeModeContext.Provider value={contextValue}>
      {children}
    </ThemeModeContext.Provider>
  );
};