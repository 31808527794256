import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { FaArrowLeft, FaArrowRight, FaCloudUploadAlt } from 'react-icons/fa';
import { topics } from '../../utils/discover';
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import MainModal from '../Modals/MainModal';
import { useDispatch } from "react-redux";
import { addPosts } from "../../redux/features/feedSlice";
import MediaFactory from "../MediaFactory";
import MinorTextInput from "../inputs/MinorTextInput";
import MinorTextArea from "../inputs/TextArea";
import Detect from 'detect.js';
import useAllPosts from "../../hooks/useAllPosts";
// import { config } from "../../utils/client";
// import { createClient } from "@sanity/client";

interface IProp {
    modalOpen: any;
    setModalOpen: any;
}

// const client = createClient(config);

const getFileNameWithoutExtension = (fileName: string) => {
  const extensionIndex = fileName?.lastIndexOf('.');
  if (extensionIndex !== -1) {
    return fileName?.substring(0, extensionIndex);
  }
  return fileName;
};

const formatFileName = (fileName: string) => {
  if (fileName?.length > 15) {
    return fileName?.substring(0, 15) + '...';
  }
  return fileName?.replace(/_/g, ' ');
};

const UploadModal: React.FC<IProp> = ({modalOpen, setModalOpen}) => {
  const [caption, setCaption] = useState('');
  const [description, setDescription] = useState('');
  const [includeCharacter, setIncludeCharacter] = useState<any>(false);
  const [topic, setTopic] = useState<string>(topics[0].name);
  const [loading, setLoading] = useState<boolean>(false);
  const [replacing, setReplacing] = useState<boolean>(false);
  const [fileAssets, setFileAssets] = useState<any[]>([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(1);
  const posts = useSelector((state: any) => state.feed.posts);
  const userProfile = useSelector((state: any) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { page, isLoading, setIsLoading, addPost, fetchAllPosts } = useAllPosts();

  useEffect(() => {
    if (!userProfile) navigate('/');
    console.log('USER', userProfile)
  }, [userProfile, navigate]);
  
  const handlePost = async (e: any) => {
    e.preventDefault();

    setTimeout(() => { 
      setModalOpen(!modalOpen);
      setFileAssets([]);
      setCaption('');
      setDescription('');
      setTopic('');
    }, 3000);
    
    try {
      if (caption && fileAssets.length > 0) { 
        
        const formData = new FormData();
        formData.append('caption', caption);
        formData.append('content', description);
        fileAssets.forEach((file) => {
          formData.append("media", file);
        });
        formData.append('censored', includeCharacter);
        // formData.append('user_id', userProfile?.id);

      const response = await addPost(formData);

      console.log('profile', userProfile);
      console.log('FILE RESPONSE', response);
      if ('status' in response && response.status === 201) {
        toast.success('Post successful', {
          duration: 5000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
        });
        // Store the token and user data as needed
        // Cookies.set('file', file, { expires: 3 });
        console.log('UPLOAD RES', response)
        dispatch(addPosts(response?.data?.post?.Item));
        fetchAllPosts(page)
        // navigate('/');
        handleDiscard();
      } else if ('status' in response && response.status === 401) {
        toast.error(response.data.message, {
          duration: 5000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
        });
      } else if ('statusCode' in response && response.statusCode === '400') {
        toast.error(response.data.message, {
          duration: 5000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
        });
      } else if ('status' in response && response.status === '500') {
        toast.error('Network error, try again in 1 minute', {
          duration: 5000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
        });
      }
    } }catch (error) {
      console.log('Error:', error);
      toast.error('An error occurred. Please try again later.', {
        duration: 5000,
        position: 'top-center',
        style: {
          backgroundColor: '@apply bg-boxes',
          color: '@apply text-text'
        }
      });
    }
  }
  
  const handleDiscard = () => {
    setIsLoading(false);
    setFileAssets([]);
    setCaption('');
    setDescription('');
    setTopic('');
  };

  useEffect(() => {
    console.log('ASSET', fileAssets)
  }, [fileAssets])
  
  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);

    if (event.target.checked) {
      setIncludeCharacter(true);
    } else {
      setIncludeCharacter(false);
    }
  };

  useEffect(() => {
    if (Array.isArray(fileAssets)) {
      const formattedCaptions = fileAssets.map((file) =>
        formatFileName(getFileNameWithoutExtension(file?.name))
      );
  
      setCaption(
        fileAssets.length === 1
          ? formattedCaptions[0]
          : `${formattedCaptions.length} files selected`
      );
    }
  }, [fileAssets]);
  
  
  
  const handleSteps = () => {
    if (currentStep === 1) {
      setCurrentStep(2);
    } else {
      setCurrentStep(1);
    }
  };

  const form = (
    <div className="flex w-full max-w-[320px] ml-5 flex-col gap-3">
      <div className="min-h-[150px]">
        <p className="text-2xl text-white font-bold">Upload Media</p>
        <p className="text-md text-text mt-1">
          Post a character
        </p>
      </div>
      {/* <input
        type="text"
        value={caption}
        onChange={(e) => setCaption(e.target.value)}
        className="rounded lg:after:w-650 outline-none bg-mnf text-md border-border border-gray-200 p-2"
      /> */}
      <MinorTextInput
        value={caption}
        onChange={(e:any) => setCaption(e.target.value)}
        placeholder={'Title'}
        maxLength={30}
        minLength={1}
      />
      {/* <textarea
        // type="text"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        className="rounded lg:after:w-650 outline-none bg-mnf text-md border-border border-gray-200 p-2"
      ></textarea> */}
      <MinorTextArea 
        value={description}
        onChange={(e:any) => setDescription(e.target.value)}
        placeholder={'Description'}
        maxLength={400}
        minLength={1}
      />
      {/* <div className="flex gap-6 mt-5"> */}
        <div className="flex items-center">
          <input
            type="checkbox"
            id="includeCharacter"
            checked={isChecked}
            onChange={handleCheckboxChange}
            className="mr-2 h-2 w-2 bg-mnf"
            style={{ transform: 'scale(2.5)' }}
            title="Include This Character In TekFall Supreme Game"
          />
          <style>
            {`
            /* Styling for the tooltip */
            #includeCharacter::after {
              content: attr(title);
              display: inline-block;
              position: relative;
              top: -1.5em;
              left: 0;
              width: 100px; /* Set the width of the tooltip */
              padding: 0.5em;
              margin-left: 2.2em;
              background-color: #fff; /* Set the background color of the tooltip */
              color: #000; /* Set the text color of the tooltip */
              font-size: 5px; /* Set the font size of the tooltip */
              border-radius: 2px; /* Set the border radius of the tooltip */
              border: 1px solid transparent; /* Set the border color of the tooltip */
              opacity: 0; /* Initially hide the tooltip */
              transition: opacity 0.2s ease-in-out;
            }

            #includeCharacter:hover::after {
              opacity: 1; /* Show the tooltip on hover */
            }
            `}
          </style>
        </div>
      {/* </div> */}
      <div className="flex gap-6 mt-10">
        <button
          onClick={handleDiscard}
          type="button"
          className="border-[#FF4500] hover:border-red-500 border text-[#FF4500] hover:text-red-500 text-md font-medium p-2 rounded w-28 lg:w-44 outline-none"
        >
          Discard
        </button>
        <button
          onClick={handlePost}
          type="submit"
          className={`text-[#fff] text-md font-medium p-2 rounded w-28 lg:w-44 outline-none hover:bg-red-500 bg-[#FF4500]`}
        >
          {isLoading ? 'Posting...' : 'Post'}
        </button>
      </div>
    </div>
  );

  const file = (
    <div className="flex flex-col justify-center items-center">
      <MediaFactory fileAssets={fileAssets} setFileAsset={setFileAssets} />
      {fileAssets.length > 0 &&
        fileAssets.map((fileAsset, index) => (
          <div
            key={index}
            className="text-center flex items-center pl-4 border-dashed border-4 border-border hover:border-[#FF4500] rounded-full justify-between mt-4 w-full max-w-[3200px] bg-main hover:shadow-md transition-all duration-500 ease-in-out"
          >
            <p className="text-md text-text">
              {replacing ? 'Replacing... ' : formatFileName(fileAsset?.name)}
            </p>
            <button
              type="button"
              className="text-text font-bold border-b border-t border-r border-dashed border-4 border-border hover:border-[#FF4500] rounded-full bg-boxes p-2 text-md hover:text-white text-center cursor-pointer outline-none"
              onClick={() => {
                setReplacing(true);
                setTimeout(() => {
                  setReplacing(false);
                  setFileAssets(prevAssets =>
                    prevAssets.filter((_, idx) => idx !== index)
                  );
                }, 2000);
              }}
            >
              {replacing
                ? formatFileName(fileAsset?.name)
                : `Replace ${
                    fileAsset?.type.startsWith('video/') ? 'video' : 'image'
                  }`}
            </button>
          </div>
        ))}


      {/* {wrongFileType && (
          <p className="text-center text-xl text-red-400 font-semibold mt-4 w-[260px]">
              Please select a media file (mp4, gif, jpeg, jpg, png, avi, mov, webm, or ogg)
          </p>
      )} */}
    </div>
  )
  return (
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="flex min-h-full mb-10 pt-5 min-h-fit justify-center">
        <div className="bg-boxes md:hidden xl:hidden xl:hidden rounded-lg flex min-h-fit flex-wrap justify-center items-center p-5 pt-3">
          <div className="flex w-full justify-start mt-4">
              {currentStep === 2 ? 
                <FaArrowLeft
                  className="mr-3 md:mr-5 text-white cursor-pointer"
                  onClick={handleSteps}
                />
              :
                <FaArrowRight
                  className="mr-3 md:mr-5 text-white cursor-pointer"
                  onClick={handleSteps}
                />
              }
          </div>
          {currentStep === 1 ? (
            file 
          ) : (
            form
          ) }
        </div>

        <div className="bg-boxes rounded-lg hidden md:flex lg:flex xl:flex flex min-h-fit flex-wrap justify-center items-center p-5 pt-3">
          {file }
          {form}
        </div>
      </div>
    </MainModal>
  );
};

export default UploadModal;