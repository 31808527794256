import React, { useState, useRef} from 'react'
import './index.css'
import { FaCloudUploadAlt } from 'react-icons/fa';
import { Toaster, toast } from 'react-hot-toast';
import ResponsiveImageRenderer from '../MediaLibrary/ResponsiveImage';
import { ThirdPlayer } from '../MediaLibrary/ThirdPlayer';
// import pkg from '../package.json'

type IProps = {
    fileAssets?: any[];
    setFileAsset?: any;
};

const MediaFactory: React.FC<IProps> = ({ fileAssets, setFileAsset }: any) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<any>('');


    const [ trimRange, setTrimRange ] = useState<[from: number, to: number]>([ 5, 10 ]);


    const [ videoSrc, setVideoSrc ] = useState('')
    const fileRef = useRef<File | null>(null)
    const videoRef = useRef<HTMLVideoElement | null>(null)

    const videoIntervalIntervalRef: React.MutableRefObject<any> = useRef(0)
    clearInterval(videoIntervalIntervalRef.current)

    videoIntervalIntervalRef.current = setInterval(() => {
        if (!videoRef.current) return;
        const video = videoRef.current;
    
        const el = document.querySelector('#currentTime')!;
        el.innerHTML = `${msToTime(video.currentTime * 1000)}/${msToTime(trimRange[1])}`;
    
        if (video.paused) return;
    
        if (video.currentTime * 1000 >= trimRange[1]) {
            video.currentTime = trimRange[0] / 1000;
        }
    }, 1000 / 24);    



    const msToTime = (s: number) => {
        function pad(n: number, z?: number) {
            z = z || 2;
            return ('00' + n).slice(-z);
        }
    
        let ms = s % 1000;
        s = (s - ms) / 1000;
        let secs = s % 60;
        s = (s - secs) / 60;
        let mins = s % 60;
        let hrs = (s - mins) / 60;
    
        const microsecs = ms.toFixed(0).padStart(3, '0');
    
        return pad(hrs) + ':' + pad(mins) + ':' + pad(secs);
    } 

    const handleVideoSelection = (file: any) => {
        const maxSize = 100 * 1024 * 1024; // 100 MB in bytes
        const maxDuration = 60; // 60 seconds
        const supportedTypes = ['video/mp4', 'video/webm', 'video/ogg', 'image/jpeg', 'image/png', 'image/gif'];

        const video = document.createElement('video');
        video.preload = 'metadata';
        video.src = URL.createObjectURL(file);

        const imageElement = document.createElement('img');
        imageElement.src = URL.createObjectURL(file);

        setSelectedFile(file);
        setFileAsset([file]);

        if (!supportedTypes.includes(file?.type)) {
            toast.error('Unsupported file type.', {
                duration: 3000,
                position: 'top-center',
                style: {
                  backgroundColor: '@apply bg-boxes',
                  color: '@apply text-text'
                }
            });
            return;
        }

        if (file.size > maxSize) {
            toast.error('File size is greater than 100MB.', {
                duration: 3000,
                position: 'top-center',
                style: {
                  backgroundColor: '@apply bg-boxes',
                  color: '@apply text-text'
                }
            });
            return;
        }

        setLoading(true);

        if (file.type?.startsWith('video/')) {
            video.onloadedmetadata = () => {
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
                if (video.duration > maxDuration) {
                    toast.error('Video duration is longer than 1 minute.', {
                        duration: 3000,
                        position: 'top-center',
                        style: {
                            backgroundColor: '@apply bg-boxes',
                            color: '@apply text-text'
                        }
                    });
                    video.remove();
                    return;
                }

                fileRef.current = file;
                setVideoSrc(URL.createObjectURL(file));
            }
            video.remove();
        } else if (file.type?.startsWith('image/')) {
            imageElement.onload = () => {
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
                fileRef.current = file;
                setVideoSrc(URL.createObjectURL(file));
            }
            imageElement.remove();
        }
    }


    const nonVideoCoverStyle = 'border-dashed rounded-xl border-4 bg-boxes border-border flex flex-col justify-center items-center  outline-none mt-10 max-w-[320px] min-h-[532px] p-10 cursor-pointer hover:border-[#FF4500] hover:bg-main';
    const videoCoverStyle = 'rounded-xl bg-main border-border flex flex-col justify-center items-center  outline-none mt-10 max-w-[320px] min-h-[532px] cursor-pointer';


    const upload: JSX.Element = (
        <div>
            <label className="cursor-pointer">
                <div className="flex flex-col items-center justify-center h-full">
                    <div className="flex flex-col justify-center items-center">
                        <p className="font-bold text-xl">
                        <FaCloudUploadAlt className="text-text text-6xl" />
                        </p>
                        <p className="text-xl text-white font-semibold">
                        Select media to upload
                        </p>
                    </div>

                    <p className="text-text text-center mt-10 text-sm leading-10">
                        {/* MP4, PNG, JPG, JPEG, WebM or ogg <br />
                        720x1280 resolution or higher <br /> */}
                        Up to 1 minutes <br />
                        Less than 100 MB
                    </p>
                    <p className="bg-[#FF4500] text-center mt-8 rounded text-[#fff] text-md font-medium p-2 w-52 outline-none">
                        Select or drag file
                    </p>
                    </div>
                    <input
                        type="file"
                        name="upload-video"
                        onChange={(e) => {
                            const selectedFile = e.target.files?.[0];
                            if (selectedFile) {
                                handleVideoSelection(selectedFile);
                            }
                        }}
                        className="w-0 h-0"
                    />
            </label>
        </div>
    )

    return (
        <div
            ref={ref}
            onDrop={(evt) => {
                evt.preventDefault();
                handleVideoSelection(evt.dataTransfer.files[0]);
            }}
            onDragOver={(evt) => evt.preventDefault()}
        >
            <div className={videoSrc && fileAssets ? videoCoverStyle : nonVideoCoverStyle}>
                {loading ? (
                    <p className="text-center text-3xl w-[320px] text-white font-semibold">
                        Uploading...
                    </p>
                ) : (
                    <div>
                        {
                            !videoSrc ? (
                                upload
                            ) : (
                                <div className='relative rounded-3xl w-[320px]  p-4 flex flex-col min-h-fit flex-col gap-6 justify-center items-center'>
                                    {videoSrc && fileAssets && fileAssets[0]?.type?.startsWith('video/') ? (
                                        <>
                                            {/* <LazyLoad>
                                                <video
                                                    ref={videoRef}
                                                    onLoadedData={videoReady}
                                                    onPause={() => setIsPlaying(false)}
                                                    className="rounded-xl h-[532px] bg-black"
                                                    onPlay={() => setIsPlaying(true)}
                                                    src={videoSrc}
                                                    // muted
                                                />
                                            </LazyLoad> */}
                                            <ThirdPlayer
                                                src={[
                                                    {
                                                    quality: "Full HD",
                                                    url: videoSrc
                                                        // "https://cdn.glitch.me/cbf2cfb4-aa52-4a1f-a73c-461eef3d38e8/1080.mp4",
                                                    },
                                                    {
                                                    quality: 720,
                                                    url: videoSrc
                                                        // "https://cdn.glitch.me/cbf2cfb4-aa52-4a1f-a73c-461eef3d38e8/720.mp4",
                                                    },
                                                    {
                                                    quality: 480,
                                                    url: videoSrc
                                                        // "https://cdn.glitch.me/cbf2cfb4-aa52-4a1f-a73c-461eef3d38e8/480.mp4?v=1647351419495",
                                                    },
                                                ]}
                                                subtitles={[
                                                    {
                                                    lang: "en",
                                                    language: "English",
                                                    url:
                                                        "https://cdn.jsdelivr.net/gh/naptestdev/video-examples@master/en.vtt",
                                                    },
                                                    {
                                                    lang: "fr",
                                                    language: "French",
                                                    url:
                                                        "https://cdn.jsdelivr.net/gh/naptestdev/video-examples@master/fr.vtt",
                                                    },
                                                ]}
                                                minHeight={'480px'}
                                                // poster="https://cdn.jsdelivr.net/gh/naptestdev/video-examples@master/poster.png"
                                                />
                                            
                                            {/* <div className="absolute appear bottom-0 left-1/2 transform -translate-x-2/4 -translate-y-1/2 pb-4 justify-around flex items-center">
                                                {isPlaying ?
                                                    <FaPause className='text-[#FF4500] mr-3 cursor-pointer' onClick={playPause} />
                                                    :
                                                    <FaPlay className='text-[#FF4500] mr-3 cursor-pointer' onClick={playPause} />
                                                }
                                                <span id='currentTime' className='mr-2'>0.0.0/0.0.0</span>
                                                {isMuted ?
                                                    <FaVolumeMute className='text-[#FF4500] mr-3 cursor-pointer' onClick={toggleMute} />
                                                    :
                                                    <FaVolumeUp className='text-[#FF4500] mr-3 cursor-pointer' onClick={toggleMute} />
                                                }
                                                {isFullscreen ?
                                                    <FaCompress className='text-[#FF4500] mr-3 cursor-pointer' onClick={toggleFullscreen} />
                                                    :
                                                    <FaExpand className='text-[#FF4500] cursor-pointer' onClick={toggleFullscreen} />
                                                }
                                            </div> */}
                                        </>
                                    ) : videoSrc && fileAssets && fileAssets[0]?.type?.startsWith('image/') ? (
                                        <ResponsiveImageRenderer  
                                            // sizes="(max-width: 800px) 100vw, 50vw"
                                            // loading="lazy" 
                                            src={videoSrc} 
                                            alt="Upload media" 
                                            // className="rounded-xl h-[532px] bg-black" 
                                        />
                                    ) : (
                                        upload
                                    )}
                                </div>
                            )
                        }
                    </div> 
                )}
                <Toaster />
            </div>
        </div>
    )
}

export default MediaFactory;