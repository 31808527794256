import React from "react";

const HorizontalLoader = () => {
  return (
    <div className="w-full h-2 relative">
      <div className="h-full gradient-bg animate-stripes"></div>
    </div>
  );
};

export default HorizontalLoader;
