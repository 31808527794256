

// import React, { useEffect, useState } from "react";
// import MediaCard from "../../components/MediaCard";
// import NoResults from "../../components/NoResults";
// import Spinner from "../../components/loading/Spinner";
// import useAllPosts from '../../hooks/useAllPosts';
// import { v4 as uuidv4 } from "uuid";
// import MediaSkeleton from "../../components/loading/skeletons/MediaSkeleton";
// // import { setPage } from "../../redux/features/feedSlice";

// const Home: React.FC = () => {
//   const { 
//     isLoading, posts, hasMore, 
//     isLoadingMore, page, fetchAllPosts,
//     loadMore 
//   } = useAllPosts();

//   // Function to load more posts when the user scrolls to the bottom
//   const handleScroll = () => {
//     if (!isLoading && hasMore) {
//       const scrollHeight = document.documentElement.scrollHeight;
//       const currentHeight = window.innerHeight + window.pageYOffset;

//       // if (currentHeight + 1 >= scrollHeight) {
//       //   loadMore();
//       // }
//       if (currentHeight + 1 >= scrollHeight && hasMore) {
//         // setPage(page + 1);
//         loadMore()
//       }
//     }
//   };

//   // Attach the scroll event listener
//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     // Clean up the event listener when the component unmounts

//     console.log('PAE HOMEG 1', page);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [handleScroll]);
//   console.log('PAGE HOME 2', page);

//   return (
//     <div className="flex flex-col p-7 min-w-[320px] h-[100vh]">
//       {posts?.length ? (
//         posts?.map((post: any) => (
//           <MediaCard
//             loading={isLoading}
//             post={post}
//             isShowingOnHome
//             key={post.post_id}
//           />
//         ))
//       ) : isLoadingMore && ( 
//         Array.from(new Array(1)).map((el, index) => (
//           <Spinner size={'sm'} key={uuidv4()} />
//         ))
//       )}
//       {!hasMore && ( 
//         Array.from(new Array(1)).map((el, index) => (
//           <p className="text-center text-gray-500">No more posts to load</p>
//         )))}
//     </div>
//   );
// };

// export default Home;
import React, { useEffect, useState, useRef } from "react";
import MediaCard from "../../components/MediaCard";
import NoResults from "../../components/NoResults";
import Spinner from "../../components/loading/Spinner";
import useAllPosts from '../../hooks/useAllPosts';
import { v4 as uuidv4 } from "uuid";
import MediaSkeleton from "../../components/loading/skeletons/MediaSkeleton";

const Home: React.FC = () => {
  const { 
    isLoading, posts, hasMore, 
    isLoadingMore, page, fetchAllPosts,
    loadMore 
  } = useAllPosts();

  // Ref to store the sentinel element
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  // Function to handle the intersection observer callback
  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting && !isLoading && hasMore) {
      if( posts?.length > 0 ) {
        loadMore();
      }
    }
  };

  // Attach the intersection observer to the sentinel element
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1, // Adjust this threshold as needed
    });
    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [isLoading, hasMore, posts]);

  return (
    <div className="flex flex-col p-7 min-w-[320px] h-[100vh]">
      {posts?.length ? (
        posts?.map((post: any) => (
          <MediaCard
            loading={isLoading}
            post={post}
            isShowingOnHome
            key={post?.post_id}
          />
        ))
      ) : isLoadingMore && posts?.length !== 0 && ( 
        Array.from(new Array(1)).map((el, index) => (
          <Spinner size={'sm'} key={uuidv4()} />
        ))
      )}
      {!hasMore && !isLoading && ( 
        Array.from(new Array(1)).map((el, index) => (
          <p className="text-center text-gray-500 mb-12" key={uuidv4()}>No more posts to load</p>
        )))}
      {/* Sentinel element */}
      {posts?.length === 0 ? <NoResults text={'No Posts Available'} /> : null}
      <div ref={sentinelRef}></div>
    </div>
  );
};

export default Home;