import React, { useEffect, useRef, useState } from "react";
import { FaHeart, FaComment, FaShare } from "react-icons/fa";
import '../../../../components/MediaLibrary/scss/main.scss';
import '../../../../components/MediaLibrary/index.css';
import { SecondPlayer } from "../../../../components/MediaLibrary/SecondPlayer";
import ResponsiveImageRenderer from "../../../../components/MediaLibrary/ResponsiveImage";
import NumberFormatter from "../../../../components/NumberFormater";
import SharePostModal from "../../../../components/Modals/ShareModal";
import useLikes from "../../../../hooks/useLikes";
import { useDispatch, useSelector } from "react-redux";
import useAllPosts from "../../../../hooks/useAllPosts";
import { useNavigate } from "react-router-dom";
import { setPostDetails } from "../../../../redux/features/feedSlice";

type IncomingProps = {
  src?: any;
  info?: any;
  postId?: any;
  caption?: any;
  totalComments?: any;
  totalLikes?: any;
};
const MediaContainer: React.FC<IncomingProps> = (
  { 
    src, caption, postId,
    totalComments, totalLikes 
  }
) => {

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const progressBarRef = useRef<HTMLDivElement | null>(null);
  const [url, setUrl] = useState<any>();
  const [type, setType] = useState<any>();
  const [ trimRange, setTrimRange ] = useState<[from: number, to: number]>([ 5, 10 ]);
  const [urlLow, setUrlLow] = useState('');
  const [urlMedium, setUrlMedium] = useState('');
  const [urlHigh, setUrlHigh] = useState('');
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { 
    addLike, removeLike, 
    setIsLiked, isLikeLoading, isLiked 
  } = useLikes(postId);
  const { page, fetchPostById, fetchAllPosts } = useAllPosts(postId);

  const navigate = useNavigate();
  const user = useSelector((state: any) => state?.auth?.user);
  const postDetail = useSelector((state: any) => state?.feed?.postDetails);

  useEffect(() => {
    const video = videoRef.current;
    const observerOptions = {
      threshold: 1.0, // Adjust threshold as needed
    };
    video&&playPause();

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            video?.play();
          } else {
            video?.pause();
          }
        });
      },
      observerOptions
    );

    if (video) {
      observer.observe(video);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(src) && src.length > 0) {
      const urlsByType = src[0]; // Assuming src[0] contains type and URLs
      const { type, url } = urlsByType;
      const { low, medium, high } = url;
      setUrl(url);
      setType(type);
      setUrlLow(low);
      setUrlMedium(medium);
      setUrlHigh(high);
    }
  }, [src]);
  
  const fetchPost = async () => {
    const response = await fetchPostById();
    dispatch(setPostDetails(response?.data?.data?.post))
    const usersWhoLiked = response?.data?.data?.post?.usersWhoLiked;
    const ifCurrentUserLiked = usersWhoLiked?.find( 
      async (userThatLiked: any) => {
        return await userThatLiked?.user_id === user?.id;
      }
    );

    if (ifCurrentUserLiked) {
      setIsLiked(true);
    }
  }

  useEffect(() => {
    fetchPost();
  }, [user?.id, postId]);

  const playPause = async () => {
    if (!videoRef.current) return

    const video = videoRef?.current

    if (video && video?.paused) {
        video.currentTime = trimRange[0] / 1000
        await video?.play()
    }
    else {
        video && video?.pause()
    }
  }

  const handleLike = async () => {
    // e.preventDefault();
    if (user) {
      await addLike();
    }
    if (!isLikeLoading) {
      fetchPost();
    }
  };
  const handleUnLike = async () => {
    // e.preventDefault();
    if (user) {
      await removeLike();
    }
    if (!isLikeLoading) {
      fetchPost();
    }
  };

  return (
    <>
      <div>
        {type === 'video' ?
          <>
            <SecondPlayer
                src={[
                    {
                        quality: "Full HD",
                        url: urlHigh
                    },
                    {
                        quality: 720,
                        url: urlMedium
                    },
                    {
                        quality: 480,
                        url: urlLow
                    },
                ]}
                subtitles={[
                    {
                        lang: "en",
                        language: "English",
                        url: "https://cdn.jsdelivr.net/gh/naptestdev/video-examples@master/en.vtt",
                    },
                    {
                        lang: "fr",
                        language: "French",
                        url: "https://cdn.jsdelivr.net/gh/naptestdev/video-examples@master/fr.vtt",
                    },
                ]}
                minHeight={'200px'} 
              />
          </>
          :
          (
            <ResponsiveImageRenderer
              // sizes="(max-width: 800px) 100vw, 50vw"
              // loading="lazy" 
              minHeight={'200px'}
              src={url}
              alt="Upload media"
              className="h-full w-full border rounded-[7px] border-1 border-boxes" 
            />
          )}
        </div>
        <SharePostModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          caption={caption}
        />
          <div className={`
            flex flex-row h-fit mt-5 justify-between items-center`}
            >
          {!isLiked ? 
            (<div className="flex relative cursor-pointer items-center space-x-1 mr-5 md:mr-0 lg:mr-0 xl:mr-0 md:mb-6 lg:mb-6 xl:mb-6">
                <button onClick={handleLike} className="bg-boxes border border-1 border-transparent rounded-full p-2">
                    <FaHeart className="text-white text-2xl" />
                </button>
                <div className="w-5 h-5 font-medium bottom-10 left-6 rounded-full text-xs text-notification absolute">
                    <NumberFormatter number={totalLikes} />
                </div>
            </div>
          ):(
            <div className="flex relative cursor-pointer items-center space-x-1 mr-5 md:mr-0 lg:mr-0 xl:mr-0 md:mb-6 lg:mb-6 xl:mb-6">
              <button onClick={handleUnLike} className="bg-boxes border border-1 border-transparent rounded-full p-2">
                  <FaHeart className="text-[#FF4500] text-2xl" />
              </button>
              <div className="w-5 h-5 font-medium bottom-10 left-6 rounded-full text-xs text-notification absolute">
                  <NumberFormatter number={totalLikes} />
              </div>
            </div>
          )}
          <div className="flex relative cursor-pointer items-center mr-5 md:mr-0 lg:mr-0 xl:mr-0 space-x-1 md:mb-6 lg:mb-6 xl:mb-6">
              <button onClick={() => navigate(`/detail/${postId}`)} className="bg-boxes border border-1 border-transparent rounded-full p-2">
                <FaComment className="text-white text-2xl" />
              </button>
              <div className="w-5 h-5 font-medium bottom-10 left-6 rounded-full text-xs text-notification absolute">
                <NumberFormatter number={totalComments} />
              </div>
          </div>
          <div className="flex relative cursor-pointer items-center mr-5 md:mr-0 lg:mr-0 xl:mr-0 space-x-1">
              <button onClick={() => setModalOpen(true)} className="bg-boxes border border-1 border-transparent rounded-full p-2">
                <FaShare className="text-white text-2xl" />
              </button>
              <div className="w-5 h-5 font-medium bottom-10 left-6 rounded-full text-xs text-notification absolute">
                <NumberFormatter number={10} />
              </div>
          </div>
      </div>
    </>
  );
};


export default MediaContainer;
