import React, { ReactNode } from 'react'
import { IconType } from 'react-icons';
import Spinner from '../loading/Spinner';

type Props = {
  type?: 'button' | 'submit' | 'reset';
  onClick?: VoidFunction;
  disabled?: any;
  children?: ReactNode;
  Icon?: IconType;
  text?: string;
  isTextCanClosed?: boolean;
}

const FollowButton: React.FC<Props> = ({ type = 'button', disabled, Icon, text, onClick, children }) => {
  return (
    <button type={type} onClick={onClick} disabled={disabled} className={`
      w-full max-w-[100px] flex justify-center items-center h-[35px] rounded-md p-2 bg-[#FF4500] mr-3
      text-[#fff] text-md font-bold border-1 border-notification hover:bg-red-500 rounded-md duration-200`
    }>
      <>
        {Icon && <Icon className='mr-2 text-md text-[#fff]' />}
      </>
      {text ? text : null}
      {children}
    </button>
  )
}

export default FollowButton;