import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicButton from '../../../../components/buttons/BasicButton';
import MediaContainer from './MediaContainer';
import { useSelector } from 'react-redux';
import { deletePostDetails } from '../../../../services/postService';
import { toast } from 'react-hot-toast';

type Props = {
  fileAssets?: any;
  incomingId?: any;
  profileId?: any;
};

const MediaBox: FC<Props> = ({ fileAssets, incomingId, profileId }) => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.user);

  const handleViewClick = () => {
    navigate(`/detail/${fileAssets?.post_id}`);
  };

  const deletePost = async (e: any) => {
    e.preventDefault();
    try {
      const response = await deletePostDetails(fileAssets?.post_id);
      console.log('DELETED POST', response);
      if ('status' in response && response?.status === 200) {
        toast.success(response?.data?.message, {
            duration: 5000,
            position: 'top-center',
            style: {
              backgroundColor: '@apply bg-boxes',
              color: '@apply text-text'
            }
          });
      } else if ('status' in response && response?.status === 401) {
        toast.error(response?.data?.message, {
            duration: 5000,
            position: 'top-center',
            style: {
              backgroundColor: '@apply bg-boxes',
              color: '@apply text-text'
            }
          });
      } else if ('status' in response && response?.status === 409) {
        toast.error(response?.data?.message, {
            duration: 5000,
            position: 'top-center',
            style: {
              backgroundColor: '@apply bg-boxes',
              color: '@apply text-text'
            }
          });
      }
    } catch (error) {
      console.error("Error fetching post details:", error);
    }
}

  return (
    <>
    <div className="p-3 text-white rounded-sm lg:mr-5 xl:mr-5 md:mr-5 items-center w-full mt-5 max-w-[320px] h-auto bg-boxes">
      <p className='text-text mb-2'>{fileAssets?.caption}</p>
      <MediaContainer 
        postId={fileAssets?.post_id}
        caption={fileAssets?.caption}
        src={fileAssets?.media_items} 
        totalLikes={fileAssets?.totalLikes} 
        totalComments={fileAssets?.totalComments} 
      />
        <div className='w-[300px] flex flex-row justify-between items-center'>
            <div>
            <BasicButton type="button" onClick={handleViewClick} width={'100px'}>
                View
            </BasicButton>
            </div>
            {incomingId == user?.id && (
            <div>
                <BasicButton type="button" onClick={deletePost} width={'100px'} background={'bg-[#FF4500]'}>
                Delete
                </BasicButton>
            </div>
            )}
        </div>
    </div>
    </>
  );
};

export default MediaBox;