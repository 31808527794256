import React from 'react'
import useSkeletons from '../../../hooks/useSkeletons';

const TitleAndContentSkeleton: React.FC<any> = (props) => {
    const count: any = (props.count) ? parseInt(props.count) : 1;
    const { SkeletonTextDiv } = useSkeletons();

    return (
        <>
            {
                [...Array(count)].map((item, index) => {
                    return (
                        <>
                            <SkeletonTextDiv width='100%' maxWidth='350px' height='10px' />
                            <SkeletonTextDiv width='100%' maxWidth='400px' height='13px' />
                        </>
                    )
                })
            }
        </>
    )
}
export default TitleAndContentSkeleton;
