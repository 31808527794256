import React, { useEffect, useRef, useState } from "react";
import { FaHeart, FaComment, FaShare, FaPause, FaPlay, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import NumberFormatter from "../../NumberFormater";
import ResponsiveImageRenderer from "../../MediaLibrary/ResponsiveImage";
import { Player } from "../../MediaLibrary/Player";
import '../../MediaLibrary/scss/main.scss';
import '../../MediaLibrary/index.css';
import MediaSkeleton from '../../loading/skeletons/MediaSkeleton';
import AvatarSkeleton from '../../loading/skeletons/AvatarSkeleton';
import useLikes from "../../../hooks/useLikes";
import { useNavigate } from "react-router-dom";
import SharePostModal from "../../Modals/ShareModal";
import useAllPosts from "../../../hooks/useAllPosts";
import useShareCounts from "../../../hooks/useShares";
import { useDispatch, useSelector } from "react-redux";
import { setPostDetails } from "../../../redux/features/feedSlice";

type IncomingProps = {
  src?: string;
  info?: any;
  isLoading?: any;
  userId?: any;
  caption?: any;
  postId?: any;
  likes?: any;
  totalComments?: any;
  totalLikes?: any;
};
const VideoPlayer: React.FC<IncomingProps> = (
  { isLoading, info, userId, postId, 
    likes, caption, totalComments, totalLikes, src 
  }
) => {

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const progressBarRef = useRef<HTMLDivElement | null>(null);
  const [url, setUrl] = useState<any>();
  const [type, setType] = useState<any>();
  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [ videoDuration, setVideoDuration ] = useState(0);
  const [ trimRange, setTrimRange ] = useState<[from: number, to: number]>([ 5, 10 ]);
  const [urlLow, setUrlLow] = useState('');
  const [urlMedium, setUrlMedium] = useState('');
  const [urlHigh, setUrlHigh] = useState('');
  const { addLike, removeLike, setIsLiked, isLiked, isLikeLoading } = useLikes(postId);
  const [modalOpen, setModalOpen] = useState(false);
  const [ isPlaying, setIsPlaying ] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [singlePost, setSinglePost] = useState<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state?.auth?.user);
  const { page, fetchPostById, fetchAllPosts} = useAllPosts(postId);

  const postDetails = useSelector((state: any) => state?.feed?.postDetails);

  useEffect(() => {
    const video = videoRef.current;
    const observerOptions = {
      threshold: 1.0, // Adjust threshold as needed
    };
    video&&playPause();

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            video?.play();
          } else {
            video?.pause();
          }
        });
      },
      observerOptions
    );

    if (video) {
      observer.observe(video);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const fetchPost = async () => {
    const response = await fetchPostById();
    const thePost = response?.data?.post;
    dispatch(setPostDetails(thePost));

    console.log('POST MENTAL', thePost);
    const usersWhoLiked = response?.data?.post?.usersWhoLiked;
    const ifCurrentUserLiked = usersWhoLiked?.find( 
      async (userThatLiked: any) => {
        return await userThatLiked?.user_id === user?.id;
      }
    );

    if (ifCurrentUserLiked) {
      setIsLiked(true);
    }
  }

  useEffect(() => {
    fetchPost();
  }, [user]);
  

  useEffect(() => {
    if (Array.isArray(src) && src.length > 0) {
      const urlsByType = src[0]; // Assuming src[0] contains type and URLs
      const { type, url } = urlsByType;
      const { low, medium, high } = url;

      setUrl(url);
      setType(type);
      setUrlLow(low);
      setUrlMedium(medium);
      setUrlHigh(high);
    }
  }, [src]);

  const playPause = async () => {
    if (!videoRef.current) return

    const video = videoRef?.current

    if (video && video?.paused) {
        video.currentTime = trimRange[0] / 1000
        await video?.play()
    }
    else {
        video && video?.pause()
    }
  }
  const toggleMute = () => {
    const video = videoRef.current;
    if (video) {
        video.muted = !video.muted;
        setIsMuted(video.muted);
    }
  };

  const videoReady = () => {
    setVideoDuration(videoRef.current!.duration * 1000)
    setTrimRange([0, videoRef.current!.duration * 1000])
  }

  const handleIndicatorMouseDown = () => {
    setIsDragging(true);
    videoRef.current?.pause();
  };

  const handleIndicatorMouseMove = (e: MouseEvent) => {
    if (!isDragging) return;

    const progressBar = progressBarRef.current;
    if (!progressBar) return;

    const rect = progressBar.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const progressPercentage = (offsetX / rect.width) * 100;

    setIndicatorPosition(progressPercentage);
  };

  const handleIndicatorMouseUp = () => {
    setIsDragging(false);
    if (!videoRef.current) return;

    const newTime = (indicatorPosition / 100) * (trimRange[1] - trimRange[0]) + trimRange[0];
    videoRef.current.currentTime = newTime / 1000;
    videoRef.current.play();
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleIndicatorMouseMove);
      window.addEventListener('mouseup', handleIndicatorMouseUp);
    } else {
      window.removeEventListener('mousemove', handleIndicatorMouseMove);
      window.removeEventListener('mouseup', handleIndicatorMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleIndicatorMouseMove);
      window.removeEventListener('mouseup', handleIndicatorMouseUp);
    };
  }, [isDragging]);

  const handleLike = async () => {
    // e.preventDefault();
    await addLike();
    if (!isLikeLoading) {
      fetchAllPosts(page);
    }
  };
  const handleUnLike = async () => {
    // e.preventDefault();
    await removeLike();
    if (!isLikeLoading) {
      fetchAllPosts(page);
    }
  };
  return (
    <div className="w-full max-w-[600px] flex-flex-col justify-center items-center">
      <SharePostModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        caption={caption}
      />
    <div className={`
        mb-10 flex flex-col bg-main  border rounded-[7px] 
        border-1 border-transparent py-3 xl:py-10 lg:py-10 md:py-10 
        justify-center items-center`}
    >
    {info}
    <div className="w-full flex flex-col min-h-fit justify-center items-end post-mobile md:flex-row">
      {isLoading ?
        <MediaSkeleton />
      :
        <div
          className={`
            video-player bg-black border rounded-[7px] border-1 
            border-transparent flex flex-col justify-center items-center 
            md:max-w-[450px] md:min-h-[590px] w-full min-h-[590px] md:mr-4 relative`}
        >
          {type === 'video' ?
            (<Player
              src={[
                {
                  quality: "Full HD",
                  url: urlHigh
                    // "https://cdn.glitch.me/cbf2cfb4-aa52-4a1f-a73c-461eef3d38e8/1080.mp4",
                },
                {
                  quality: 720,
                  url: urlMedium
                    // "https://cdn.glitch.me/cbf2cfb4-aa52-4a1f-a73c-461eef3d38e8/720.mp4",
                },
                {
                  quality: 480,
                  url: urlLow
                    // "https://cdn.glitch.me/cbf2cfb4-aa52-4a1f-a73c-461eef3d38e8/480.mp4?v=1647351419495",
                },
              ]}
              subtitles={[
                {
                  lang: "en",
                  language: "English",
                  url:
                    "https://cdn.jsdelivr.net/gh/naptestdev/video-examples@master/en.vtt",
                },
                {
                  lang: "fr",
                  language: "French",
                  url:
                    "https://cdn.jsdelivr.net/gh/naptestdev/video-examples@master/fr.vtt",
                },
              ]}
              minHeight={'590px'}
              // poster="https://cdn.jsdelivr.net/gh/naptestdev/video-examples@master/poster.png"
            />
          ):(
          <ResponsiveImageRenderer 
              // sizes="(max-width: 800px) 100vw, 50vw"
              // loading="lazy" 
              minHeight={'590px'}
              src={url} 
              alt="Upload media" 
              className="h-full w-full border rounded-[7px] border-1 border-boxes"
          />
          )}
        </div>
      }
      <div className={`
        flex flex-row lg:flex-col xl:flex-col md:flex-col h-[70px] 
        md:h-[25%] lg:h-[25%] xl:h-[25%] lg:justify-between xl:justify-between`
        }>
        
          {isLoading ? (<AvatarSkeleton /> 
            ): (
              <>
                {!isLiked ?
                  (<div className="flex relative cursor-pointer items-center space-x-1 mr-5 md:mr-0 lg:mr-0 xl:mr-0 md:mb-6 lg:mb-6 xl:mb-6">
                    <button onClick={handleLike} className="bg-boxes border border-1 border-transparent rounded-full p-2">
                      <FaHeart className="text-white text-2xl" />
                    </button>
                    <div className="w-5 h-5 font-medium bottom-10 left-6 rounded-full text-xs text-notification absolute">
                      <NumberFormatter number={totalLikes}/>
                    </div>
                  </div>
                  ):(
                    <div className="flex relative cursor-pointer items-center space-x-1 mr-5 md:mr-0 lg:mr-0 xl:mr-0 md:mb-6 lg:mb-6 xl:mb-6">
                    <button onClick={handleUnLike} className="bg-boxes border border-1 border-transparent rounded-full p-2">
                      <FaHeart className="text-[#FF4500] text-2xl" />
                    </button>
                    <div className="w-5 h-5 font-medium bottom-10 left-6 rounded-full text-xs text-notification absolute">
                      <NumberFormatter number={totalLikes}/>
                    </div>
                  </div>
                  )
                }
              </>
            )}
          {isLoading ? <AvatarSkeleton /> :
            <div className="flex relative cursor-pointer items-center mr-5 md:mr-0 lg:mr-0 xl:mr-0 space-x-1 md:mb-6 lg:mb-6 xl:mb-6">
              <button onClick={() => navigate(`/detail/${postId}`)} className="bg-boxes border border-1 border-transparent rounded-full p-2">
                <FaComment className="text-white text-2xl" />
              </button>
              <div className="w-5 h-5 font-medium bottom-10 left-6 rounded-full text-xs text-notification absolute">
                  <NumberFormatter number={totalComments}/>
              </div>
            </div>
          }
          {isLoading ? <AvatarSkeleton /> :
            <div className="flex relative cursor-pointer items-center mr-5 md:mr-0 lg:mr-0 xl:mr-0 space-x-1">
              <button className="bg-boxes border border-1 border-transparent rounded-full p-2">
                <FaShare onClick={() => setModalOpen(true)} className="text-white text-2xl" />
              </button>
              <div className="w-5 h-5 font-medium bottom-10 left-6 rounded-full text-xs text-notification absolute">
                  <NumberFormatter number={0}/>
              </div>
            </div>
          }
      </div>
    </div>
    </div>
    </div>
  );
};


export default VideoPlayer;
