import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import authReducer from './features/authSlice';
import channelReducer from './features/channelSlice';
import feedReducer from './features/feedSlice';
import shopReducer from './features/shopSlice';
import themeSlice from './features/themeSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  channel: channelReducer,
  theme: themeSlice,
  feed: feedReducer,
  shop: shopReducer
});

const persistConfig = {
  key: 'root',
  storage,
  // Specify the reducers you want to persist
  whitelist: ['auth', 'channel', 'theme', 'feed', 'shop'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer
});

export const persistor = persistStore(store);