import React, { FC, useEffect, useState } from "react";

import ClientRender from "./components/ClientRender";
import ErrorBoundary from "./components/ErrorBoundary";
import SecondMain from "./components/SecondMain";
// import { PlayerProps } from "./shared/types";

export const ThirdPlayer: FC<any> = props => {
  const [h, setH] = useState<any>('')
  const { dimensions, primaryColor } = props;

  useEffect(() => {
    setH(props?.minHeight);
  }, [])

  return (
    <div
      className="tuby border rounded-[7px] 
      border-1 border-transparent"
      style={{
        ...(typeof dimensions === "number"
          ? { width: "100%", height: '100%', minHeight: h, paddingBottom: `` }
          : typeof dimensions === "object"
          ? {
              width: dimensions.width,
              height: '100%', minHeight: h,
            }
          : { width: "100%", height: '100%', minHeight: h, paddingBottom: '' }),
        ...(primaryColor
          ? {
              ["--tuby-primary-color" as any]: primaryColor,
            }
          : {}),
      }}
    >
      <ClientRender>
        <ErrorBoundary renderer={props.children} {...props}>
          <SecondMain {...props} />
        </ErrorBoundary>
      </ClientRender>
    </div>
  );
};

// export { PlayerProps } from "./shared/types";
