import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { AiFillLock } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { HiUser, HiKey  } from 'react-icons/hi';

import BasicButton from "../../../components/buttons/BasicButton";
import PasswordInput from "../../../components/inputs/PasswordInput";
import { logOut, setUser } from "../../../redux/features/authSlice";
import { logIn } from "../../../services/authService";
import { getUserWithoutId } from "../../../services/userService";
import SocialLoginFactory from "../../../components/SocialLoginFactory";
import { FaArrowLeft } from "react-icons/fa";
import LoginBasicButton from "../../../components/buttons/LoginBasicButton";
import useAllUsers from "../../../hooks/useAllUsers";
import StripeModal from '../../../components/StripePayment';

type Props = {
  id?: string;
  email?: string;
  setIsFormOpen: Dispatch<SetStateAction<boolean>>;
  setLastId: Dispatch<SetStateAction<string>>;
}

const Relogin: FC<Props> = ({ id, email, setIsFormOpen, setLastId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [lastUser, setLastUser] = useState<any | null>(null);
  const [page, setPage] = useState(1);
  let { fetchUserById } = useAllUsers(id)

  useEffect(() => {
    const fetchUser = async () => {
      const result = await fetchUserById();
      const user = result?.data;
      setLastUser(user);
      console.log('Last user', user);
    }
    fetchUser();
  }, [id]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const password = await e.target.password.value;
      if (!password) return;
      const response = await logIn({ email, password });
  
      if ('status' in response && response.status === 200) {
        console.log('LOG USER', response)
        const token = response?.data?.data?.token;
        const user = response?.data?.data?.authenticated_user;
        toast.success(`${response?.data?.message} successful`, {
          duration: 5000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
        });
        // const decodedToken: unknown = jwtDecode(token);
        // Store the token and user data as needed
        Cookies.set('access_token', token as any, { expires: 3 });
        Cookies.set('last_user', user?.user_id);
        Cookies.set('user_email', user?.email);

        dispatch(setUser({
          username: user.username,
          email: user.email,
          id: user?.user_id,
          image: user?.avatar,
          firstname: user?.fname,
          lastname: user?.lname,
          role: user?.role,
          status: user?.status
        }));
        
        setIsFormOpen(false);
        setTimeout(() => {
          return navigate('/');
        }, 2000);
      } else if ('statusCode' in response && response.statusCode === '400') {
        // reset();
        toast.error(response.message, {
          duration: 3000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
        });
      } else if ('statusCode' in response && response.statusCode === '401') {
        toast.error(`${response.message}`, {
          duration: 5000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
        });
      }
    } catch (error) {
      console.log('Error:', error);
      toast.error('An error occurred. Please try again later.', {
        duration: 3000,
        position: 'top-center',
        style: {
          backgroundColor: '@apply bg-boxes',
          color: '@apply text-text'
        }
      });
    }
  };

  const handleClickChange = () => {
    Cookies.remove('access_token');
    Cookies.remove('last_user');
    dispatch(logOut());
    setLastId('');
  }

  const handleLoginWithSocials = () => {
    Cookies.remove('access_token');
    Cookies.remove('last_user');
    dispatch(logOut());
    setLastId('');
    setPage(1);
  }

  return (
    <div className="w-full">
        <form action="POST" className='flex flex-col justify-center items-center' onSubmit={handleSubmit}>
          {page === 1 ?
          <>
            <h1 className="text-xl ml-10 flex flex-col items-start justify-start">
              Welcome
            </h1>
            <div className="flex flex-row justify-start items-center w-[80%] items-center">
              {lastUser?.avatar != null ? (
                <LazyLoadImage
                  className="w-32 h-32 object-cover rounded-full cursor-pointer"
                  src={lastUser?.avatar}
                  alt="user-pp"
                />
              ) : (
                <div
                  className="w-10 h-10 flex flex-col text-center cursor-pointer items-center justify-center bg-gray-300 rounded-full text-4xl text-gray-600 font-bold"
                >
                  {lastUser?.username?.charAt(0).toUpperCase()}
                </div>
              )}
              <h1 className="text-xl my-5 ml-5 flex flex-col items-start justify-start">  
                <strong>{lastUser?.username}</strong>
              </h1>
            </div>
            </>
            :null}
          
          {page === 1 ? 
            <>
              <PasswordInput
                label='Password'
                placeholder='Type your password.'
                error={null}
                Icon={AiFillLock}
                refs={{ name: 'password' }}
              />
              <div className="relogin mx-auto w-[90%] md:w-[80%]">
                <BasicButton type='submit' >Login</BasicButton>
                {/* <BasicButton type='submit' onClick={handleLoginWithSocials} >Login With Socials</BasicButton> */}
              </div>
            </>
            :
            <div className="w-[90%]">
              <LoginBasicButton type='submit' onClick={() => setPage(1)} ><HiUser className="mr-[10px]" />Use only password</LoginBasicButton>
              {/* <SocialLoginFactory /> */}
            </div>
          }
        </form>
      <div className="w-full text-center mt-4">
        <button onClick={handleClickChange} className="text-lg text-text hover:text-boxes duration-200">Change Account</button>
        <Toaster />
      </div>
    </div>
  )
}

export default Relogin;