import { FC } from 'react'
import { IconType } from 'react-icons'
import Spinner from '../loading/Spinner';

type Props = {
    Icon: IconType;
    text?: string;
    type?: 'button' | 'submit' | 'reset';
    handleClick: VoidFunction;
    isTextCanClosed?: boolean;
}

const IconButton: FC<Props> = ({ Icon, text, type, handleClick, isTextCanClosed }) => {
    return (
        <button
            type={type}
            onClick={handleClick}
            className="flex flex-row relative w-[110px] cursor-pointer justify-between items-center space-x-1 mr-5 bg-boxes border border-1 border-transparent rounded-full p-3"
        >
            <>
                <Icon className='mr-2 text-md text-white' />
                <span className={`${isTextCanClosed ? 'hidden xl:inline' : 'inline'} text-white`}>{text}</span>
            </>
        </button>
    )
}

export default IconButton