import { ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import UploadModal from '../Upload';
import { IoMdAdd } from 'react-icons/io'

const Upload = () => {
  const [isUploadDialogOpen, setUploadDialogOpen] = useState(false);
  const navigate = useNavigate();
 
  
  return (
    <div>
      <button
        className="border-2 flex flex-row items-center border-white hover:border-notification hover:bg-green text-white hover:text-black font-semibold py-2 px-4 rounded-md disabled:opacity-50"
        onClick={() => setUploadDialogOpen(true)}
      >
        <IoMdAdd className="text-xl" />{" "}
        <span className="hidden md:block">Upload </span>
      </button>
      <UploadModal
        modalOpen={isUploadDialogOpen}
        setModalOpen={setUploadDialogOpen}
      />
    </div>
  );
};

export default Upload;
