import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

type FeedState = {
  posts: any[];
  postDetails: any;
  menuToggle: boolean;
  anyLoading: boolean;
  followings?: any;
  followers?: any;
  likes?: any;
  page: any;
};

var initialState: FeedState = { 
  posts: [], postDetails: {}, menuToggle: true, 
  anyLoading: false, page: 1, followings: [],
  followers: [], likes: {}
};

export const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    // addPosts: (state, action: PayloadAction<any>) => {
    //   if (state?.posts === undefined) {
    //     state.posts = [];
    //   }
    //   state.posts = action?.payload;
    //   console.log('FROM FEED SLICE', action?.payload)
    // },
    addPosts: (state, action: PayloadAction<any>) => {
      state.posts = action.payload
    },
    addPost: (state,action) => {
      state.posts = [...state.posts, ...action.payload.posts]
    },
    
    setPostDetails: (state, action: PayloadAction<any>) => {
      state.postDetails = action.payload;
    },

    setFollowing: (state, action: PayloadAction<any>) => {
      if (state) {
        state.followings = action.payload.followings;
      } else {
        console.error("user followings non-existent :(");
      }
    },
    setFollowers: (state, action: PayloadAction<any>) => {
      if (state) {
        state.followers = action.payload.followers;
      } else {
        console.error("user followers non-existent :(");
      }
    },
    setLikes: (state, action: PayloadAction<any>) => {
      if (state) {
        state.likes = action.payload.likes;
      } else {
        console.error("post likes non-existent :(");
      }
    },
    setMenuToggle: (state) => {
      state.menuToggle = !state.menuToggle;
    },
    
    setAnyLoading: (state) => {
      state.anyLoading = !state.anyLoading;
    },
    setPage: (state, action: PayloadAction<any>) => {
      state.page = action.payload;
    }
  }
});

export const { 
  addPosts, addPost,setPage, 
  setPostDetails, setFollowers,
  setMenuToggle, setAnyLoading , 
  setFollowing, setLikes
} = feedSlice.actions;
export default feedSlice.reducer;
