import { Routes, Route } from 'react-router-dom';
import AddFriend from '../pages/AddFriend';
import Channel from '../pages/Channel';
import Chat from '../pages/Chat';
import Create from '../pages/Create';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Profile from '../pages/Profile';
import ProfileEdit from '../pages/ProfileEdit';
import Register from '../pages/Register';
import Edit from '../pages/Edit';
import ProtectedRoute from './ProtectedRoute';
import UploadsFolder from '../pages/Uploads';
import WatchPage from '../pages/WatchPage';
import Password from '../pages/AccountSettings';
import FavoritesMovies from '../pages/Favourites';
import Detail from '../components/Details';
// import CheckoutPage from '../pages/CheckOut';
import SearchPage from '../pages/SearchPage'
import ResetPassword from '../pages/ResetPassword';
import AccountSettings from '../pages/AccountSettings';

const Index = () => {
    
    return (
        <Routes>
            <Route path='/' element={<ProtectedRoute/>}>
                <Route path='/' element={<Home />} />
                <Route path='/profile/:id' element={<Profile/>} />
                <Route path='/profile/settings' element={<AccountSettings/>} />
                <Route path='/create' element={<Create />} />
                <Route path='/addfriend' element={<AddFriend />} />
                <Route path='/chat' element={<Chat />} />
                <Route path='/channel' element={<Channel />} />
                <Route path='/edit' element={<Edit />} />
                <Route path='/detail/:id' element={<Detail />} />
                <Route path="/watch/:id" element={<WatchPage />} />
                <Route path="/posts" element={<SearchPage />} />
                <Route path='/shop' element={<UploadsFolder />} />
                <Route path='/favourites' element={<FavoritesMovies />} />
                <Route path='/password' element={<Password />} />
            </Route>
            <Route path='/password/reset' element={<ResetPassword />} />
            <Route path='/register' element={<Register />} />
            <Route path='/login' element={<Login />} />
        </Routes>
    )
}

export default Index;