import React, { useEffect, useState } from "react";
import { GoVerified } from "react-icons/go";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAllUsers from '../../hooks/useAllUsers';
import UsersSkeleton from "../loading/skeletons/UsersSkeleton";

const CurrentlyOnline: React.FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [shuffledUsers, setShuffledUsers] = useState<any[]>([]);
  const { isLoading, users } = useAllUsers()

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const shuffled = [...users].sort(() => 0.5 - Math.random());
        setShuffledUsers(shuffled);
        console.error('SHUFFLED:', shuffled);
      } catch (error) {
        console.error('Network error:', error);
      }
    };
    fetchAllUsers();
  }, []);
  
  return (
      <div className="border-lines pb-4">
        <p className="text-gray-500 font-semibold m-3 mt-4 text-left hidden lg:block xl:block">
          Currently Online
        </p>
        {isLoading ? 
        ( 
          <UsersSkeleton 
            count={3} 
          /> 
        )
        :
        <div>
          {Array.isArray(shuffledUsers) &&
            shuffledUsers?.slice(0, 6).map((user: any) => {
              return (<div
                onClick={() => navigate(`/profile/${user?.user_id}`)}
                key={`${user?.user_id}-${user?.username}`}
              >
                <div className="flex flex-row gap-3 hover:bg-primary p-2 cursor-pointer font-semibold rounded">
                  <div className="w-12 h-auto">
                    {user?.avatar ? (
                      <LazyLoadImage
                        src={user?.avatar}
                        alt="user-profile"
                        className="w-10 h-10 object-cover rounded-full cursor-pointer"
                      />
                    ) : (
                      <div className="w-10 h-10 object-cover rounded-full cursor-pointer flex flex-col text-center items-center bg-gray-300 text-4xl text-gray-600 font-bold">
                        {user?.username?.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>

                  <div className="hidden lg:block xl:block">
                      <p className="flex gap-1 items-center text-md font-bold text-text lowercase">
                        {user?.username?.replace(/\s+/g, "")}{" "}
                        {user?.is_premium &&<GoVerified className="text-[#FF4500]" />}
                      </p>
                    <p className="capitalize text-white text-xs">
                      @{user?.username?.toLowerCase()}
                    </p>
                  </div>
                </div>
              </div>
            )})}
        </div>}
      </div>
  );
};

export default CurrentlyOnline;
