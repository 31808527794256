import { FC } from "react";
import { toast, Toaster } from "react-hot-toast";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import TransparentFollowButton from "../../../../components/buttons/TransparentFollowButton";
import { GoVerified } from "react-icons/go";

type Props = {
    users?: any;
    isFollowing?: any;
    incomingId?: any;
    handleAdd?: any;
    handleRemove?: any;
    isFollowLoading?: any;
};

const AccountBox: FC<Props> = (
    { 
        isFollowing, incomingId, users, 
        handleAdd, isFollowLoading, handleRemove 
    }
) => {
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.auth.user)
    return (
        <>
            <div className="flex flex-row gap-3 w-full justify-start hover:bg-primary p-2 cursor-pointer font-semibold rounded-sm items-center">
                {users?.avatar ? (
                    <LazyLoadImage
                        onClick={() => navigate(`/profile/${users?.user_id}`)}
                        src={users?.avatar}
                        alt="user-profile"
                        className="w-10 h-10 object-cover rounded-full cursor-pointer"
                    />
                ) : (
                    <div 
                        onClick={() => navigate(`/profile/${users?.user_id}`)}
                        className="w-10 h-10 object-cover rounded-full cursor-pointer flex flex-col text-center items-center bg-gray-300 text-4xl text-gray-600 font-bold"
                    >
                    {users?.username?.charAt(0).toUpperCase()}
                    </div>
                )}
                <div 
                    onClick={() => navigate(`/profile/${users?.user_id}`)} 
                    className="hidden lg:block xl:block">
                    <p 
                       className="flex gap-1 items-center text-md font-bold text-text lowercase"
                    >
                    {user?.id === users?.user_id ? 'You' : users?.username?.replace(/\s+/g, "")}{" "}
                    {users?.is_premium &&<GoVerified className="text-[#FF4500]" />}
                    </p>
                    <p 
                        className="capitalize text-white text-xs"
                    >
                        {user?.id === users?.user_id ? 'You' : users?.username}
                    </p>
                </div>
                <div className="ml-10 flex flex-row items-center justify-center">
                    {user?.id != users?.user_id && 
                     
                        <>
                            {
                                isFollowing
                                    ?
                                    <>
                                        {/* <button
                                            onClick={handleClickMessage}
                                            className="font-semibold text-xl px-3 py-2 bg-neutral-700 hover:bg-neutral-600 duration-200 rounded-md mt-3 mr-3"
                                        >
                                            <BiMessageDots className="mx-auto text-3xl" />
                                        </button> */}
                                        <TransparentFollowButton
                                            isTextCanClosed
                                            // Icon={IoPersonRemoveSharp}
                                            text={'Following'}
                                            type="button"
                                            onClick={handleRemove}
                                            
                                        />
                                    </>
                                    :
                                    <TransparentFollowButton
                                        isTextCanClosed
                                        // Icon={IoPersonAddSharp}
                                        text={'Follow'}
                                        type="button"
                                        onClick={handleAdd}
                                        
                                    />
                            }
                        </>
                    }
                </div>
            </div>
            <Toaster />
        </>
    )
}

export default AccountBox;