import React, { ReactNode } from 'react'
import { IconType } from 'react-icons';
import Spinner from '../loading/Spinner';

type Props = {
  type?: 'button' | 'submit' | 'reset';
  onClick?: VoidFunction;
  disabled?: any;
  children?: ReactNode;
  text?: string;
  isTextCanClosed?: boolean;
}

const TransparentFollowButton: React.FC<Props> = ({ type = 'button', disabled, text, onClick, children }) => {
  return (
    <button type={type} onClick={onClick} disabled={disabled} className={`
      w-full max-w-[70px] flex justify-center items-center h-[30px] rounded-md p-2 bg-black mr-3
      text-xs text-[#FF4500] hover:text-white border border-1 border-[#FF4500] rounded-md duration-200`
    }>
      {text ? text : null}
      {children}
    </button>
  )
}

export default TransparentFollowButton;