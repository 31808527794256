import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Discover from '../../Discover'
import { getChannelsByUser } from '../../../services/channelService';
import ChannelBox from './ChannelBox';
import Searchbar from './Searchbar';
import UserBox from './UserBox'
import Spinner from '../../loading/Spinner';
import { getAllUser } from '../../../services/userService';
import { useDispatch } from 'react-redux';
import { setAllUsers } from '../../../redux/features/authSlice';

const Sidebar = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.auth.user);
    const { refresh } = useSelector((state: any) => state.channel);
    const [channels, setChannels] = useState<Channel[]>([]);
    const [isPending, setIsPending] = useState<boolean>(true);
    const [lastMessages, setLastMessages] = useState<Message[]>([]);
    const [search, setSearch] = useState<string>('');
    const [users, setUsers] = useState<any[]>([]);

    const menuToggle = useSelector((state: any) => state.feed.menuToggle);

    useEffect(() => {
        setIsPending(true);
        const fetchAllUsers = async () => {
            try {
                const result = await getAllUser();
                console.log('RESULT', result);
                const allUsers = result; // Directly use the result object
                console.log('ALL USERS', allUsers);
                setUsers((prevUsers: any) => [...prevUsers, allUsers]);
            } catch (error) {
                console.error(error);
            }
        }
        fetchAllUsers();
    }, []);

    return (
        <>
        {menuToggle && <aside id="autoflow"
            className={`bg-main max-w-[335px] lg:block hidden lg:col-span-2 xl:col-span-2 min-h-fit overflow-y-auto max-h-[100vh]
            xl:w-400 lg:w-400 md:w-30 sm:w-20 w-20 flex flex-col justify-start border-y border-1 border-lines px-3`}>
            <Searchbar setSearch={setSearch} />
            {/* <Discover /> */}
            <div className='overflow-x-hidden overflow-y-auto max-h-fit pb-16 '> 
                {/* <div className='flex items-center'>
                    <p className='text-gray-500 font-semibold m-3 mt-4 hidden lg:block xl:block'>
                        Suggested Teams
                    </p> */}
                    {/* <div className='max-h-16 xl:w-96 lg:w-80 md:w-52 sm:w-36 w-32 overflow-hidden'>
                        <p className='hidden md:block'>{name}</p>
                        <p className='md:hidden'>{name}</p>
                        {
                            participants && <span className='text-sm text-neutral-300 mr-1 font-semibold'>Participants: </span>
                        }
                        {
                            participants?.length! > 0
                            &&
                            participants?.map((participant) => {
                                return <span key={participant.id} className='text-sm text-neutral-300 mr-2'>{participant.username}</span>
                            })
                        }
                    </div> */}
                {/* </div> */}
                {
                    isPending
                        ?
                        <div className='mt-10'>
                            {/* <Spinner size='sm' /> */}
                        </div>
                        :
                        (
                            channels.length > 0
                                ?
                                channels.map((channel, index) => {
                                    return (
                                        <ChannelBox
                                            key={channel.id}
                                            channel={channel}
                                            userId={user?.id!}
                                            lastMessage={lastMessages[index]}
                                            search={search}
                                        />
                                    )
                                })
                                :
                            <p className='text-neutral-500 text-center mt-3'>Create a team now and start chatting.</p>
                        )
                }
            </div>
        </aside>
        }</>
    )
}

export default Sidebar;