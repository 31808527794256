import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ResponsiveImageRenderer: React.FC<any> = ({ src, alt, minHeight }) => {

  const imageStyle = {
    width: '100%',
    height: 'auto',
    maxHeight: minHeight,
    objectFit: 'contain' as 'contain', // Type assertion here
  };

  return (
    <LazyLoadImage
      src={src}
      sizes="(max-width: 800px) 100vw, 50vw"
      loading="lazy" 
      alt={alt}
      style={imageStyle}
    />
  );
};

export default ResponsiveImageRenderer;