import { FC, useState } from 'react';
import { IconType } from 'react-icons';
import { AiOutlineInfoCircle } from 'react-icons/ai';

type Props = {
  label: string;
  error: any;
  tooltipContent: string;
  refs: any;
  checked?: boolean;
  onClick?: any;
  style?: any;
  Icon: IconType;
}

const CheckInput: FC<Props> = (
  { 
    label, error, style, onClick, 
    checked, tooltipContent, refs, Icon 
  }
) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div className={`${error ? 'text-red-500' : 'text-white'} w-[90%] md:w-[80%] mx-auto m-5`}>
      <div className='flex items-center justify-between'> 
        <div className='flex items-center'>
          <input
            className='text-white w-4 h-4 text-lg bg-transparent p-2 outline-none placeholder-neutral-500'
            id={label.toLowerCase()}
            name={label.toLowerCase()}
            onClick={onClick}
            checked={checked}
            type='checkbox'
            {...refs}
            style={style}
          />
          <label style={style} className='capitalize ml-4 font-semibold' htmlFor={label.toLowerCase()}>
            {label}
          </label>
        </div>
        <div style={style} className='ml-2'>
          <AiOutlineInfoCircle className='text-neutral-400 cursor-pointer' onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip} />
          {showTooltip && (
            <div className='absolute bg-neutral-800 text-white p-2 mt-1 w-48'>
              {tooltipContent}
            </div>
          )}
        </div>
      </div>
      {error && (
        <div className={`flex w-[90%] justify-between mt-3 items-center transition-all duration-100`}>
          <div className='group'>
            <AiOutlineInfoCircle className='text-2xl' />
            <div className='absolute hidden group-hover:block bg-neutral-800 p-2 shadow-xl'>
              <p>* {tooltipContent}</p>
            </div>
          </div>
        </div>
      )}
      {error && <p className='text-red-500 mt-2'>* {error}</p>}
    </div>
  );
}

export default CheckInput;