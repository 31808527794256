import { FC } from "react";
import { FaArrowLeft, FaComment, FaHeart, FaUsers } from "react-icons/fa";
import { RxDotsVertical } from "react-icons/rx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setMenuToggle } from "../../../redux/features/feedSlice";
import UserBox from "../Sidebar/UserBox";
import { ImCancelCircle } from "react-icons/im";
import { AiFillHome, AiOutlineMenu } from "react-icons/ai";
import React from "react";
import NumberFormatter from "../../NumberFormater";
import NavigationSkeleton from "../../loading/skeletons/NavigationSkeleton";
import useFollowerStatus from "../../../hooks/useFollowerStatus";
import useAllUsers from "../../../hooks/useAllUsers";

type Props = {
  image?: string;
  name?: string;
  participants?: User[] | null;
  isChannel?: boolean;
  search?: any;
  upload?: any;
};

const BottomBar: React.FC<Props> = ({
  image,
  name,
  participants,
  isChannel,
  search,
  upload,
}) => {
  const menuToggle = useSelector((state: any) => state.feed.menuToggle);
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.user);

  const { 
    follower, following, 
    isFollower, isFollowing 
  } = useFollowerStatus(user?.id);

  const { isLoading } = useAllUsers();

  const handleSetToggle = () => {
    dispatch(setMenuToggle());
  };
  const navigate = useNavigate();
  const location = useLocation();

  const hover = "hover:text-subMain transitions text-white";
  const Hover = ({ isActive }: any) => (isActive ? "text-subMain" : hover);

  const activeLink =
    `${Hover} cursor-pointer flex items-center hover:bg-primary p-3 justify-center xl:justify-start cursor-pointer font-semibold text-text rounded`;

  const normalLink =
  `${Hover} cursor-pointer flex items-center hover:bg-primary p-3 justify-center xl:justify-start cursor-pointer font-semibold text-white rounded`;

  const handleClick = () => {
    if (location.pathname === "/chat")
      return navigate("/channel", {
        state: { channelId: location.state.channelId },
      });
    navigate("/chat", { state: { channelId: location.state.channelId } });
  };


  return (
    <div
      className={`md:hidden lg:hidden xl:hidden sticky bottom-0
        text-sm min-w-[300px] flex flex-row w-full left-0 right-0
        justify-between items-center px-5 w-inherit 
        z-50 bg-main border-b border-lines`
      }
    >
      <div className="w-full justify-between flex flex-row items-center">
        <div>
          {location.pathname === "/" ? null : (
            <FaArrowLeft
              className="w-6 h-6 text-text cursor-pointer"
              onClick={() => navigate(-1)}
            />
          )}
        </div>
        {isLoading ? <NavigationSkeleton /> :
          <div onClick={ () => navigate('/')} className="flex cursor-pointer items-center space-x-1">
            <div className={`${location.pathname === "/" ? activeLink : normalLink}`}>
              <AiFillHome className="w-6 h-6"  />
            </div>
          </div>
        }

        <div
          className="block text-text cursor-pointer m-2 ml-4 mt-3 text-xl"
          onClick={handleSetToggle}
        >
          {/* {menuToggle ? <ImCancelCircle className="text-text" /> : <AiOutlineMenu className="text-text" />} */}
          {isLoading ? <NavigationSkeleton /> :
            <div onClick={ () => navigate(`/profile/${user?.id}`)} className="relative cursor-pointer items-center space-x-1">
              <div className={location.pathname === `/profile/${user?.id}` ? activeLink : normalLink}>
                <FaUsers className="w-6 h-6" />
              </div>
              <div className="w-6 h-6 font-medium bottom-8 left-5 rounded-full text-xs text-notification absolute">
                <NumberFormatter number={follower?.length}/>
              </div>
            </div>
          }
        </div>
        <div>
          <LazyLoadImage
            src={
              "https://tekfall-home.s3.amazonaws.com/icon.png"
            }
            alt="logo-pp"
            effect="blur"
            className="w-auto hidden md:block lg:block xl:block h-10 mx-3 lg:mx-5"
          />
        </div>

        <>{upload ? upload : null}</>

        <div className={`${Hover} relative mx-5 cursor-pointer group`}>
          <FaHeart className="w-6 h-6  text-white" />
          <div className="w-5 h-5 flex-colo text-xs text-notification absolute -top-5 -right-1">
            <NumberFormatter number={100} />
          </div>
        </div>
        <UserBox />
      </div>
    </div>
  );
};

export default BottomBar;
