import React, { useEffect, useRef, useState } from "react";
import { HiVolumeUp, HiVolumeOff } from "react-icons/hi";
import { BsFillPlayFill, BsFillPauseFill } from "react-icons/bs";
import { GoVerified } from "react-icons/go";
import { BsPlay } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LazyLoad from "react-lazy-load";
import Spinner from "./loading/Spinner";
import { useDispatch } from "react-redux";
import { setPostDetails } from "../redux/features/feedSlice";
import Detect from 'detect.js';
import VideoPlayer from "./MediaFactory/components/VideoPlayer";
import FollowButton from "./buttons/FollowButton";
import { getAllUser } from "../services/userService";
import { useSelector } from "react-redux";
import useAllUsers from "../hooks/useAllUsers";
import CurrentlyOnlineSkeleton from "./loading/skeletons/UsersSkeleton";
import TitleAndContentSkeleton from "./loading/skeletons/TitleAndContentSkeleton";
import ButtonSkeleton from "./loading/skeletons/ButtonSkeleton";
import useBlockStatus from "../hooks/useBlockStatus";
import useFollowerStatus from "../hooks/useFollowerStatus";
import { toast } from "react-hot-toast";

interface IProps {
  post?: any;
  loadMoreRef?: any;
  hasMore?: any;
  loading?: any;
  isShowingOnHome?: boolean;
}

const MediaCard: React.FC<IProps> = ({
  post: { caption, content, author_id,
     media_items, post_id, entity_id, 
     entity_type, totalComments, totalLikes,  
  },
  isShowingOnHome,
  loadMoreRef,
  hasMore,
  loading
}) => {
  const [playing, setPlaying] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [user, setUser] = useState<any>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [expandedPosts, setExpandedPosts] = useState<string[]>([]);
  const currentUser = useSelector((state: any) => state.auth.user);

  const { users, isLoading, fetchUserById} = useAllUsers(author_id);
  
  const [incomingUser, setIncomingUser] = useState<any>(); // Change type to 'any'
  const { 
    isFollower, isFollowing, isFollowLoading, 
    fetchAllFollowings, removeFollower, addFollower
  } = useFollowerStatus(author_id);
  
  const { isBlocked, isBlockLoading,  addBlock, removeBlock } = useBlockStatus(author_id);

  const browser = new Detect();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onVideoPress = () => {
    if (playing) {
      videoRef.current?.pause();
      setPlaying(false);
    } else {
      videoRef.current?.play();
      setPlaying(true);
    }
  };

    //  Toggle Posts expansion
  const togglePostExpansion = (postId: string) => {
    setExpandedPosts((prevExpandedPosts) =>
      prevExpandedPosts.includes(postId)
        ? prevExpandedPosts.filter((id) => id !== postId)
        : [...prevExpandedPosts, postId]
    );
  };
  const navigateDetails = () => {
    let postDet = {
      caption,
      content, 
      author_id, 
      media_items, 
      post_id, 
      entity_id, 
      entity_type, 
      totalLikes, 
      totalComments, 
    }
    dispatch(setPostDetails(postDet));
    navigate(`/detail/${post_id}`);
  }
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isVideoMuted;
    }
    // console.log('FROM MEDIA CARD', media)
  }, [isVideoMuted]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const result = await fetchUserById();
        if ('data' in result) {
          console.log('RESULT', result);
          const user = result?.data;
          setUser(user);
        } else {
          console.error('API error:', result.message);
        }
      } catch (error) {
          console.error(error);
      }
  
    }
    fetchUser();
  }, []);

  const handleFollow = () => {
    try {
        addFollower()
        setTimeout(() => {
            toast.success(`${user?.username} followed.`, {
                duration: 3000,
                position: 'top-center',
                style: {
                    backgroundColor: '@apply bg-boxes',
                    color: '@apply text-text'
                }
            });
        }, 1000);
    }catch(error: any) {
        // Handle API request errors here
        console.error('API request error:', error);
        // You can also display an error toast or take other actions as needed.
    };
  };
  

const handleUnFollow = () => {
    try {
        removeFollower();
        setTimeout(() => {
            toast.success(`${user?.username} unfollowed.`, {
                duration: 3000,
                position: 'top-center',
                style: {
                    backgroundColor: '@apply bg-boxes',
                    color: '@apply text-text'
                }
            });
        }, 1000);
    }catch(error: any) {
        // Handle API request errors here
        console.error('API request error:', error);
        // You can also display an error toast or take other actions as needed.
    };
  };

  const userInfo = (
    <>
      <div key={author_id} className="md:min-h-fit flex flex-row justify-between items-start w-full min-h-fit relative">
        {isLoading ? 
          <div className="w-full"><CurrentlyOnlineSkeleton /></div> 
        :
          <div className="w-full flex flex-row justify-between items-center">
            <div className="flex gap-2 py-2 font-semibold rounded">
              <div onClick={() => navigate(`/profile/${user?.user_id}`)}>
                {user?.avatar ? (
                  <LazyLoadImage
                    className="rounded-full h-10 w-10"
                    src={user?.avatar}
                    alt="user-profile"
                  />
                ) : (
                  <div
                    onClick={() => navigate(`/profile/${user?.user_id}`)}
                    className="w-10 h-10 flex flex-col text-center cursor-pointer items-center justify-center bg-gray-300 rounded-full text-4xl text-gray-600 font-bold"
                  >
                    {user?.username?.charAt(0)?.toUpperCase()}
                  </div>
                )}
              </div>
              <div>
                <div onClick={() => navigate(`/profile/${user?.user_id}`)}>
                  <div className="flex flex-col items-start gap-2">
                    <p className="flex gap-2 items-center md:text-md font-bold text-text">
                      {user?.username}{" "}
                      {user?.is_premium &&<GoVerified className="text-[#FF4500] text-xl" />}
                    </p>
                    <p className="capitalize font-medium text-xs text-white hidden md:block">
                      @{user?.username?.toLowerCase()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {isLoading ? 
          <ButtonSkeleton />
        :
          <>
            {currentUser?.id != user?.user_id && (
              <>
                {isFollowing ?
                  (<FollowButton
                    isTextCanClosed
                    text={'Following'}
                    onClick={handleUnFollow}
                    type='button'
                  
                  />
                  ):(
                  <FollowButton
                    isTextCanClosed
                    text={'Follow'}
                    onClick={handleFollow}
                    type='button'
                  
                  />)
                }
              </>
            )}
          </>
        }
      </div>
      <div key={post_id} className=" min-h-fit w-full text-lef mb-1 items-start max-w-[500px]">
        {isLoading ? 
          <TitleAndContentSkeleton />
        :
          <>
            <div className="w-full text-left" onClick={navigateDetails}>
              <p className="mt-2 text-white hover:text-text">{caption}</p>
            </div>
            {content?.length > 50 && !expandedPosts.includes(post_id) ? (
              <p className="flex py-2 flex-row font-normal">
                <span className="text-text max-w-[380px]">{`${content?.slice(0, 50)}`}</span>
                <button className="text-white" onClick={() => togglePostExpansion(post_id)}>
                  ...See more
                </button>
              </p>
            ) : (
              <p className="py-2 font-normal text-text max-w-[380px]">
                <span>{`${content}`}</span>
              </p>
            )}
          </>
        }
      </div>
    </>
  );
  
  return (
    <div
      className="flex flex-col min-w-[320px] justify-center cursor-pointer items-center border-dryBlack pb-6"
    >
      <VideoPlayer 
        info={userInfo} 
        src={media_items} 
        isLoading={isLoading} 
        userId={author_id} 
        postId={post_id}
        caption={caption}
        totalLikes={totalLikes} 
        totalComments={totalComments} 
      />
    </div>
  );
};

export default MediaCard;
