import Cookies from "js-cookie";
import AllUploads from "../pages/Uploads/components/AllUploads";
import axiosFormDataWithAuth from "../utils/axiosFormDataWithAuth";
import axiosWithAuth from "../utils/axiosWithAuth";
import axios from "axios";

const token = Cookies.get('access_token');

export const getFile = async (id: string) => {
    try {
        const file = await axiosFormDataWithAuth.get(`/files/file/${id}`);
        console.log('File by id', file)
        return file.data.data;
    } catch (error) {
        // Handle error
        throw new Error('Failed to fetch user');
    }
}

export const getAllPosts = async (page: any) => {
  console.log('PAGEeerrrr', page)
    try {
        return await axiosWithAuth.get(`/posts?page=${page}`);
    } catch (error) {
        // Handle error
        throw new Error('Failed to fetch all uploads');
    }
}

export const getUploadsBySearch = async (search: string) => {
  try {
    const file = await axiosFormDataWithAuth.get(`/files/upload?search=${search}`);
    console.log('File by search', file.data.file)
    return file.data.file;
  } catch (error) {
    // Handle error
    throw new Error('Failed to fetch uploads by search');
  }
}


export const createPost = async (formData: any) => {
  try {
    return await axiosFormDataWithAuth.post(`/posts`, formData);
  } catch (error) {
    console.log('ERRROR', error);
    // Handle error
    throw new Error('Failed to create post');
  }
};
  

export const getPostLikesbyId = async (postId: any) => {
  const { data } = await axiosWithAuth.get(`/posts/${postId}/like`);
  return data;
}
export const setLike = async (postId: any) => {
  const { data } = await axiosWithAuth.post(`/posts/${postId}/like`);
  return data;
}

export const unLike = async (postId: any) => {
  const { data } = await axiosWithAuth.delete(`/posts/${postId}/like`);
  return data;
}

export const postComments = async (comment: any, postId: any) => {
  console.log('THE COMMENT', comment)
  try {
    return await axiosWithAuth.post(
      `/posts/${postId}/comment`, {content: comment}
    );
  } catch (error) {
    console.log('ERRROR', error);
    // Handle error
    throw new Error('Failed to create post');
  }
};

export const getAllComments = async (postId: any) => {
  try {
    const  { data } = await axiosWithAuth.get(`/posts/${postId}/comment`);
    return data;
  } catch (error) {
    console.log('ERRROR', error);
    // Handle error
    throw new Error('Failed to create post');
  }
};

export const deleteComments = async (postId: any) => {
  try {
    const { data } = await axiosWithAuth.post(`/posts/${postId}/comment`);
    return data;
  } catch (error) {
    console.log('ERRROR', error);
    // Handle error
    throw new Error('Failed to create post');
  }
};

export const getPostDetails = async (postID: any) => {
  return await axiosWithAuth.get(`/posts/${postID}`);
};

export const deletePostDetails = async (postID: any) => {
  return await axiosWithAuth.delete(`/posts/${postID}`);
};

export const getCommentsByPostId = async (postId: any) => {
  return await axiosWithAuth.get(`/comments/comment/${postId}`);
};

export const uploadFile = async (formData: FormData) => {
  try {
    return await axiosFormDataWithAuth.post(`/file/upload`, formData);
  } catch (error) {
    // Handle error
    throw new Error('Failed to create file');
  }
};
  


export const updateFile = async (id: string, file: any) => {
    try {
        const newFile = await axiosFormDataWithAuth.get(`/files/file/${id}`, file);
        console.log('File', newFile)
        return newFile.data.data;
    } catch (error) {
        // Handle error
        throw new Error('Failed to fetch user');
    }
}

export const deleteFile = async (id: string) => {
    try {
        const file = await axiosFormDataWithAuth.get(`/files/file/${id}`);
        console.log('File', file)
        return file.data.data;
    } catch (error) {
        // Handle error
        throw new Error('Failed to fetch user');
    }
}