import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import NoResults from "../../../../components/NoResults";
import useFollowerStatus from "../../../../hooks/useFollowerStatus";
import useBlockStatus from "../../../../hooks/useBlockStatus";
import toast from "react-hot-toast";
import AccountBox from "./AccountBox";

const AccountTab = ({ relatedUsers }: any) => {
  const users = useSelector(
    (state: any) => state.auth.allUsers
  );

  const [foundAccountId, setFoundAccountId] = useState<any>()
  const location = useLocation();

  useEffect(() => {
    // Find the first user with a user_id in relatedUsers
    const foundUser = relatedUsers?.find(
      (userFound: any) => userFound.user_id
    );
    
    setFoundAccountId(foundUser?.user_id || null);
  }, [relatedUsers]);

  const {
    isFollowing, isFollowLoading, 
    addFollower, removeFollower 
  } = useFollowerStatus(foundAccountId);

  const handleAdd = () => {
      addFollower();
      return toast.success('Follower added successfully.', {
          duration: 5000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
      });
  }

  const handleRemove = () => {
      removeFollower();
      return toast.success('Follower removed successfully.', {
          duration: 5000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
      });
  };

  return (
    <div>
      {
        (relatedUsers && relatedUsers.length > 0)
        ?
        relatedUsers.map((users: any) => {
          return (
            <AccountBox 
              key={users?.user_id} 
              incomingId={users?.user_id} 
              users={users} 
              isFollowLoading={isFollowLoading}
              isFollowing={isFollowing}
              handleAdd={handleAdd}
              handleRemove={handleRemove}
            />
          )
        })
      : <NoResults text={`No Related Accounts`} />}
    </div>
  )
}

export default AccountTab;