import React from 'react';
import ReactDOM from 'react-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DemoText from "./DemoText";
import CheckoutForm from "./PaymentForm";
import api from "./api";
import MainModal from '../Modals/MainModal';

const PUBLIC_KEY = "pk_test_51JIEiiHnXkLhdfIMoOMgw3SHio8AwgZGBNI3VvxNu4UQeTqLQP9NcP4PBx6dTzsrqqKV25lwwmOrWbrttyn24JAd00YUT4heob"

const stripePromise = loadStripe(PUBLIC_KEY)

export default function StripeModal({ isOpen, onClose }: any) {
  return (
    <MainModal modalOpen={isOpen} setModalOpen={onClose}>
      <div className="bg-boxes border-2 border-boxes border p-8 rounded-lg">
        <Elements stripe={stripePromise}>
            <CheckoutForm onClose={onClose}/>
        </Elements>
        <DemoText />
      </div>
    </MainModal>
  );
};