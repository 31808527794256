import { FC, useContext } from "react";
import { FaArrowLeft, FaComment, FaHeart } from "react-icons/fa";
import { RxDotsVertical } from "react-icons/rx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setMenuToggle } from "../../../redux/features/feedSlice";
import UserBox from "../Sidebar/UserBox";
import { ImCancelCircle } from "react-icons/im";
import { AiOutlineMenu } from "react-icons/ai";
import React from "react";
import NumberFormatter from "../../NumberFormater";
import MenuBox from "../Sidebar/MenuBox";
import { ThemeModeContext } from "../../../services/themeService";
import HorizontalLoader from '../../loading/HorizontalLoader'

import { logOut } from "../../../redux/features/authSlice";

type Props = {
  image?: string;
  name?: string;
  participants?: User[] | null;
  isChannel?: boolean;
  search?: any;
  upload?: any;
};

const NaveBar: React.FC<Props> = ({
  image,
  name,
  participants,
  isChannel,
  search,
  upload,
}) => {
  const menuToggle = useSelector((state: any) => state.feed.menuToggle);
  const dispatch = useDispatch();
  const userProfile = useSelector((state: any) => state.auth.user);
  const anyLoading = useSelector((state: any) => state.feed.anyLoading);
  const { themeMode, handleThemeToggle } = useContext(ThemeModeContext);

  const handleSetToggle = () => {
    dispatch(setMenuToggle());
  };
  const navigate = useNavigate();
  const location = useLocation();

  const hover = "hover:text-subMain transitions text-white";
  const Hover = ({ isActive }: any) => (isActive ? "text-subMain" : hover);

  const handleClick = () => {
    if (location.pathname === "/chat")
      return navigate("/channel", {
        state: { channelId: location.state.channelId },
      });
    navigate("/chat", { state: { channelId: location.state.channelId } });
  };

  console.log('ANY LOADING', anyLoading)
  
  const desktopBlackLogo = 'https://tekfall-home.s3.amazonaws.com/digiyo_word__1_-removebg-preview.png';
  const desktopWhiteLogo = 'https://tekfall-home.s3.amazonaws.com/image.png';

  return (
    <>
    <div
      className={`
        text-sm min-w-[300px] flex flex-row w-full 
        justify-between items-center px-5 w-inherit 
        z-50 bg-main border-b border-lines py-2 md:py-0 lg:py-0 xl:py-0`
      }
    >
      <div className="w-4/12 flex flex-row items-center">
        <div
          className="lg:block hidden text-text cursor-pointer m-2 ml-4 mt-3 text-xl"
          onClick={handleSetToggle}
        >
          {menuToggle ? <ImCancelCircle className="text-text" /> : <AiOutlineMenu className="text-text" />}
        </div>
          <a href="https://tekfallsupreme.com" target="_blank">
            <LazyLoadImage
              src={
                themeMode === 'dark' ? desktopWhiteLogo : desktopBlackLogo
              }
              alt="logo-pp"
              effect="blur"
              className="w-[18%] hidden md:block lg:block xl:block mx-auto lg:mx-5"
            />
            <LazyLoadImage
              src={
                "https://tekfall-home.s3.amazonaws.com/icon.png"
              }
              alt="logo-pp"
              effect="blur"
              className="w-auto block md:hidden lg:hidden xl:hidden h-10 lg:mx-5"
            />
          </a>
        </div>

      <div className="w-4/12 min-w-[200px]">{search ? search : null}</div>
      <div className="w-4/12 flex flex-row items-center justify-end">

        <div className="hidden md:block lg:block xl:block">{upload ? upload : null}</div>

        <div className={`${Hover} relative hidden md:block lg:block xl:block mx-5 cursor-pointer group`}>
          <FaHeart className="w-6 h-6  text-white" />
          <div className="w-5 h-5 flex-colo text-xs text-notification absolute -top-5 -right-1">
            <NumberFormatter number={2} />
          </div>
        </div>

        {/* <div className={`${Hover} relative mx-5 cursor-pointer group`}>
          <FaComment className="w-6 h-6 text-white" />
          <div className="w-5 h-5 flex-colo text-xs text-notification absolute -top-5 -right-1">
            <NumberFormatter number={100} />
          </div>
        </div> */}
        {/* {
            (isChannel && participants !== null)
            &&
            <div className='ml-auto cursor-pointer group'>
                <RxDotsVertical onClick={handleClick} />
            </div>
        } */}
        <div className="hidden md:block lg:block xl:block">
          <UserBox />
        </div>
        <MenuBox />
      </div>
    </div>
    {anyLoading &&<HorizontalLoader />}
    </>
  );
};

export default NaveBar;
