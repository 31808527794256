import React from "react";
import { FC, useState, useTransition } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import Spinner from '../../components/loading/Spinner'
import ProfileEdit from "../ProfileEdit";
import ChangePassword from "../ChangePassword";
import ChangeEmail from "../ChangeEmail";

type Props = {
    searchId? : any,
}

const AccountSettings: FC<Props> = ({ searchId }) => {
    const user = useSelector((state: any) => state.auth.user);
    const [tab, setTab] = useState('editprofile');
    const [isPending, startTransition] = useTransition();

    const location = useLocation();
  
    // Get the search query from the location.search property
    const searchQuery = new URLSearchParams(location.search).get('q');

    const handleClickEditProfile = () => {
        startTransition(() => {
            setTab('editprofile');
        });
    };

    const handleClickChangePassword = () => {
        startTransition(() => {
            setTab('changepassword');
        });
    };

    const handleClickChanegeEmail = () => {
        startTransition(() => {
            setTab('changeemail');
        });
    };

    return (
        <div className="max-w-[800px] mx-auto pb-10">
            <div className="flex justify-around">
                <span
                    onClick={handleClickEditProfile}
                    className={`
                        text-md py-3 text-text cursor-pointer w-full text-center duration-200 transition-all border-b 
                        ${tab === 'editprofile' ? 'border-b-2 font-semibold' : 'hover:border-white border-neutral-700'}
                    `}
                >
                    Edit Profile
                </span>
                {/* {
                    user?.id === searchId
                    && */}
                    <>
                        <span
                            onClick={handleClickChangePassword}
                            className={`
                                text-md py-3 text-text cursor-pointer w-full text-center duration-200 transition-all border-b 
                                ${tab === 'passwordchange' ? 'border-b-2 font-semibold' : 'hover:border-white border-neutral-700'}
                            `}
                        >
                            Change Password
                        </span>
                        {/* <span
                            onClick={handleClickChanegeEmail}
                            className={`
                                text-md py-3 text-text cursor-pointer w-full text-center duration-200 transition-all border-b 
                                ${tab === 'changeemail' ? 'border-b-2 font-semibold' : 'hover:border-white border-neutral-700'}
                            `}
                        >
                            Change Email
                        </span> */}
                    </>
                {/* } */}
            </div>
            <div>
                {
                    isPending
                        ?
                        <div className="mt-10">
                            <Spinner size="lg" />
                        </div>
                        :
                        <>
                            {
                                tab === 'editprofile' && <ProfileEdit />
                            }
                            {
                                tab === 'changepassword' && <ChangePassword />
                            }
                            {
                                tab === 'changeemail' && <ChangeEmail />
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default AccountSettings;