
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAllUsers } from '../redux/features/authSlice';
import { getAllUser, getUserById } from '../services/userService';
import { AxiosResponse } from 'axios';

type IProps = {
  userId?: any;
};

type UseAllUsersReturnType = {
    isLoading: boolean | undefined;
    users: any;
    fetchUserById: () => Promise<any>;
    fetchAllUsers: () => Promise<any>;
};

const useAllUsers = (userId?: any): UseAllUsersReturnType => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const dispatch = useDispatch();
  const users = useSelector((state: any) => state?.auth?.allUsers);

  const fetchAllUsers = async () => {
    setIsLoading(true);
    try {
      const result = await getAllUser();
      if ('data' in result) {
        const allUsers = result?.data?.data;
        dispatch(setAllUsers(allUsers));
      } else {
        console.error('API error:', result.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchUserById = async () => {
    setIsLoading(true);
    try {  
        return await getUserById(userId);
    } catch (error) {
        console.log(
            error
        )
    } finally { 
      setIsLoading(false);
    }
  };

  return { isLoading, users, fetchAllUsers, fetchUserById };
};

export default useAllUsers;