import { useEffect } from 'react';
import Layout from './components/layout';
import { store } from './redux/store';
import Routes from './routes';
import { useSelector } from 'react-redux';



const App = () => {
  
  const themeMode = useSelector((state: any) => state.theme.mode);
  
  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--color-main', themeMode === 'dark' ? '#000000' : '#FFFFFF');
    root.style.setProperty('--color-boxes', themeMode === 'dark' ? '#262626' : '#EEEEEE');
    root.style.setProperty('--color-subMain', themeMode === 'dark' ? '#F20000' : '#F20000');
    root.style.setProperty('--color-dry', themeMode === 'dark' ? '#0B0F29' : '#FAFAFA');
    root.style.setProperty('--color-star', themeMode === 'dark' ? '#FFB000' : '#FFB000');
    root.style.setProperty('--color-text', themeMode === 'dark' ? '#FFFFFF' : '#000000');
    root.style.setProperty('--color-border', themeMode === 'dark' ? '#4b5563' : '#D1D5DB');
    root.style.setProperty('--color-dryGray', themeMode === 'dark' ? '#555555' : '#EEEEEE');
    root.style.setProperty('--color-dryBlack', themeMode === 'dark' ? '#404040' : '#404040');
    root.style.setProperty('--color-green', themeMode === 'dark' ? '#FF4500' : '#FFFFFF');
    root.style.setProperty('--color-white', themeMode === 'dark' ? '#FFFFFF' : '#808080');
    root.style.setProperty('--color-notification', themeMode === 'dark' ? '#FF4500' : '#000000');
    root.style.setProperty('--color-lines', themeMode === 'dark' ? '#202020' : '#EEEEEE');
    root.style.setProperty('--color-button', themeMode === 'dark' ? '#404040' : '#FF4500');
    root.style.setProperty('--color-buttonHover', themeMode === 'dark' ? '#525252' : '#38fc38');
    root.style.setProperty('--color-mediaNFields', themeMode === 'dark' ? '#171717' : '#808080');
    // ... set other variables ...
  }, [themeMode]);  

  console.log('app mode', themeMode)
  return (
    <Layout>
        <Routes />
    </Layout>
  );
}

export default App;
