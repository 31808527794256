import { useEffect, useState } from 'react';
import { 
    getAllBlocked, setBlock, setUnBlock 
} from '../services/userService';

const useBlockStatus = (incomingId: string) => {
    const [isBlockLoading, setIsBlockLoading] = useState<boolean>(false);
    const [isBlocked, setIsBlocked] = useState<boolean>(false);
    const [blockedUsers, setBlockedUsers] = useState<any>();

    useEffect(() => {
        const fetchAllBlocked = async () => {
            try {
                const result = await getAllBlocked();
                const allBlocked = result;
                setBlockedUsers(allBlocked);
                const blockedUser = allBlocked.find(
                    (user: any) => user?.user_id === incomingId
                );
                
                if ( blockedUser ) {
                    setIsBlocked(true);
                } else {
                    setIsBlocked(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchAllBlocked();
    }, [incomingId]);

    const addBlock = async () => {
        setIsBlockLoading(true);
        try {  
            await setBlock(incomingId);
            setIsBlocked(true);
        } catch (error) {
            console.log(
                error
            )
        } finally { 
            setIsBlockLoading(false);
        }
    };

    const removeBlock = async () => {
        setIsBlockLoading(true);
        try {  
            await setUnBlock(incomingId);
            setIsBlocked(false);
        } catch (error) {
            console.log(
                error
            )
        } finally { 
            setIsBlockLoading(false);
        }
    }

    return { 
        isBlocked, isBlockLoading, 
        blockedUsers, addBlock, removeBlock 
    };
}

export default useBlockStatus;