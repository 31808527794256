import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import axios from "axios"
import React, { useState } from 'react'
import './index.scss'
import Spinner from "../loading/Spinner";
import { useDispatch } from "react-redux";
import { setIsPaymentSuccessful } from "../../redux/features/authSlice";
import { useNavigate } from "react-router-dom";
import { joinFoundersClub } from "../../services/userService";
import toast from "react-hot-toast";

interface StripeCardElementOptions {
  iconStyle?: 'solid' | 'default';
  style?: {
      base?: {
          iconColor?: string;
          color?: string;
          fontWeight?: number;
          fontFamily?: string;
          fontSize?: string;
          fontSmoothing?: string;
          ':-webkit-autofill'?: {
            color?: string;
        };
          '::placeholder'?: {
              color?: string;
          };
      };
      invalid?: {
          iconColor?: string;
          color?: string;
      };
  };
}


const CARD_OPTIONS: StripeCardElementOptions  = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#c4f0ff",
			color: "#fff",
			fontWeight: 500,
			fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": { color: "#fce883" },
			"::placeholder": { color: "#87bbfd" }
		},
		invalid: {
			iconColor: "#ffc7ee",
			color: "#ffc7ee"
		}
	}
}

export default function PaymentForm({ onClose }: any) {
    const [success, setSuccess ] = useState(false)
    const [error, setError] = useState<any | null>(null);
    const [processing, setProcessing] = useState(false);
    const stripe = useStripe()
    const elements = useElements()
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleSubmit = async (e: any) => {
      e.preventDefault();
      setProcessing(true);
      if (!stripe || !elements) {
          console.error('Stripe or elements not available');
          return;
      }

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
          console.error('CardElement not found');
          return;
      }

      const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement
      });


      if (error) {
        setError(`Payment failed: ${error?.message}`);
        setProcessing(false);
        console.log("[error]", error);
      } 

    if(!error) {
        try {
            const { id } = paymentMethod;
            const email = 'ajiboduraphael@gmail.com';
            const intent_id = id;
            const res = await joinFoundersClub(intent_id, email);
            console.log('RESPONSE FROM PAYMENT', res.status);
          
            // const response = await axios.post("http://localhost:3200/checkout/payment", {
            //   amount: 1000,
            //   id
            // });
          
            if (res?.status === 'successfull') {
              console.log("Successful payment");
              toast.success(`Payment ${res?.status}`, {
                duration: 3000,
                position: 'top-center',
                style: {
                  backgroundColor: '@apply bg-boxes',
                  color: '@apply text-text'
                }
              });
          
              // Check if the action is dispatched
              console.log("Before dispatch");
          
              // Dispatch the action
              dispatch(setIsPaymentSuccessful());
          
              // Check if the action was dispatched successfully
              console.log("After dispatch");
          
              setSuccess(true);
              setTimeout(() => {
                setProcessing(false);
              }, 5000);
            }
          } catch (error) {
            console.log("Error", error);
          } finally {
            setProcessing(false);
          }
          
    } else {
        console.log(error.message)
    }
}

  const Login = () => {
    onClose();
    navigate('/login');
  }
    return (
        <>
        {!success ? 
        <form onSubmit={handleSubmit}>
          <h1 className="mb-2 mt-2 text-xl text-text font-bold">
           {'$29.99'}
         </h1>
         <h4 className="mt-3 mb-3 text-notification">Pre-order and Join Founders Club</h4>
            <fieldset className="payment-form-group">
                <div className="payment-form-row">
                    <CardElement options={CARD_OPTIONS}/>
                </div>
            </fieldset>
            
            {error && <div className="text-red-500 mt-2">{error}</div>}
             <button
                id="payment-button"
                type="submit"
                className="bg-[#FF4500] text-black cursor-pointer font-semibold px-4 py-2 rounded-lg mt-4 transition duration-300 hover:bg-green-400"
                disabled={processing}
            >
                {processing ? (
                    <div className="flex flex-row justify-center w-full items-center">
                        <Spinner size="sm" />
                    </div>
                ) : (
                    'Pay'
                )}
            </button>
        </form>
        :
       <div>
           <h2>
            <b className="font-bold text-[#FF4500]">Congrats</b>, 
            you just joined DigiYo Founders Club, this is the best decision of your life.
          </h2>
          <button
                id="payment-button"
                type="submit"
                className="bg-[#FF4500] text-black cursor-pointer font-semibold px-4 py-2 rounded-lg mt-4 transition duration-300 hover:bg-green-400"
                onClick={Login}
            >
                Login page
            </button>
       </div> 
        }
            
        </>
    )
}