import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useSelector } from 'react-redux'

import PageInfo from '../../components/layout/ContentArea/PageInfo'
import { useEffect, useRef, useState } from 'react'
import { deleteAccount, getUserWithoutId, updateUser, uploadUserImage } from '../../services/userService'
import BasicButton from '../../components/buttons/BasicButton'
import { toast, Toaster } from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { setUser } from '../../redux/features/authSlice'
import TextInput from '../../components/inputs/TextInput'
import { resendOTP, verifyAccount } from '../../services/authService'
import OTPVerifyModal from '../../components/Modals/OTPModal'
import { useNavigate } from 'react-router-dom'


const getFileNameWithoutExtension = (fileName: any) => {
    const extensionIndex = fileName?.lastIndexOf('.');
    if (extensionIndex !== -1) {
      return fileName?.substring(0, extensionIndex);
    }
    return fileName;
};
  
const formatFileName = (fileName: any) => {
    return fileName?.replace(/_/g, ' ');
};


const ProfileEdit = () => {
    const user = useSelector((state: any) => state.auth.user);
    const [details, setDetails] = useState<any>();
    const [image, setImage] = useState<any>(null);
    const [photo, setPhoto] = useState<any>(null);
    const [name, setName] = useState<string>('');
    const [OTPModal, setOTPModal] = useState<boolean>(false);
    const [loggedUser, setLoggedUser] = useState<any>();
    const [otpCountdown, setOtpCountdown] = useState(60);
    const [loading, setLoading] = useState<boolean>(false);
    const [update, setUpdate] = useState<boolean>(false);
    const [remove, setRemove] = useState<boolean>(false);
    const inputRef = useRef<any>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputValues, setInputValues] = useState({
        image: '',
        bio: user?.bio || '',
        username: user?.username || '',
        firstname: user?.firstname || '',
        lastname: user?.lastname || '',
        email: user?.email || '',
        otp: ''
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setInputValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
    };

    useEffect(() => {
        const fetchUser = async () => {
            setLoggedUser( await user);
        };
        if ( otpCountdown === 0 ) {
            setLoading(false);
        }
        fetchUser();
    }, [user?.id]);

    useEffect(() => {
        console.log('USER', user);
        console.log('LOGGED USER', loggedUser);
        console.log('IMAGE', image);
        const countdownInterval = setInterval(() => {
            setOtpCountdown((prevCountdown: any) =>
                prevCountdown > 0 ? prevCountdown - 1 : 0
            );
            }, 1000);
            console.log('OTPModal boolean', OTPModal)
            return () => clearInterval(countdownInterval);
    }, []);

    const handleProfileDelete = async (e: any) => {
        e.preventDefault();
        const { 
            otp
        } = inputValues;
        
        const userEmail = user?.email;
        const verifyResponse = await verifyAccount({email: userEmail, otp}); 
        console.log('VERIFY OTP', verifyResponse)

        if ('status' in verifyResponse && verifyResponse.status === 200) {
            toast.success('OTP Verified', {
                duration: 5000,
                position: 'top-center',
                style: {
                backgroundColor: '@apply bg-boxes',
                color: '@apply text-text'
                }
            });
            setLoading(true);
            const response = await deleteAccount();
            
            console.log('DELETED USER', response)
            if ('statusText' in response && response.statusText === 'OK') {
                dispatch(setUser(response?.data))
                toast.success(response?.data.message, {
                    duration: 3000,
                    position: 'bottom-center',
                    style: {
                    backgroundColor: '@apply bg-boxes',
                    color: '@apply text-text'
                    }
                });
                setTimeout(() => {
                    setOTPModal(false);
                    setLoading(false);
                    navigate('/register');
                }, 2000)
            }

            if ('statusCode' in response && response.statusCode === '401') {
                // reset();
                setLoading(false);
                toast.error('Network error, OTP not resent!', {
                    duration: 5000,
                    position: 'top-center',
                    style: {
                        backgroundColor: '@apply bg-boxes',
                        color: '@apply text-text'
                    }
                });
            }
            if ('statusCode' in response && response.statusCode === '409') {
                // reset();
                
                setLoading(false);
                toast.error('Network error, OTP not resent!', {
                duration: 5000,
                position: 'top-center',
                style: {
                    backgroundColor: '@apply bg-boxes',
                    color: '@apply text-text'
                }
                });
            }
        }
        if ('statusCode' in verifyResponse && verifyResponse.statusCode === '401') {
            // reset();
            setLoading(false);
            toast.error('Network error, OTP not resent!', {
                duration: 5000,
                position: 'top-center',
                style: {
                    backgroundColor: '@apply bg-boxes',
                    color: '@apply text-text'
                }
            });
        }
        if ('statusCode' in verifyResponse && verifyResponse.statusCode === '409') {
            // reset();
            
            setLoading(false);
            toast.error('Network error, OTP not resent!', {
            duration: 5000,
            position: 'top-center',
            style: {
                backgroundColor: '@apply bg-boxes',
                color: '@apply text-text'
            }
            });
        }
    };

    const handleProfileSubmit = async (e: any) => {
        e.preventDefault();
        const { 
            username, 
            firstname, lastname, 
            email, otp, bio
        } = inputValues;
        
        const formData = new FormData();
        formData.append('username', username);
        formData.append('fname', firstname);
        formData.append('lname', lastname);
        formData.append('age', '0')
        // formData.append('bio', bio);
        formData.append('email', email);
        if (image) {
            formData.append('media', image);
        } 
        if (!username) return;

        
        const userEmail = user?.email;
        const verifyResponse = await verifyAccount({email: userEmail, otp}); 
        console.log('UPDATED USER', verifyResponse)

        try {
            if ('status' in verifyResponse && verifyResponse.status === 200) {
                toast.success('OTP Verified', {
                    duration: 3000,
                    position: 'top-center',
                    style: {
                    backgroundColor: '@apply bg-boxes',
                    color: '@apply text-text'
                    }
                });
                setLoading(true);
                const response = await updateUser(formData);
                
                console.log('UPDATED USER', response)
                if ('statusText' in response && response.statusText === 'OK') {
                    dispatch(setUser(response?.data))
                    toast.success(response?.data.message, {
                        duration: 3000,
                        position: 'bottom-center',
                        style: {
                        backgroundColor: '@apply bg-boxes',
                        color: '@apply text-text'
                        }
                    });
                    setTimeout(() => {
                        setOTPModal(false);
                        setLoading(false);
                        navigate('/profile');
                    }, 2000)
                }

                if ('statusCode' in response && response.statusCode === '401') {
                    // reset();
                    setLoading(false);
                    toast.error('Network error, OTP not resent!', {
                        duration: 5000,
                        position: 'top-center',
                        style: {
                            backgroundColor: '@apply bg-boxes',
                            color: '@apply text-text'
                        }
                    });
                }
                if ('statusCode' in response && response.statusCode === '409') {
                    // reset();
                    
                    setLoading(false);
                    toast.error('Network error, OTP not resent!', {
                    duration: 5000,
                    position: 'top-center',
                    style: {
                        backgroundColor: '@apply bg-boxes',
                        color: '@apply text-text'
                    }
                    });
                }
            }
            if ('statusCode' in verifyResponse && verifyResponse.statusCode === '401') {
                // reset();
                setLoading(false);
                toast.error('Network error, OTP not resent!', {
                    duration: 5000,
                    position: 'top-center',
                    style: {
                        backgroundColor: '@apply bg-boxes',
                        color: '@apply text-text'
                    }
                });
            }
            if ('statusCode' in verifyResponse && verifyResponse.statusCode === '409') {
                // reset();
                
                setLoading(false);
                toast.error('Network error, OTP not resent!', {
                duration: 5000,
                position: 'top-center',
                style: {
                    backgroundColor: '@apply bg-boxes',
                    color: '@apply text-text'
                }
                });
            }
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                toast.error('Network catch error, Account not updated!', {
                    duration: 5000,
                    position: 'top-center',
                    style: {
                        backgroundColor: '@apply bg-boxes',
                        color: '@apply text-text'
                    }
                });
            }, 3000)
        } finally {
            setLoading(false);
            setOTPModal(false);
        }
    };

    const handleResendOTP = async () => {
        const email = user?.email;
        const response = await resendOTP({email});
        console.log('status', response);
        if ('status' in response && response.status === 200) {
            setOtpCountdown(60);
            toast.success(response?.data?.message, {
                duration: 5000,
                position: 'top-center',
                style: {
                backgroundColor: '@apply bg-boxes',
                color: '@apply text-text'
                }
            });
        }
        if ('statusCode' in response && response.statusCode === '401') {
            // reset();
            toast.error('Network error, OTP not resent!', {
            duration: 5000,
            position: 'top-center',
            style: {
                backgroundColor: '@apply bg-boxes',
                color: '@apply text-text'
            }
            });
        }
        if ('statusCode' in response && response.statusCode === '409') {
            // reset();
            toast.error('Network error, OTP not resent!', {
            duration: 5000,
            position: 'top-center',
            style: {
                backgroundColor: '@apply bg-boxes',
                color: '@apply text-text'
            }
            });
        }
    }
    const onSubmit = async (e: any) => {
        setUpdate(true);
        e.preventDefault();
        if (OTPModal) {
            handleProfileSubmit(e);
        } else {
            
        console.log('handleForm is called 2');
            setOTPModal(true);
            handleResendOTP();
        }
    };

    const onDelete = async (e: any) => {
        e.preventDefault();
        setRemove(true);
        if (OTPModal) {
            handleProfileDelete(e);
        } else {
            setOTPModal(true);
            handleResendOTP();
        }
    };

    const handleClick = () => {
        inputRef.current.click();
    };

    const handlePictureChange = async (e: any) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile) {
            setImage(selectedFile);
            setPhoto(URL.createObjectURL(selectedFile));
        }
    };

    const fileName = getFileNameWithoutExtension(photo);
    const formattedFileName = formatFileName(fileName);

    return (
        <section>
            <form className='w-full flex flex-col items-center justify-around py-5' name='form'
                onSubmit={onSubmit} action='POST'>
                <div className='flex md:flex-row flex-col'>
                    <LazyLoadImage
                        className='rounded-full w-32 h-32 object-cover cursor-pointer'
                        onClick={handleClick}
                        src={image ? photo : user?.image}
                        alt='user'
                        effect='blur'
                    />
                    <div className='md:pl-5 flex flex-col justify-center md:mt-0 mt-5'>
                        <p className='md:text-start text-center text-white'>{formattedFileName ? formattedFileName.slice(0, 20) + '...' : 'No image selected.'}</p>
                        <button
                            className='bg-main font-medium transitions hover:bg-[#FF4500] border border-[#FF4500] text-white py-3 px-6 rounded w-full sm:w-auto'
                            onClick={handleClick}
                            type='button'
                        >
                            Change Image
                        </button>
                    </div>
                    <input onChange={handlePictureChange} ref={inputRef} type="file" name="image" hidden accept='image/png, image/jpeg' />
                </div>
                <div className='mt-10 flex flex-col md:w-[400px]'>
                    <input
                        minLength={5}
                        maxLength={20}
                        placeholder={user?.username}
                        className='w-full p-3 mt-2 border bg-boxes text-text border-border rounded'
                        type="text"
                        name='username'
                        // defaultValue={user?.username}
                        value={inputValues.username}
                        onChange={handleChange}
                    />
                    <input
                        minLength={5}
                        maxLength={20}
                        placeholder={user?.firstname}
                        className='w-full p-3 mt-2 border bg-boxes text-text border-border rounded'
                        type="text"
                        name='firstname'
                        // defaultValue={user?.firstname}
                        value={inputValues.firstname}
                        onChange={handleChange}
                    />
                    <input
                        minLength={5}
                        maxLength={20}
                        placeholder={user?.lastname}
                        className='w-full p-3 mt-2 border bg-boxes text-text border-border rounded'
                        type="text"
                        name='lastname'
                        // defaultValue={user?.lastname}
                        value={inputValues.lastname}
                        onChange={handleChange}
                    />
                    <input
                        minLength={5}
                        maxLength={40}
                        placeholder={user?.email}
                        className='w-full p-3 mt-2 bg-boxes text-text border border-border rounded'
                        type="text"
                        name='email'
                        // defaultValue={user?.email}
                        value={inputValues.email}
                        onChange={handleChange}
                    />
                    <textarea
                        spellCheck={false}
                        maxLength={250}
                        placeholder={user?.bio ? user?.bio : 'Write bio yourself...'}
                        className='w-full h-20 mt-2 p-6 bg-boxes text-text border border-border rounded'
                        name="bio"
                        cols={30}
                        rows={10}
                        // defaultValue={user?.bio}
                        value={inputValues.bio}
                        onChange={handleChange}
                    />

                    {OTPModal && (
                        <OTPVerifyModal modalOpen={OTPModal} setModalOpen={setOTPModal}>
                            <div className='w-[90%] md:w-[90%] mx-auto'>
                                <input
                                    minLength={6}
                                    maxLength={6}
                                    placeholder='Type your OTP.'
                                    type='number'
                                    name='otp'
                                    value={inputValues.otp}
                                    onChange={handleChange}
                                    className='pl-3 text-white text-md bg-main p-2 w-full outline-none border border-1 rounded-md border-boxes placeholder-neutral-500'
                                />
                                <div className='flex flex-row mb-5 flex-wrap items-center justify-between text-sm mx-auto'>
                                    <p>Resend OTP in {otpCountdown} seconds</p>
                                    <button type="button" className='text-notification' onClick={handleResendOTP}>Resend OTP</button>
                                </div>
                                {update && (
                                    <BasicButton
                                        type='submit'
                                        onClick={onSubmit}
                                    >
                                        {loading ? 'Verifying...' : 'Verify'}
                                    </BasicButton>
                                )}

                                {remove && (
                                        <BasicButton
                                        type='submit'
                                        onClick={onDelete}
                                >
                                        {loading ? 'Deleting...' : 'Delete'}
                                    </BasicButton>
                                )}
                            </div>
                        </OTPVerifyModal>
                    )}
                    {/* <BasicButton type='submit'>Save</BasicButton> */}
                    <div className="flex gap-2 flex-wrap flex-col sm:flex-row justify-between items-center my-4">
                        <button 
                            type='submit' className="bg-main font-medium transitions hover:bg-[#FF4500] border border-[#FF4500] text-white py-3 px-6 rounded w-full sm:w-auto"
                        >
                            Update Profile
                        </button>
                        <button 
                            type='button' onClick={onDelete} 
                            className="bg-main font-medium transitions hover:bg-[#FF4500] border border-[#FF4500] text-white py-3 px-6 rounded w-full sm:w-auto"
                        >
                            Delete Account
                        </button>
                    </div>
                </div>
            </form>
            <Toaster />
        </section>
    )
}

export default ProfileEdit;