import React, { ReactNode } from "react";
import MainModal from "./MainModal";

type IProps = {
  modalOpen?: boolean,
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>,
  children?: ReactNode
};

const OTPVerifyModal: React.FC<IProps> = ({ modalOpen, setModalOpen, children }) => {
  return (
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="inline-block sm:w-4/5 border border-border md:w-3/5 lg:w-2/5 w-full align-middle p-10 overflow-y-auto h-full bg-main text-white rounded-2xl">
        <h2 className="text-2xl">
          OTP
        </h2>
        {children}
      </div>
    </MainModal>
  );
}

export default OTPVerifyModal;