import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { getRequests } from '../../../../services/userService';
import MediaBox from './PostsBox';
import { useSelector } from 'react-redux';
import { getAllPosts } from '../../../../services/postService';

const PostsTab: React.FC<any> = ({incomingId, relatedPosts}: any) => {
  
  return (
    <div className='flex flex-row justify-center items-center flex-wrap '>
      {
        (relatedPosts && relatedPosts?.length > 0)
        &&
        relatedPosts?.map((post: any) => {
          return <MediaBox fileAssets={post} incomingId={incomingId} />
        })
      }
    </div>
  )
}

export default PostsTab