import { useForm } from 'react-hook-form';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdMail } from 'react-icons/md';
import { HiKey  } from 'react-icons/hi';
import { AiFillLock } from 'react-icons/ai';
import toast, { Toaster } from 'react-hot-toast';

import PasswordInput from '../../../components/inputs/PasswordInput';
import TextInput from '../../../components/inputs/TextInput';
import BasicButton from '../../../components/buttons/BasicButton';
import { resendOTP, resetPassword } from '../../../services/authService';
import Terms from '../../Register/components/Terms';
import Cookies from 'js-cookie';
// import { response } from 'express';
import CheckInput from '../../../components/inputs/CheckInput';
import { FaArrowLeft } from 'react-icons/fa';

enum RegistrationPage {
  Page1,
  Page2
}

const ResetPasswordForm: React.FC<any> = ({ handlePage }: any) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<RegistrationPage>(
    RegistrationPage.Page1
  );
  const [unAuthorizedUser, setUnAuthorizedUser] = useState<any>({})

  const [otpCountdown, setOtpCountdown] = useState(60);

  useEffect(() => {
    if (currentPage === RegistrationPage.Page2) {
      const countdownInterval = setInterval(() => {
        setOtpCountdown((prevCountdown: any) =>
          prevCountdown > 0 ? prevCountdown - 1 : 0
        );
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [currentPage]);

  const handleResendOTP = async () => {
    const email = unAuthorizedUser?.email;
    const response = await resendOTP({ email });
    console.log('status', response);
    if ('status' in response && response.status === 200) {
      setOtpCountdown(60);
      toast.success(response?.data?.message, {
        duration: 5000,
        position: 'top-center',
        style: {
          backgroundColor: '@apply bg-boxes',
          color: '@apply text-text'
        }
      });
      if ('statusCode' in response && response.statusCode === '401') {
        // reset();
        toast.error('Network error, OTP not resent!', {
          duration: 5000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
        });
      }
      if ('statusCode' in response && response.statusCode === '409') {
        // reset();
        toast.error('Network error, OTP not resent!', {
          duration: 5000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
        });
      }
    }
    // Logic to resend OTP and refresh the countdown
     // Reset countdown to 60 seconds
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { 
      errors, isValid, isDirty, 
      isLoading, isSubmitSuccessful, 
      isSubmitted, isSubmitting, isValidating 
    }
  } = useForm();

  const onSubmit = async (data: any) => {
    try {
      // Handle form submission based on the current page
      if (currentPage === RegistrationPage.Page2) {
        // Handle Page 3 submission (create account)
        const response = await resetPassword(data);
        console.log('status', response);
        if ('status' in response) {
        toast.success(response?.status, {
          duration: 5000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
        });
          handlePage();
        }
    
        if ('statusCode' in response && response.statusCode === '401') {
          // reset();
          toast.error(response?.message, {
            duration: 5000,
            position: 'top-center',
            style: {
              backgroundColor: '@apply bg-boxes',
              color: '@apply text-text'
            }
          });
        }
        if ('statusCode' in response && response.statusCode === '409') {
          // reset();
          toast.error(response?.message, {
            duration: 5000,
            position: 'top-center',
            style: {
              backgroundColor: '@apply bg-boxes',
              color: '@apply text-text'
            }
          });
        }
      } else if (currentPage === RegistrationPage.Page1) {
        const response = await resendOTP(data);
        setUnAuthorizedUser(data);
        console.log('status', response);
        if ('status' in response && response.status === 200) {
          setOtpCountdown(60);
          toast.success(response?.data?.message, {
            duration: 5000,
            position: 'top-center',
            style: {
              backgroundColor: '@apply bg-boxes',
              color: '@apply text-text'
            }
          });
          handleNextPage();
        }
        if ('statusCode' in response && response.statusCode === '401') {
          // reset();
          toast.error('Network error, OTP not resent!', {
            duration: 5000,
            position: 'top-center',
            style: {
              backgroundColor: '@apply bg-boxes',
              color: '@apply text-text'
            }
          });
        }
        if ('statusCode' in response && response.statusCode === '409') {
          // reset();
          toast.error('Network error, OTP not resent!', {
            duration: 5000,
            position: 'top-center',
            style: {
              backgroundColor: '@apply bg-boxes',
              color: '@apply text-text'
            }
          });
        }
      }}catch (error) {
      console.log('Error:', error);
      // reset();
      toast.error('An error occurred. Please try again later.', {
        duration: 3000,
        position: 'top-center',
        style: {
          backgroundColor: '@apply bg-boxes',
              color: '@apply text-text'
        }
      });
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  }
  const renderPage = () => {
    switch (currentPage) {
      case RegistrationPage.Page1:
        return (
          <div>
            <TextInput
              label='Email'
              placeholder='Type your email.'
              Icon={MdMail}
              informations={['Email is required.', 'Email must be valid.']}
              error={errors.email && errors.email.message}
              refs={{
                ...register('email', {
                  required: 'Email is required.',
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email.'
                  }
                })
              }}
            />
            <div className='w-[90%] md:w-[80%] mx-auto'>
              <BasicButton type="submit" disabled={!isValid} >Continue</BasicButton>
            </div>
          </div>
        );
      case RegistrationPage.Page2:
        return (
              <div>
                <TextInput
                  label='Email'
                  placeholder='Type your email.'
                  Icon={MdMail}
                  informations={['Email is required.', 'Email must be valid.']}
                  error={errors.email && errors.email.message}
                  refs={{
                  ...register('email', {
                    required: 'Email is required.',
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'Please enter a valid email.'
                    }
                  })
                }}
              />
              <PasswordInput
                label='Password'
                placeholder='Type your password.'
                error={errors.password && errors.password.message}
                Icon={AiFillLock}
                refs={{
                  ...register('password', {
                    required: 'Password is required',
                    minLength: {
                      value: 6,
                      message: 'Password must be min 6 characters.'
                    },
                    maxLength: {
                      value: 32,
                      message: 'Password must be max 32 characters.'
                    }
                  })
                }}
              />
            <TextInput
              label='OTP'
              minLength={6}
              maxLength={6}
              placeholder='Type your OTP.'
              Icon={HiKey}
              error={errors.otp && errors.otp.message}
              informations={['OTP must be min 6.', 'OTP must be max 6.']}
              refs={{
                ...register('otp', {
                  required: 'OTP is required.',
                  minLength: {
                    value: 3,
                    message: 'OTP must be min 6 characters.'
                  },
                  maxLength: {
                    value: 20,
                    message: 'OTP must be max 6 characters.'
                  }
                })
              }}
            />
            <div className='flex flex-row flex-wrap items-center justify-between text-sm w-[90%] md:w-[80%] mx-auto'>
              <p>Resend OTP in {otpCountdown} seconds</p>
              <button type="button" className='text-notification' onClick={handleResendOTP}>Resend OTP</button>
            </div>
            <div className='w-[90%] md:w-[80%] mx-auto'>
              <Terms />
              <BasicButton
                type='submit'
                disabled={!isValid}
              >
                Reset Password
              </BasicButton>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  
  return (
    <form
      method='POST'
      name='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      {currentPage !== 0 ? <FaArrowLeft className='ml-9 text-green cursor-pointer' onClick={handlePrevPage} /> : null }
      {renderPage()}
      <Toaster />
    </form>
  );
};

export default ResetPasswordForm;