// import { FC } from 'react'

// type Props = {
//     size: 'sm' | 'lg';
// }

// const Spinner: FC<Props> = ({ size }) => {
//     return (
//         <div className='w-full flex items-center justify-center'>
//             <div
//                 className={`
//                     animate-spin inline-block border-[5px] border-current border-t-transparent text-cyan-500 rounded-full 
//                     ${size === 'sm' ? 'h-6 w-6' : 'w-12 h-12 '}
//                 `}
//             />
//         </div>
//     )
// }

// export default Spinner

import { FC } from 'react'

type Props = {
    size: 'sm' | 'lg';
}

const Spinner: FC<Props> = ({ size }) => {
    return (
        <div className='w-full flex items-center justify-center'>
            <div
                className={`
                    animate-spin inline-block rounded-full 
                    ${size === 'sm' ? 'h-6 w-6' : 'w-12 h-12 '}
                `}
                style={{
                    backgroundImage: 'linear-gradient(45deg, #FF0000, #00FF00)', // Bright red and green colors
                    backgroundClip: 'border-box',
                    border: '5px solid transparent',
                    borderTop: '5px solid transparent',
                    borderRadius: '50%',
                }}
            />
        </div>
    )
}

export default Spinner;

// import React, { FC, useEffect, useState } from 'react';

// type Props = {
//   size: 'sm' | 'lg';
// };

// const Spinner: FC<Props> = ({ size }) => {
//   const [circleColors, setCircleColors] = useState(['#00FF00', '#00FF00', '#00FF00', '#FF0000', '#FF0000', '#FF0000']);
//   const circleRadius = 4;

//   useEffect(() => {
//     const animationInterval = setInterval(() => {
//       // Shift the colors to create a moving effect
//       const shiftedColors = [...circleColors];
//       const firstColor: any = shiftedColors.shift();
//       shiftedColors.push(firstColor);
//       setCircleColors(shiftedColors);
//     }, 1000); // 1 second for each color change

//     return () => {
//       clearInterval(animationInterval);
//     };
//   }, [circleColors]);

//   return (
//     <div className="w-full flex items-center justify-center">
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         width={size === 'sm' ? '68' : '126'}
//         height={size === 'sm' ? '68' : '126'}
//         viewBox="0 0 100 100"
//         style={{
//           fill: 'transparent',
//         }}
//       >
//         {circleColors.map((color, index) => (
//           <circle
//             key={index}
//             cx={25 + (index % 3) * 20}
//             cy={25 + Math.floor(index / 3) * 20}
//             r={circleRadius}
//             fill={color}
//             className={`dice-circle dice-circle-${index}`}
//           />
//         ))}
//       </svg>
//     </div>
//   );
// };

// export default Spinner;


