import { useForm } from 'react-hook-form';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdMail } from 'react-icons/md';
import { HiUser, HiKey  } from 'react-icons/hi';
import { AiFillLock } from 'react-icons/ai';
import toast, { Toaster } from 'react-hot-toast';

import PasswordInput from '../../../components/inputs/PasswordInput';
import TextInput from '../../../components/inputs/TextInput';
import BasicButton from '../../../components/buttons/BasicButton';
import { createAccount, resendOTP, verifyAccount } from '../../../services/authService';
import Terms from './Terms';
import Cookies from 'js-cookie';
// import { response } from 'express';
import CheckInput from '../../../components/inputs/CheckInput';
import { FaArrowLeft } from 'react-icons/fa';
import StripeModal from '../../../components/StripePayment';
import { useSelector } from 'react-redux';
import LoginBasicButton from '../../../components/buttons/LoginBasicButton';
import SocialLoginFactory from '../../../components/SocialLoginFactory';

type Props = {
  setIsFormOpen: Dispatch<SetStateAction<boolean>>;
}
enum RegistrationPage {
  Page1,
  Page2,
  Page3,
}

const RegisterForm: FC<Props> = ({ setIsFormOpen }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<RegistrationPage>(
    RegistrationPage.Page1
  );
  
  const [signUpTypePage, setSignUpTypePage] = useState<any>(1);
  const [unAuthorizedUser, setUnAuthorizedUser] = useState<any>({})
  const isPaymentSuccessful = useSelector((state: any) => state?.auth?.isPaymentSuccessful);
  const [otpCountdown, setOtpCountdown] = useState(60);
  const [open, setOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(false);

  const {
    register,
    reset,
    handleSubmit,
    formState: {
      errors,
      isValid,
      isDirty,
      isLoading,
      isSubmitSuccessful,
      isSubmitted,
      isSubmitting,
      isValidating,
    },
  } = useForm();
  
  useEffect(() => {
    if (currentPage === RegistrationPage.Page2) {
      const countdownInterval = setInterval(() => {
        setOtpCountdown((prevCountdown: any) =>
          prevCountdown > 0 ? prevCountdown - 1 : 0
        );
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [currentPage]);

  const handleResendOTP = async () => {
    const email = unAuthorizedUser.email;
    const response = await resendOTP({email});
    console.log('status', response);
    if ('status' in response && response.status === 200) {
      setOtpCountdown(60);
      toast.success(response?.data?.message, {
        duration: 5000,
        position: 'top-center',
        style: {
          backgroundColor: '@apply bg-boxes',
          color: '@apply text-text'
        }
      });
      if ('statusCode' in response && response.statusCode === '401') {
        // reset();
        toast.error('Network error, OTP not resent!', {
          duration: 5000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
        });
      }
      if ('statusCode' in response && response.statusCode === '409') {
        // reset();
        toast.error('Network error, OTP not resent!', {
          duration: 5000,
          position: 'top-center',
          style: {
            backgroundColor: '@apply bg-boxes',
            color: '@apply text-text'
          }
        });
      }
    }
    // Logic to resend OTP and refresh the countdown
     // Reset countdown to 60 seconds
  };

  const onSubmit = async (data: any) => {
    try {
      // Handle form submission based on the current page
      if (currentPage === RegistrationPage.Page3) {
        // Handle Page 3 submission (create account)
          setTimeout(() => {
            Cookies.remove('last_user');
            setIsFormOpen(false);
            return navigate('/login');
          }, 2000);

        } else if (currentPage === RegistrationPage.Page2) {
        // Handle Page 3 submission (create account)
        const response = await verifyAccount(data);
        console.log('status', response);
    
        if ('status' in response && response.status === 200) {
          toast.success(response?.data?.message, {
            duration: 5000,
            position: 'top-center',
            style: {
              backgroundColor: '@apply bg-boxes',
              color: '@apply text-text'
            }
          });
          setTimeout(() => {
            // Cookies.remove('last_user');
            // setIsFormOpen(false);
            // return navigate('/login');
            handleNextPage();
          }, 2000);
        }
    
        if ('statusCode' in response && response.statusCode === '401') {
          // reset();
          toast.error(response?.message, {
            duration: 5000,
            position: 'top-center',
            style: {
              backgroundColor: '@apply bg-boxes',
              color: '@apply text-text'
            }
          });
        }
        if ('statusCode' in response && response.statusCode === '409') {
          // reset();
          toast.error(response?.message, {
            duration: 5000,
            position: 'top-center',
            style: {
              backgroundColor: '@apply bg-boxes',
              color: '@apply text-text'
            }
          });
        }
      } else if (currentPage === RegistrationPage.Page1) {
        
        // data.age = 45;
        // Handle Page 3 submission (create account)
        const response = await createAccount(data);
        console.log('status', response);
        if ('status' in response && response.status === 201) {
          if ('data' in response) {
            setUnAuthorizedUser(response?.data?.data);
            Cookies.remove('last_user');
            const userEmail = response?.data?.email;
            toast.success(`An OTP message has been sent to ${userEmail}`, {
              duration: 5000,
              position: 'top-center',
              style: {
                backgroundColor: '@apply bg-boxes',
                color: '@apply text-text'
              }
            });
            setTimeout(() => {
              handleNextPage();
            }, 2000);
          }
        }
    
        if ('statusCode' in response && response.statusCode === '401') {
          // reset();
          toast.error(response?.message, {
            duration: 5000,
            position: 'top-center',
            style: {
              backgroundColor: '@apply bg-boxes',
              color: '@apply text-text'
            }
          });
        }
        if ('statusCode' in response && response.statusCode === '409') {
          // reset();
          toast.error(response?.message, {
            duration: 5000,
            position: 'top-center',
            style: {
              backgroundColor: '@apply bg-boxes',
              color: '@apply text-text'
            }
          });
        }
      } else {
        handleNextPage();
      }
    } catch (error) {
      console.log('Error:', error);
      // reset();
      toast.error('An error occurred. Please try again later.', {
        duration: 3000,
        position: 'top-center',
        style: {
          backgroundColor: '@apply bg-boxes',
              color: '@apply text-text'
        }
      });
    }
  };

  const handleCheckboxChange = (event: any) => {
    setIsChecked(!isChecked);
    console.log('ISCHECKED', isChecked)
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  }

  const renderPage = () => {
    switch (currentPage) {
      case RegistrationPage.Page1:
        return (
          <div>
            <TextInput
              label='Username'
              minLength={3}
              maxLength={20}
              placeholder='Type your username.'
              Icon={HiUser}
              error={errors.username && errors.username.message}
              informations={['username must be min 5.', 'username must be max 20.']}
              refs={{
                ...register('username', {
                  required: 'username is required.',
                  minLength: {
                    value: 3,
                    message: 'username must be min 3 characters.'
                  },
                  maxLength: {
                    value: 20,
                    message: 'username must be max 20 characters.'
                  }
                })
              }}
            />
            <TextInput
              label='Email'
              minLength={10}
              maxLength={50}
              placeholder='Type your email.'
              Icon={MdMail}
              informations={['Email is required.', 'Email must be valid.']}
              error={errors.email && errors.email.message}
              refs={{
                ...register('email', {
                  required: 'Email is required.',
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email.'
                  }
                })
              }}
            />
            <PasswordInput
              label='Password'
              minLength={6}
              maxLength={32}
              placeholder='Type your password.'
              error={errors.password && errors.password.message}
              Icon={AiFillLock}
              refs={{
                ...register('password', {
                  required: 'Password is required',
                  minLength: {
                    value: 6,
                    message: 'Password must be min 6 characters.'
                  },
                  maxLength: {
                    value: 32,
                    message: 'Password must be max 32 characters.'
                  }
                })
              }}
            />
            <div className='w-[90%] md:w-[80%] mx-auto'>
              <BasicButton type="submit" disabled={!isValid} >Continue</BasicButton>
            </div>
          </div>
        );
      case RegistrationPage.Page2:
        return (
          <div>
            <TextInput
              label='OTP'
              minLength={6}
              maxLength={7}
              placeholder='Type your OTP.'
              Icon={HiKey}
              error={errors.otp && errors.otp.message}
              informations={['OTP must be min 6.', 'OTP must be max 7.']}
              refs={{
                ...register('otp', {
                  required: 'OTP is required.',
                  minLength: {
                    value: 6,
                    message: 'OTP must be min 6 characters.'
                  },
                  maxLength: {
                    value: 7,
                    message: 'OTP must be max 7 characters.'
                  }
                })
              }}
            />
            <div className='flex flex-row flex-wrap items-center justify-between text-sm w-[90%] md:w-[80%] mx-auto'>
              <p>Resend OTP in {otpCountdown} seconds</p>
              <button type="button" className='text-notification' onClick={handleResendOTP}>Resend OTP</button>
            </div>
            <div className='w-[90%] md:w-[80%] mx-auto'>
              <Terms />
              <BasicButton
                type='submit'
                disabled={!isValid}
              >
                Continue
              </BasicButton>
            </div>
          </div>
        );
        case RegistrationPage.Page3:
        return (
          <div>
            <CheckInput
              label='Join Founders Club (Optional)'
              error={errors.is_premium && errors.is_premium.message}
              tooltipContent='A $20.99 fee is required to join our exclusive Founders Club.' // Add the tooltip content
              refs={{
                ...register('is_premium'),
              }}
              Icon={HiUser}
              onClick={() => setOpen(!open)}
              
            />
            <div className='w-[90%] md:w-[80%] mx-auto text-center'>
              <p className='w-full text-center text-[#FF4500] font-bold text-lg'>OR</p>
            </div>
            <div className='w-[90%] md:w-[80%] mx-auto'>
              <BasicButton type="submit">Login</BasicButton>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  
  return (
    <form
      method='POST'
      name='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* <div onClick={() => setOpen(!open)}>OPEN PAYMENT</div> */}
      {open &&<StripeModal isOpen={open} onClose={() => setOpen(!open)} />}
      {currentPage !== 0 ? 
          <FaArrowLeft 
            className='ml-9 text-text cursor-pointer' 
            onClick={handlePrevPage} /> : null 
        }
      {/* { signUpTypePage === 2 ?  */}
      <>
        {/* {signUpTypePage !== 1 ? 
          <FaArrowLeft 
            className='ml-9 text-text cursor-pointer' 
            onClick={() => setSignUpTypePage(1)} /> : null 
        } */}
        {renderPage()}
      </>
      {/* : */}
      {/* <div className='w-[100%] pt-3 pb-3 md:w-[100%] mx-auto flex flex-col justify-center items-center'>
        <LoginBasicButton
          type='button'
          onClick={() => setSignUpTypePage(2)}
        >
          <HiUser />Use email/username
        </LoginBasicButton> */}
        {/* <SocialLoginFactory setIsFormOpen={setIsFormOpen} /> */}
      {/* </div>
      } */}
      <Toaster />
    </form>
  );
};

export default RegisterForm;